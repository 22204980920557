<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary"
    id="modalCoxsBazar"
    data-bs-toggle="modal"
    data-bs-target="#staticBackdrop"
    hidden
  >
    Launch static backdrop modal
  </button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" role="document">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <img
          v-if="selectedPackage"
          :src="selectedPackage.image_url"
          style="height: 350px"
        />
        <div class="content">
          <p>
            <b
              >Destination:
              {{ selectedPackage ? selectedPackage.from_destination : '' }} -
              {{ selectedPackage ? selectedPackage.to_destination : '' }}</b
            >
          </p>
          <p>
            <b
              >Vehicle type:
              {{ selectedPackage ? selectedPackage.vehicle_type : '' }}</b
            >
          </p>
          <p>
            <b
              >Price:
              {{
                selectedPackage
                  ? selectedPackage.starting_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }}
              (Condition Applied)</b
            >
          </p>

          <p><b>Terms & Conditions:</b></p>
          <ul>
            <li>
              <b>1.</b> This rate includes all kinds of fuel and driver
              allowance.
            </li>
            <li>
              <b>2.</b> This rate is only for the mentioned trip type ( One way/
              Round trip).
            </li>
            <li>
              <b>3.</b> If you want to go to Upazilas/Villages from the main
              city then additional charges will be added upon discussion.
            </li>
            <li>
              <b>4.</b> This price may increase during Eid/Puja/Christmas time
              or any blackout period depending on the market supply and demand.
            </li>
            <li>
              <b>5.</b> 50% of the total payment needs to be paid in advance as
              a booking charge.
            </li>
            <li><b>6.</b> Vehicles are subject to availability.</li>
            <li>
              <b>7.</b> You may cancel up to 24 hours in advance of your
              designated pick-up time without penalty.
            </li>
            <li>
              <b>8.</b> Shuttle reserves the right to change or cancel any part
              of the terms and conditions.
            </li>
            <li>
              <a href="tel:+09638-888868"
                ><b>9.</b> For more queries, please call our Hotline:
                <b>09638-888868.</b></a
              >
            </li>
          </ul>
          <a
            href=""
            data-bs-toggle="modal"
            data-bs-target="#exampleModal2"
            data-bs-dismiss="modal"
            id="package_cta"
            >Book Now</a
          >
        </div>
      </div>
    </div>
  </div>
  <BookingModal :selectedPackage="selectedPackage" />
</template>
<script>
import BookingModal from './BookingModal.vue'
export default {
  props: ['selectedPackage'],
  components:{BookingModal},
  data() {
    return {};
  }
};
</script>
<style scoped>
.modal-body img {
  height: auto;
  max-width: 100%;
}
.modal-content {
  border-radius: 15px;
}
.modal-content img {
  margin-top: -55px;
  border-radius: 10px;
}
.modal-header .btn-close {
  z-index: 9;
}
.modal-body {
  padding: 0;
}
.content {
  padding: 15px;
}
.content a {
  background-color: #6c3a97;
  color: #fff;
  padding: 12px 50px;
  text-align: center;
  /* display: block; */
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 20px;
  margin: 0 auto;
  display: block;
  width: 220px;
  margin: auto;
}
.content ul li {
  list-style: none;
  padding-bottom: 10px;
}
.content ul li a {
  background-color: transparent;
  color: #000;
  display: content !important;
  padding: 0 !important;
  text-align: initial !important;
  width: auto;
  font-weight: 400;
}
</style>
