<template>
  <div class="col-md-12 col-lg-6 col-sm-12 col-12 pb-lg-5 pb-0">
    <span class="position-relative">
      <div class="position-absolute top-0 start-0">
        <img
          src="@/assets/Vector.png"
          alt="Google Logo"
          class="ps-lg-1 ms-5 mx-4 img-fluid d-lg-flex d-md-none d-sm-none d-none"
        />
      </div>
      <img src="@/assets/dropoff.png" class="img-fluid" />
      <div class="position-absolute top-50 start-0 translate-middle">
        <img src="@/assets/dropoff.png" class="img-fluid ms-5 w-75" />
      </div>
    </span>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 col-12">
    <h4 class="mb-5 p-0 text-start">How to book a ride on our app?</h4>
    <div class="row">
      <div class="ul p-1">
        <li class="mb-1 d-flex">
          <span class="fw-bold">.</span>
          <p class="btn text-start px-2">Tap the Single rides button</p>
        </li>
        <li class="mb-1 d-flex">
          <span class="fw-bold">.</span>
          <p class="btn text-start px-2">
            Put your pick-up area and pick-up station Put your drop of area and
            drop-off station, Select your route
          </p>
        </li>
        <li class="mb-1 d-flex">
          <span class="fw-bold">.</span>
          <p class="btn text-start px-2">
            Pick the date and time of your commute
          </p>
        </li>
        <li class="mb-1 d-flex">
          <span class="fw-bold">.</span>
          <p class="btn text-start px-2">
            For men there is Unisex Rides and for women there are both Unisex
            and Female Only tabs
          </p>
        </li>
        <li class="mb-1 d-flex">
          <span class="fw-bold">.</span>
          <p class="btn text-start px-2">
            Tap confirm and your ride will be scheduled. Enjoy!
          </p>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
