<template>
<div>
    <Navbar/>
    <div class="container pt-5">
        <div class="row justify-content-center">
            <div class="col-10 col-md-12 col-lg-12">
                <h1>সচারচর জিজ্ঞাসিত প্রশ্নাবলী</h1>
                <p class="question">প্রশ্নঃ আমি কীভাবে শাটল পার্টনার হতে পারি? কী কী প্রয়োজনীয় নথি লাগবে?</p>
                <p class="ans"><strong>উত্তরঃ</strong> শাটল পার্টনার হতে হলে আপনাকে আমাদের ওয়েবসাইটে রেজিস্ট্রেশন করতে হবে। যেসকল প্রয়োজনীয় নথি লাগবে তা হলঃ এনআইডি, ড্রাইভিং লাইসেন্স, গাড়ির রেজিস্ট্রেশন পেপার, ট্যাক্স টোকেন, ইনসিওরেন্স পেপার এবং ফিটনেস পেপার।</p>
                <p class="question">প্রশ্নঃ সাইন আপ করতে কতটুকু সময় লাগবে?</p>
                <p class="answer"><strong>উত্তরঃ</strong> আপনার সকল তথ্য জমা দিতে মাত্র ৫ মিনিটের মতো সময় লাগবে। প্রয়োজনীয় সকল নথিপত্র আমাদের নিকট প্রদান করার পর আমরা আপনার আবেদনকে অনলাইনে যাচাই বাছাই করবো।</p>
                <p class="question">প্রশ্নঃ আমাকে কীভাবে অর্থ প্রদান করা হবে?</p>
                <p class="ans"><strong>উত্তরঃ</strong> ব্যাংক অ্যাকাউন্ট বা ডিজিটাল পেমেন্টের মাধ্যমে আমরা প্রতি মাসে অর্থ প্রদান করে থাকি</p>
                <p class="question">প্রশ্নঃ আমি ফুলটাইম ড্রাইভ করি না। আমি কি সপ্তাহে কেবলমাত্র কয়েক ঘন্টা চালানোর জন্য শাটল পার্টনারস ব্যবহার করতে পারি? </p>
                <p class="ans"><strong>উত্তরঃ</strong> অবশ্যই, আমাদের কিছু পার্টনারস পুরো সপ্তাহের কেবল কয়েক ঘন্টা সক্রিয় থাকে এবং দারুন পার্ট টাইম উপার্জন করে।</p>
                <p class="question">প্রশ্নঃ স্মার্টফোন ছাড়া কী শাটল পার্টনার ব্যবহার করা যাবে? </p>
                <p class="ans"><strong>উত্তরঃ</strong> দুঃখিত, স্মার্টফোন ছাড়া শাটল পার্টনার ব্যবহার করা যাবে না। </p>
                <p class="question">প্রশ্নঃ আমি কী আমার গাড়ির স্ট্যাটাস ট্রাক করতে পারবো?  </p>
                <p class="ans"><strong>উত্তরঃ</strong> জ্বী অবশ্যই। শাটল পার্টনারস এর মাধ্যমে আপনি আপনার গাড়ির লাইভ লোকেশন ট্রাক করতে পারবেন।</p>
                <p class="question">প্রশ্নঃ কোনো দূর্ঘটনা হলে তার দায়িত্ব কে নিবে? </p>
                <p class="ans"><strong>উত্তরঃ</strong><strong> আপনার গাড়িটি আমাদের কাজে ব্যবহারের সময় যদি কোনো আকস্মিক দূর্ঘটনার সম্মুখীন হয়, তবে, তার সর্ম্পূর্ণ দায়ভার শাটল বহন করবে।</strong></p>
                <p class="question">প্রশ্নঃ আমার গাড়ি ব্যবহারে পর্যাপ্ত সুরক্ষা নিশ্চিত করা হবে কী?</p>
                <p class="ans"><strong>উত্তরঃ</strong> পর্যাপ্ত স্যানিটাইজেশনের মাধ্যমে আপনার গাড়িটি ব্যবহার করা হবে। আপনি নিশ্চিন্তে থাকুন।</p>
                <p class="question">প্রশ্নঃ আমার তথ্য যাচাই বাছাই করতে কতটুকু সময় লাগবে? </p>
                <p class="ans"><strong>উত্তরঃ</strong> আমাদের প্রয়োজনীয়তা অনুসারে যথাযথ যাচাই বাছাই এর মাধ্যমে আপনার তথ্য পর্যবেক্ষণ করে শীঘ্রই আপনার সাথে যোগাযোগ করা হবে আরও তথ্যের জন্য যোগাযোগ করুন আমাদের সাথে ০১৩২১-১৩৯৮৪৬ এই নাম্বারে সকাল ৯ টা থেকে সন্ধ্যা ৭ টা পর্যন্ত।</p>
                <p class="question">প্রশ্নঃ গাড়ি কীভাবে অ্যাড করবো?</p>
                <p class="ans"><strong>উত্তরঃ</strong> আপনার ব্যক্তিগত তথ্য বিবরণী দিয়ে রেজিস্ট্রেশন করে নেওয়ার পর লগ ইন করে আপনার প্রোফাইল ড্যাশবোর্ডে গিয়ে গাড়ি অ্যাড করবেন। </p>
                <p class="question">প্রশ্নঃ পার্সন এবং কোম্পানি সাইন আপে পার্থক্য কী? </p>
                <p class="ans"><strong>উত্তরঃ</strong> বহু গাড়ি যদি এক ব্যক্তি মালিকানাধীন হয় তা হলো পার্সন। অপরদিকে, বহু গাড়ি যদি একটি প্রতিষ্ঠানের অধীনে থাকে তবে তা হলো কোম্পানি।</p>
                <p class="question">প্রশ্নঃ আমি কীভাবে আমার অভিযোগ জমা দিতে পারি?</p>
                <p class="ans"><strong>উত্তরঃ</strong> শাটল হেল্পলাইনে যোগাযোগ করুন অথবা যোগাযোগ করুন ০১৮৮০-১৯৯৮০১ এ নাম্বারে।</p>
                <p>আরও তথ্যের জন্য আমাদের সাথে যোগাযোগ করুন।</p>
            </div>
            <div class=" col-10 col-md-12 col-lg-12 p-4">
                <button type="submit" class="btn btn-sm" @click.prevent="signup"> সাইন আপ করুন
                    <img src="/assets/images/partner/arrow_forward.png" class="pl-3">
                </button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Navbar from './include/Header.vue'
export default {
    components: {Navbar},
    methods:{
        signup(){  this.$router.push('/partner/registration')  }
    }
}
</script>
<style scoped>
.container{ background-color: white;}
p.question{ font-weight: bold;}
</style>