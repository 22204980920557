<template>
  <div class="col-lg-5 col-md-12 col-sm-12 col-12 mb-lg-5 mb-2">
    <div class="row d-flex flex-column my-4">
      <div class="col">
        <h1 class="h2 mb-3 ride_heading">
          {{  $t("message.tab.daily.heading", {}, { locale: LoadLangShow }) }}
        </h1>
        <p class="mb-1 text-muted ride_text">
          {{  $t("message.tab.daily.body", {}, { locale: LoadLangShow }) }}
        </p>
      </div>
    </div>
    <div class="row ps-3">
      <div class="col-lg-8 col-12 ps-0">
        <button
          class="btn w-100 tabBtn ride_button text-capitalize shadow-none"
          data-bs-toggle="modal"
          data-bs-target="#b2cModal"
        >
          {{  $t("message.tab.daily.button", {}, { locale: LoadLangShow }) }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-7 col-md-12 col-sm-12 col-12 image-container">
    <div class="position-relative">
      <span
        class="position-absolute bottom-0 end-0 translate-middle-x d-lg-flex d-md-none d-none"
      >
        <img
          src="@/assets/rectangle.png"
          alt=""
          class="img-fluid bg-transparent"
        />
      </span>
      <span
        class="position-absolute top-0 start-0 translate-middle-y d-lg-flex d-md-none d-none"
      >
        <img
          src="@/assets/rectangle.png"
          alt=""
          class="img-fluid bg-transparent"
        />
      </span>
      <div class="card border-0 rounded bg-transparent mb-3">
        <div class="card-body p-0">
          <img
            src="@/assets/daily-ride.webp"
            alt=""
            class="img-fluid border-0 rounded rideTabImg"
          />
        </div>
      </div>
      <div class="row text-center d-none">
        <div class="col-lg-5 col-md-5 col-sm-5 col-12">
          <div class="header-dots active"></div>
          <div class="header-dots"></div>
          <div class="header-dots"></div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
    <modal-b2-c-vue></modal-b2-c-vue>
  </teleport>
</template>
<script>
import ModalB2CVue from './modal/ModalB2C.vue';
export default {
  name: 'RideTab',
  data() {
    return {
    }
  },
  components: {
    ModalB2CVue
  },
  computed:{
    LoadLangShow(){
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  methods:{
  //   getSignupTitle(){
  //     this.signupTitleData = 'home';
  //     console.log(this.signupTitleData);
  //   }
  }
};
</script>
<style scoped>
.translate-middle-x {
  transform: translateX(-50%) !important;
  margin-bottom: -20px;
  margin-right: -20px;
}
.translate-middle-y {
  transform: translateX(40%) !important;
  margin-top: -20px;
}
.image-container {
  margin-top: -34px;
}
@media only screen and (min-device-width: 280px) and (max-device-width: 567px) {
  .translate-middle-x {
    display: none;
  }
  .translate-middle-y {
    display: none;
  }
  .card-body {
    padding: 4px !important;
  }
  .image-container {
    margin-top: 10px;
    margin-right: 0px;
  }
}
@media only screen and (min-device-width: 568px) and (max-device-width: 767px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(-2%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: 10px;
    margin-right: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .translate-middle-x {
    transform: translateX(-60%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(-5%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: 20px;
    margin-right: 0px;
    text-align: center;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1023px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: -3px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: -1px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .translate-middle-x {
    transform: translateX(-2%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    margin-right: -44px;
  }
}
@media only screen and (min-device-width: 1201px) and (max-device-width: 1400px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: -2px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: -2px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    text-align: end;
    margin-right: -2.8%;
  }
}
@media only screen and (min-device-width: 1401px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    text-align: end;
    margin-right: -2.8%;
  }
}
</style>
