<template>
  <School/>
  <footer>
    <the-footer></the-footer>
  </footer>
</template>

<script>
import School from "../components/sfs/Index.vue";
import TheFooterVue from '../components/TheFooter.vue';

export default {
  name: "ShuttleSchool",
  components: {
    School,
    TheFooter: TheFooterVue
  },
};
</script>
