<template>
  <div
    class="
      col-md-12 col-lg-6 col-sm-12 col-12
      mb-3
      pb-lg-5 pb-0
      position-relative
      text-lg-start text-center
      book-process-instruction-img
    "
  >
    <div class="position-absolute top-50 start-50">
      <!-- <img
        src="@/assets/Vector.png"
        alt="Google Logo"
        class="img-fluid d-lg-flex d-md-none d-sm-none d-none translate-middle w-100"
      /> -->
    </div>
    <img
      :src="require(`@/assets/${currentImage}.webp`)"
      class="img-fluid w-75"
    />
    <!-- <span class="position-relative">
      <div class="position-absolute top-50 start-0 translate-middle">
      </div>
    </span> -->
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 col-12 book-process-instruction">
    <h4 class="mb-5 p-0 text-start">
      {{  $t("message.bookingProcess.headline", {}, { locale: LoadLangShow }) }}
    </h4>
    <div class="row">
      <div class="ul p-1">
        <li
          class="mb-1 d-flex book_ride active shadow"
          data-image="Tap_the_Single_rides"
        >
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg>
          </span>
          <p class="text-start px-2 pt-0">{{  $t("message.bookingProcess.body.list1", {}, { locale: LoadLangShow }) }}</p>
        </li>
        <li class="mb-1 d-flex book_ride" data-image="Find_Your_Route">
          <span class="fw-bold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </span>
          <p class="text-start px-2 pt-0">
            {{  $t("message.bookingProcess.body.list2", {}, { locale: LoadLangShow }) }}
          </p>
        </li>
        <li class="mb-1 d-flex book_ride" data-image="Select_Date_Time_of_Trip">
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">
            {{  $t("message.bookingProcess.body.list3", {}, { locale: LoadLangShow }) }}
          </p>
        </li>
        <li
          class="mb-1 d-flex book_ride"
          data-image="Choose_between_Regular_and_Female_Rides"
        >
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">
            {{  $t("message.bookingProcess.body.list4", {}, { locale: LoadLangShow }) }}
          </p>
        </li>
        <li class="mb-1 d-flex book_ride" data-image="Confirm_Route">
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">
            {{  $t("message.bookingProcess.body.list5", {}, { locale: LoadLangShow }) }}
          </p>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookRide',
  data() {
    return {
      intervalTime: 3000,
      currentImage: 'Tap_the_Single_rides',
      currentImageIndex: 0,
      images: [
        'Tap_the_Single_rides',
        'Find_Your_Route',
        'Select_Date_&_Time_of_Trip',
        'Choose_between_Regular_and_Female_Rides',
        'Confirm_Route'
      ],
      interval: null
    };
  },
  computed:{
    LoadLangShow(){
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  methods: {
    doSetTimeout(item) {
      let lists = document.querySelectorAll('.book_ride');
      lists.forEach((list) => {
        list.classList.remove('active');
        list.classList.remove('shadow');
      });
      // get the current list item value from data attribute
      let currentImage = item.getAttribute('data-image');
      // set the current image
      this.currentImage = currentImage;
      item.classList.add('active');
      item.classList.add('shadow');
    }
  },
  activated() {
    let lists = document.querySelectorAll('.book_ride');
    this.interval = setInterval(() => {
      this.doSetTimeout(lists[this.currentImageIndex]);
      this.currentImageIndex =
        this.currentImageIndex + 1 >= this.images.length
          ? 0
          : this.currentImageIndex + 1;
    }, this.intervalTime);
  },
  deactivated() {
    clearInterval(this.interval);
  },
  mounted() {
    this.currentImage = this.images[this.currentImageIndex];
  },
  unmounted() {
    this.currentImageIndex = 0;
    this.currentImage = this.images[this.currentImageIndex];
    clearInterval(this.interval);
  }
}
</script>
<style scoped>
.book_ride {
  border: none;
  text-align: center;
}
.book_ride.active {
  /* border: none; */
  height: fit-content;
  background: #a95eea;
  color: #ffffff;
  border-radius: 10px;
}
.book_ride span {
  color: #6c3a97;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: -10px;
}
.book_ride.active span svg {
  display: none;
}
.book_ride.active p {
  color: #ffffff;
  margin-bottom: 0px !important;
  padding: 10px 15px !important;
}

@media only screen and (max-width: 991px) {
  .book_ride.active {
    /* border: none; */
    transform: scaleZ(0.7) translate(-15px);
    transition: 0.5s ease-in-out;
    border-radius: 10px;
  }
}
</style>
