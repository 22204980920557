import axiosAuthClient from "../axios/axios";
// import axiosGoogleMapClient from "../axios/axiosGoogleMap";
// import config from "../config/config"; 

export default {
    async getLocationInfo(location) {
        return await axiosAuthClient.get(`google-api/place-details?location=${location}`)
    },
    async getDistance(addressOnelat, addressOneLng, addressTwoLat, addressTwoLng) {
        return await axiosAuthClient.get(`google-api/distance?addressOneLat=${addressOnelat}&addressOneLng=${addressOneLng}&addressTwoLat=${addressTwoLat}&addressTwoLng=${addressTwoLng}`)
    },
    async kmWiseCarTypePrices(km) {
        return axiosAuthClient.get(`car-type-wise-prices/km-wise?km=${km}`)
    },
    async saveBooking(data) {
        return axiosAuthClient.post(`booking-request/create`, data)
    },
    async searchByInput(data) {
        return axiosAuthClient.get(`google-api/query-autocomplete?input=${data}`)
    },
    async rentalInvoiceInfo(trxId) {
        return axiosAuthClient.get(`invoice/${trxId}`)
    },
    async packages() {
        return axiosAuthClient.get(`package-settings`)
    },
    async campaignLogStore(data){
        return axiosAuthClient.post(`campaign/log`, data);
    }
}