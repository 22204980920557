<template>
  <!-- carousel slider section start from here -->

  <section class="carousel-section">
    <div class="container">
      <div class="row"></div>
      <div class="row">
        <!-- carousel row left part design start -->

        <div class="col-md-4 col-lg-4 carousel-left">
          <div class="border"></div>
          <h2>Your Shuttle Your Way</h2>
          <p>
            Discover a wide range of ways to rent your Shuttle. Choose from
            multiple offerings to find a rental that fits your travel needs.
          </p>
        </div>
        <!-- carousel row left part design end -->

        <!-- carousel row right part design start -->

        <div class="col-md-8 col-lg-8 carousel-right">
          <div class="slide-container swiper">
            <div class="slide-content" id="slide-content-shuttle-way">
              <div class="card-wrapper swiper-wrapper">
                <div
                  class="card swiper-slide"
                  style="background-color: #f8f5ff"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/holiday.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">Holiday Rentals</h2>
                    <p class="description">
                      The prime choice for your holiday get away, keep your
                      Shuttle with you for as long as you want.
                    </p>
                  </div>
                </div>
                <div
                  class="card swiper-slide"
                  style="background-color: #f2faf7"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/group1.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">All-day Rentals</h2>
                    <p class="description">
                      Best for day trips or moving out & about in the city. Rent
                      up to 10 hours and 80 kms
                    </p>
                  </div>
                </div>
                <div
                  class="card swiper-slide"
                  style="background-color: #eff8fe"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/group2.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">Pick & Drop</h2>
                    <p class="description">
                      When you need a Shuttle for a scheduled pick & drop
                    </p>
                  </div>
                </div>
                <div
                  class="card swiper-slide"
                  style="background-color: #fdf1f9"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/group3.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">Executive Rentals</h2>
                    <p class="description">
                      Luxury class vehicles for business meetings and special
                      guests.
                    </p>
                  </div>
                </div>
              
                 <div
                  class="card swiper-slide"
                  style="background-color: #f8f5ff"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/holiday.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">Holiday Rentals</h2>
                    <p class="description">
                      The prime choice for your holiday get away, keep your
                      Shuttle with you for as long as you want.
                    </p>
                  </div>
                </div>
                <div
                  class="card swiper-slide"
                  style="background-color: #f2faf7"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/group1.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">All-day Rentals</h2>
                    <p class="description">
                      Best for day trips or moving out & about in the city. Rent
                      up to 10 hours and 80 kms
                    </p>
                  </div>
                </div>
                <div
                  class="card swiper-slide"
                  style="background-color: #eff8fe"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/group2.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">Pick & Drop</h2>
                    <p class="description">
                      When you need a Shuttle for a scheduled pick & drop
                    </p>
                  </div>
                </div>
                <div
                  class="card swiper-slide"
                  style="background-color: #fdf1f9"
                >
                  <div class="image-content">
                    <span class="overlay"></span>

                    <div class="card-image">
                      <img
                        src="/assets/images/group3.webp"
                        alt=""
                        class="card-img"
                      />
                    </div>
                  </div>

                  <div class="card-content">
                    <h2 class="name">Executive Rentals</h2>
                    <p class="description">
                      Luxury class vehicles for business meetings and special
                      guests.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-button-next swiper-navBtn" id="shuttle-way-next"></div>
            <div class="swiper-button-prev swiper-navBtn" id="shuttle-way-prev"></div>
            <div class="swiper-pagination" id="shuttle-way-pag"></div>
          </div>
        </div>
        <!-- carousel row left part design end -->
      </div>
    </div>
  </section>

  <!--  carousel slider section end here -->
</template>

<script>
export default {
  mounted() {
    /* eslint-disable */
    var swiper = new Swiper("#slide-content-shuttle-way", {
      slidesPerView: 3,
      spaceBetween: 25,
      loop: true,
      centerSlide: "true",
      fade: "true",
      grabCursor: "true",
      pagination: {
        el: "#shuttle-way-pag",
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: "#shuttle-way-next",
        prevEl: "#shuttle-way-prev",
      },
      autoplay: {
        delay: 8000,
      },

      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        520: {
          slidesPerView: 2,
        },
        950: {
          slidesPerView: 3.5,
        },
      },
    });
    /* eslint-enable */
  },
};
</script>

<style scoped>
/* carousel section css start from here */
.carousel-section {
  padding: 50px 0px 0px 0;
}
.carousel-right {
  margin-top: 34px;
}

.carousel-section h2 {
  padding-top: 20px;
  color: #111632;
  font-weight: 600;
  font-size: 32px;
}
.carousel-left .border {
  width: 5.5rem;
  height: 7px;
  background: #a95eea;
  border-radius: 3px;
}
.carousel-section a {
  background-color: #6c3a97;
  color: #fff;
  padding: 12px 100px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 10px;
}
.slide-container {
  max-width: 1120px;
  width: 100%;
  padding: 0px 0 40px 0;
}
.card-content h2 {
  font-size: 18px;
  padding-top: 0px;
}
.slide-content {
  /*   margin: 0 0px; */
  overflow: hidden;
  /* border-radius: 25px; */
}
.card {
  border-radius: 10px;
  background-color: #fff;
}
.image-content,
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 14px;
}
.image-content {
  position: relative;
  row-gap: 5px;
  padding: 5px 0;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-color: #4070F4; */
  border-radius: 25px 25px 0 25px;
}
.overlay::before,
.overlay::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  /*   background-color: #4070F4; */
}
.overlay::after {
  border-radius: 0 25px 0 0;
}
.card-image {
  position: relative;
  /*  height: 150px; */
  /*  width: 150px; */
  /*  border-radius: 50%; */
  /*  background: #FFF; */
  padding: 3px;
}
.card-image .card-img {
  height: 80%;
  width: 100%;
  object-fit: cover;
}
.name {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description {
  font-size: 14px;
  color: #707070;
  text-align: center;
  padding-top: 10px;
}

.swiper-navBtn {
  color: #6e93f7;
  transition: color 0.3s ease;
}
.swiper-navBtn:hover {
  color: #4070f4;
}
.swiper-navBtn::before,
.swiper-navBtn::after {
  font-size: 35px;
  display: none;
}
.swiper-button-next {
  right: 0;
  display: none;
}
.swiper-button-prev {
  left: 0;
}
.swiper-pagination-bullet {
  background-color: #6e93f7;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #4070f4;
}
.swiper-slide {
  height: 280px !important;
}
.slide-content {
  padding-bottom: 10px;
}

/* .swiper{
  width: 900px;
} */

/*********** carousel section css end here ***********/

@media (min-width: 768px) and (max-width: 1024px) {
  .rental-image img {
    width: 100%;
  }
  .carousel-section a {
    display: block;
    padding: 15px 20px;
    text-align: center;
  }
  .carousel-section {
    padding: 50px 0 0 0;
  }
  .slide-container {
    padding: 0;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .carousel-section {
    padding: 50px 0 0 0;
  }
  .carousel-section h2 {
    font-size: 25px;
  }
  .carousel-section a {
    display: block;
  }
  /* .swiper{
  margin-top: 40px
} */
}
</style>
