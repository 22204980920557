<template>
  <Navbar />
  <Banner />
  <TestSlider/>
  <!-- <Package /> -->
  <Information />

  <!-- <Testimonial /> -->
  <PartnerDescription />

  <Faq />
  <Blog />
  <!-- <Invoice/> -->
  <!-- <Contact/> -->

  <!-- <Footer /> -->
  
  <footer>
    <the-footer></the-footer>
  </footer>
</template>
<script>
import Navbar from "../components/rental/Navbar.vue";
import Banner from "../components/rental/Banner.vue";
// import Package from "../components/rental/Package.vue";
import Information from "../components/rental/Information.vue";
import TestSlider from "../components/rental/TestSlider.vue"

// import Testimonial from "../rental/Testimonial.vue";
import PartnerDescription from "../components/rental/PartnerDescription.vue";

import Faq from "../components/rental/Faq.vue";
import Blog from "../components/rental/Blog.vue";
// import Contact from "../rental/Contact.vue";
// import Invoice from "../rental/Invoice.vue";

// import Footer from "../components/rental/Footer.vue";
import TheFooterVue from '../components/TheFooter.vue';

export default {
  name: "Home",
  components: {
    Navbar,
    Banner,
    // Package,
    Information,

    // Testimonial,
    PartnerDescription,

    Faq,
    Blog,
    // Contact,
    // Invoice,

    // Footer,
    TheFooter: TheFooterVue,
    TestSlider
  },
};
</script>
