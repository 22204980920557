<template>
  <header>
    <businessHeader></businessHeader>
  </header>
  <!--banner section-->
  <section class="container mb-lg-5 mb-0 mt-lg-5 mt-5 ps-0">
    <!-- Back to top signup -->
    <a id="button" href="#" class="btn-block text-capitalize" data-bs-toggle="modal" 
        data-bs-target="#signupModalB2B" @click="signupTitle='business'">
        {{  $t("message.tab.daily.button", {}, { locale: LoadLangShow }) }}</a>

    <div class="row row-cols-1 row-cols-lg-2">
      <div class="col">
        <div class="container my-5">
          <div class="row row-cols-1">
            <div class="col mb-lg-2">
              <h1 class="h2 fs-3 fw-bold">
                {{  $t("message.businesstab.title.t1", {}, { locale: LoadLangShow }) }}<br />
                {{  $t("message.businesstab.title.t2", {}, { locale: LoadLangShow }) }}
              </h1>
            </div>
            <div class="col mb-lg-5 mb-5">
              <small class="text-muted fs-6">
                {{  $t("message.businesstab.body.b1", {}, { locale: LoadLangShow }) }} <br />
                {{  $t("message.businesstab.body.b2", {}, { locale: LoadLangShow }) }} <br />
                {{  $t("message.businesstab.body.b3", {}, { locale: LoadLangShow }) }}
              </small>
            </div>
            <div class="col">
              <button
                class="btn business-btn text-white text-center text-capitalize"
                data-bs-toggle="modal"
                data-bs-target="#signupModalB2B"
                @click="signupTitle='business'"
              >
                {{  $t("message.businesstab.button", {}, { locale: LoadLangShow }) }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col position-relative">
        <div
          class="
            position-absolute
            top-0
            start-0
            top-rectangle
            mx-xl-2 mx-lg-2 mx-0
            d-md-flex d-done
          "
        >
          <img
            src="@/assets/rectangle.png"
            alt=""
            class="img-fluid d-lg-flex d-none"
          />
        </div>
        <div
          class="
            position-absolute
            bottom-0
            end-0
            bottom-rectangle
            mb-xxl-0 mb-lg-2 mb-0
            me-lg-2 me-0
          "
        >
          <img
            src="@/assets/rectangle.png"
            alt=""
            class="img-fluid d-lg-flex d-none"
          />
        </div>
        <div class="card bg-transparent border-0 text-center">
          <div class="card-body text-center">
            <img
              src="@/assets/movingbusinessforward.png"
              alt=""
              class="img-fluid d-lg-flex d-none business-image"
            />
            <img
              src="@/assets/movingbusinessforward2.png"
              alt=""
              class="img-fluid d-lg-none d-flex business-image"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--end banner section-->
  <!--operate section-->
  <section class="container mb-5 py-4">
    <div class="row row-cols-1">
      <div class="col mb-lg-3 mb-3">
        <div class="container">
          <div class="business-line"></div>
        </div>
      </div>
      <div class="col mb-2">
        <div class="container">
          <h3 class="h2 fs-3 fw-bold">
            {{  $t("message.howShuttleWorks.title", {}, { locale: LoadLangShow }) }}
          </h3>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="container">
          <div class="row row-cols-1 row-cols-lg-2">
            <div class="col-lg-4 col-md-12 col-12 ms-auto mb-lg-0 mb-3 ps-0">
              <div class="card border-0">
                <div class="card-body shuttle-works">
                  {{  $t("message.howShuttleWorks.body.b1", {}, { locale: LoadLangShow }) }}<br />
                  {{  $t("message.howShuttleWorks.body.b2", {}, { locale: LoadLangShow }) }}
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-12">
              <div class="row d-flex justify-content-start">
                <div class="col">
                  <Carousel
                    v-if="LoadLangShow == 'bn'"
                    id="b2b_carousel_work"
                    :wrap-around="true"
                    :items-to-show="3"
                    :breakpoints="breakpoints"
                    :autoplay="5000"
                  >
                    <Slide
                      v-for="slide in slidesBangla"
                      :key="slide.ID"
                      @mouseover="SlideMouseOverHandler()"
                      :data-slide-index="slide.ID"
                    >
                      <base-card :style="{ background: slide.bg }">
                        <template v-slot:icon>
                          <img
                            :src="
                              require(`@/assets/slides/slide${slide.image}.png`)
                            "
                            class="
                              card-img-top
                              img-fluid
                              my-2
                              w-25
                              border
                              rounded-circle
                            "
                            :alt="slide.title"
                          />
                        </template>
                        <template v-slot:title>
                          <h6 class="slide-title text-start">
                            {{ slide.title }}
                          </h6>
                        </template>
                        <template v-slot:content>
                          <p class="slide-text">
                            {{ slide.text }}
                          </p>
                        </template>
                      </base-card>
                    </Slide>
                    <template #addons>
                      <Pagination
                        class="d-lg-none d-flex pagination-style-reso"
                      />
                    </template>
                  </Carousel>
                  <Carousel
                    v-else
                    id="b2b_carousel_work"
                    :wrap-around="true"
                    :items-to-show="3"
                    :breakpoints="breakpoints"
                    :autoplay="5000"
                  >
                    <Slide
                      v-for="slide in slides"
                      :key="slide.ID"
                      @mouseover="SlideMouseOverHandler()"
                      :data-slide-index="slide.ID"
                    >
                      <base-card :style="{ background: slide.bg }">
                        <template v-slot:icon>
                          <img
                            :src="
                              require(`@/assets/slides/slide${slide.image}.png`)
                            "
                            class="
                              card-img-top
                              img-fluid
                              my-2
                              w-25
                              border
                              rounded-circle
                            "
                            :alt="slide.title"
                          />
                        </template>
                        <template v-slot:title>
                          <h6 class="slide-title text-start">
                            {{ slide.title }}
                          </h6>
                        </template>
                        <template v-slot:content>
                          <p class="slide-text">
                            {{ slide.text }}
                          </p>
                        </template>
                      </base-card>
                    </Slide>
                    <template #addons>
                      <Pagination
                        class="d-lg-none d-flex pagination-style-reso"
                      />
                    </template>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--end operate section-->
  <!--shuttle service section-->
  <section class="container text-center mb-4 commute-dashboard">
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
      <div class="col">
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <div class="container mb-2">
              <div class="business-line2"></div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="col mb-2">
        <h2 class="text-center h2 fs-3 fw-bold">
          {{  $t("message.commuteDashboard.headline", {}, { locale: LoadLangShow }) }}
        </h2>
      </div>
      <div class="col mb-5 bg-light p-3">
        <div class="row row-cols-1 row-cols-lg-1">
          <div class="col">
            <Carousel id="shuttle_service" :mouseDrag="false" v-if="LoadLangShow == 'bn'">
              <!-- for bangla slide -->
              <Slide v-for="slide in serviceSlidesBangla" :key="slide">
                <div
                  class="carousel__item p-lg-5"
                  style="transform: scale(1) !important"
                >
                  <img
                    :src="require(`@/assets/dashboard${slide.image}.png`)"
                    alt=""
                    class="img-fluid rounded mb-4 p-4"
                  />
                  <h1 class="h5 fw-bold text-center" style="color: #a95eea">
                    {{ slide.title }}
                  </h1>
                  <!-- <p class="text-muted">
                    {{ slide.text }}
                  </p> -->
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-lg-8 col-12 text-lg-center">
                      {{ slide.text }}
                    </div>
                    <div class="col-2"></div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <navigation class="d-lg-flex d-none">
                  <template #next class="d-lg-flex d-none">
                    <i
                      class="bi bi-arrow-right text-white"
                      @click="nextHandler"
                    ></i>
                  </template>
                  <template #prev class="d-lg-flex d-none">
                    <i
                      class="bi bi-arrow-left text-white"
                      @click="prevHandler"
                    ></i>
                  </template>
                </navigation>
                <Pagination class="d-lg-none d-flex pagination-style-reso" />
              </template>
            </Carousel>
            <Carousel id="shuttle_service" :mouseDrag="false" v-else>
              <Slide v-for="slide in serviceSlides" :key="slide">
                <div
                  class="carousel__item p-lg-5"
                  style="transform: scale(1) !important"
                >
                  <img
                    :src="require(`@/assets/dashboard${slide.image}.webp`)"
                    alt=""
                    class="img-fluid rounded mb-4 p-4"
                  />
                  <h1 class="h5 fw-bold text-center" style="color: #a95eea">
                    {{ slide.title }}
                  </h1>
                  <!-- <p class="text-muted">
                    {{ slide.text }}
                  </p> -->
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-lg-8 col-12 text-lg-center">
                      {{ slide.text }}
                    </div>
                    <div class="col-2"></div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <navigation class="d-lg-flex d-none">
                  <template #next class="d-lg-flex d-none">
                    <i
                      class="bi bi-arrow-right text-white"
                      @click="nextHandler"
                    ></i>
                  </template>
                  <template #prev class="d-lg-flex d-none">
                    <i
                      class="bi bi-arrow-left text-white"
                      @click="prevHandler"
                    ></i>
                  </template>
                </navigation>
                <Pagination class="d-lg-none d-flex pagination-style-reso" />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--end shuttle service section-->
  <!--number section-->
  <!-- <section class="container-fluid mb-lg-5 mb-5 p-lg-3 p-3">
    <div class="row">
      <div class="col-12 mb-lg-4 mb-3">
        <div class="container mb-2">
          <div class="business-line2"></div>
        </div>
        <h2 class="text-center h2 fs-3 fw-bold">Our journey in numbers</h2>
      </div>
      <div class="col-12 bg-light">
        <div
          class="
            row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3
            text-center
            container-fluid
          "
        >
          <div class="col p-lg-3 p-3 mb-2">
            <div class="row row-cols-1">
              <div class="col mb-3">
                <img
                  src="@/assets/routes-icon.png"
                  alt=""
                  class="img-fluid rounded"
                  width="50"
                />
              </div>
              <div class="col">
                <p class="fw-bolder fs-2 mb-2" style="color: #6c3a97">
                  130,000+
                </p>
                <p class="fw-bold fs-6">Rides Taken</p>
              </div>
            </div>
          </div>
          <div class="col p-lg-3 p-3 mb-2">
            <div class="row row-cols-1">
              <div class="col mb-3">
                <img
                  src="@/assets/passengers-icon.png"
                  alt=""
                  class="img-fluid rounded"
                  width="50"
                />
              </div>
              <div class="col">
                <p class="fw-bolder fs-2 mb-2" style="color: #6c3a97">4500+</p>
                <p class="fw-bold fs-6">Employees Transported</p>
              </div>
            </div>
          </div>
          <div class="col p-lg-3 p-3 mb-2">
            <div class="col mb-3">
              <img
                src="@/assets/company-icon.png"
                alt=""
                class="img-fluid rounded"
                width="50"
              />
            </div>
            <div class="col">
              <p class="fw-bolder fs-2 mb-2" style="color: #6c3a97">30+</p>
              <p class="fw-bold fs-6">Companies Served</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
    <div class="container-fluid my-5" style="background-color: #fcf9ff">
    <div class="col pt-3 journey-statistics container">
       <div class="container mb-2">
          <div class="business-line2"></div>
        </div>
      <h1 class="h3 text-center mb-3 fw-bold">{{  $t("message.journeyOfCompany.headline", {}, { locale: LoadLangShow }) }}</h1>
      <div class="row row-cols-1 row-cols-md-1 row-cols-lg-3 py-3 text-center">
        <div class="col">
          <img
            src="@/assets/routes-icon.webp"
            alt=""
            class="img-fluid w-25 mb-lg-4 mb-2"
          />
          <h4 class="fw-bolder">{{  $t("message.journeyOfCompany.rideCount", {}, { locale: LoadLangShow }) }}</h4>
          <p class="fw-bold">{{  $t("message.journeyOfCompany.rideTitle", {}, { locale: LoadLangShow }) }}</p>
        </div>
        <div class="col">
          <img
            src="@/assets/passengers-icon.webp"
            alt=""
            class="img-fluid w-25 mb-lg-4 mb-2"
          />
          <h4 class="fw-bolder">{{  $t("message.journeyOfCompany.employeeCount", {}, { locale: LoadLangShow }) }}</h4>
          <p class="fw-bold">{{  $t("message.journeyOfCompany.employeeTitle", {}, { locale: LoadLangShow }) }}</p>
        </div>
        <div class="col">
          <img
            src="@/assets/company-icon.webp"
            alt=""
            class="img-fluid w-25 mb-lg-4 mb-2"
          />
          <h4 class="fw-bolder">{{  $t("message.journeyOfCompany.companyCount", {}, { locale: LoadLangShow }) }}</h4>
          <p class="fw-bold">{{  $t("message.journeyOfCompany.companyTitle", {}, { locale: LoadLangShow }) }}</p>
        </div>
      </div>
    </div>
  </div>
  <!--end number section-->
  <!-- company section -->
  <section class="container text-lg-center text-center mb-lg-5 mb-3">
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
      <!-- <div class="col">
        <div class="row">
          <div class="col"></div>
          <div class="col">
            
          </div>
          <div class="col"></div>
        </div>
      </div> -->
      <div class="col mb-5">
        <div class="container mb-2">
          <div class="business-line2"></div>
        </div>
        <h2 class="h2 fs-3 fw-bold">
          {{  $t("message.trustedCompanies.headline", {}, { locale: LoadLangShow }) }}
        </h2>
      </div>
      <div class="col mb-3">
        <div class="container">
          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
            <div class="col mb-2">
              <img
                src="@/assets/companies/SCB_logo.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/bat_logo.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2 p-2">
              <img
                src="@/assets/companies/sheba_logo.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/enosis.webp"
                alt=""
                class="img-fluid mt-0"
                width="120"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col mb-3">
        <div class="container">
          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
            <div class="col mb-2">
              <img
                src="@/assets/companies/DBL_Group.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/HSBC.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2 p-2">
              <img
                src="@/assets/companies/Kaltimax_Energy.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/Priyoshop.webp"
                alt=""
                class="img-fluid mt-0"
                width="120"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col mb-2">
        <div class="container">
          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
            <div class="col mb-2">
              <img
                src="@/assets/companies/Sajida_Foundation.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/Solshare.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/Ulkasemi.webp"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <div class="col mb-2">
              <img
                src="@/assets/companies/United_Group.webp"
                alt=""
                class="img-fluid mt-0"
                width="120"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--end company section-->
  <!--review section-->
  <section class="container text-lg-center text-center mt-3">
    <div class="row row-cols-1 row-cols-md-1 row-cols-lg-1 mb-3">
      <div class="col mt-md-5 mt-2">
        <div class="container mb-2">
          <div class="business-line2"></div>
        </div>
        <p class="text-center h2 fs-3 fw-bold">
          {{  $t("message.testimonial.headline", {}, { locale: LoadLangShow }) }}
        </p>
      </div>
    </div>
    <div class="p-3 bg-light">
      <div class="row row-cols-1 row-cols-md-1 row-cols-lg-1">
        <div class="col mb-5">
          <Carousel
            v-if="LoadLangShow == 'bn'"
            id="b2b_testimonial"
            :items-to-show="2.5"
            :breakpoints="breakpoints3"
            :wrapAround="true"
            :mouseDrag="false"
          >
            <Slide
              v-for="slide in testimonialSlidesBangla"
              :key="slide"
              style="opacity: 1 !important"
            >
              <div class="carousel__item my-lg-5 mt-2 py-lg-4 px-lg-2">
                <div class="row d-flex flex-column">
                  <div class="col">
                    <div class="card border-0 mx-0">
                      <div class="card-body">
                        <h5 class="card-title p-0 m-0">
                          <div class="row d-flex justify-content-between">
                            <div class="col-9 text-warning">
                              <small class="d-flex flex-row bd-highlight">
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                              </small>
                            </div>
                            <div class="col-3 text-start text-lg-end">
                              <img
                                src="../assets/quotes.png"
                                alt=""
                                class="img-fluid w-50"
                              />
                            </div>
                          </div>
                        </h5>
                        <div class="row text-start text-lg-start">
                          <div class="col-12 mb-2">
                            <small class="fw-bold" style="font-size: 12px">{{
                              slide.title
                            }}</small>
                          </div>
                          <div class="col-12 mb-3">
                            <p style="font-size: 0.7rem">
                              {{ slide.text }}
                            </p>
                          </div>
                          <div class="col-12 d-flex flex-row">
                            <div class="row">
                              <div class="col">
                                <img
                                  :src="
                                    require(`@/assets/test${slide.icon}.png`)
                                  "
                                  alt=""
                                  class="img-fluid rounded-circle"
                                  width="45"
                                />
                              </div>
                            </div>
                            <div class="row d-flex flex-column px-2">
                              <h6 class="h6 m-0">{{ slide.name }}</h6>
                              <small style="font-size: 10px" class="mt-0">{{
                                slide.designation
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <navigation class="d-lg-flex d-none">
                <template #next>
                  <i
                    class="bi bi-arrow-right"
                    @click="nextReviewHandler($event)"
                  ></i>
                </template>
                <template #prev>
                  <i
                    class="bi bi-arrow-left"
                    @click="prevReviewHandler($event)"
                  ></i>
                </template>
              </navigation>
              <Pagination class="d-lg-none d-flex pagination-style-reso" />
            </template>
          </Carousel>
          <Carousel
            v-else
            id="b2b_testimonial"
            :items-to-show="2.5"
            :breakpoints="breakpoints3"
            :wrapAround="true"
            :mouseDrag="false"
          >
            <Slide
              v-for="slide in testimonialSlides"
              :key="slide"
              style="opacity: 1 !important"
            >
              <div class="carousel__item my-lg-5 mt-2 py-lg-4 px-lg-2">
                <div class="row d-flex flex-column">
                  <div class="col">
                    <div class="card border-0 mx-0">
                      <div class="card-body">
                        <h5 class="card-title p-0 m-0">
                          <div class="row d-flex justify-content-between">
                            <div class="col-9 text-warning">
                              <small class="d-flex flex-row bd-highlight">
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                                <div class="p-1 bd-highlight">
                                  <i class="bi bi-star-fill"></i>
                                </div>
                              </small>
                            </div>
                            <div class="col-3 text-start text-lg-end">
                              <img
                                src="../assets/quotes.png"
                                alt=""
                                class="img-fluid w-50"
                              />
                            </div>
                          </div>
                        </h5>
                        <div class="row text-start text-lg-start">
                          <div class="col-12 mb-2">
                            <small class="fw-bold" style="font-size: 12px">{{
                              slide.title
                            }}</small>
                          </div>
                          <div class="col-12 mb-3">
                            <p style="font-size: 0.7rem">
                              {{ slide.text }}
                            </p>
                          </div>
                          <div class="col-12 d-flex flex-row">
                            <div class="row">
                              <div class="col">
                                <img
                                  :src="
                                    require(`@/assets/test${slide.icon}.png`)
                                  "
                                  alt=""
                                  class="img-fluid rounded-circle"
                                  width="45"
                                />
                              </div>
                            </div>
                            <div class="row d-flex flex-column px-2">
                              <h6 class="h6 m-0">{{ slide.name }}</h6>
                              <small style="font-size: 10px" class="mt-0">{{
                                slide.designation
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <navigation class="d-lg-flex d-none">
                <template #next>
                  <i
                    class="bi bi-arrow-right"
                    @click="nextReviewHandler($event)"
                  ></i>
                </template>
                <template #prev>
                  <i
                    class="bi bi-arrow-left"
                    @click="prevReviewHandler($event)"
                  ></i>
                </template>
              </navigation>
              <Pagination class="d-lg-none d-flex pagination-style-reso" />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
  <!--end review section-->
  <!-- business section -->
  <section
    class="container-lg container-fluid mb-5"
    style="background: #6c3a97; border-radius: 10px"
  >
    <div class="row row-cols-1 row-cols-lg-2 p-lg-3 p-2">
      <div class="col position-relative mb-2 mt-lg-1 mt-0 p-3">
        <div class="position-absolute bottom-0 start-0 d-lg-flex d-none">
          <img src="@/assets/business_bg.png" alt="" class="img-fluid mt-0" />
        </div>
        <div class="container p-0">
          <div class="row row-cols-1">
            <div class="col mb-lg-2 mb-2">
              <h1 class="h2 text-white fw-bold">
                {{  $t("message.businessCard.title", {}, { locale: LoadLangShow }) }}
              </h1>
            </div>
            <div class="col mb-lg-3 mb-md-4 mb-5">
              <small class="fs-6 text-white">
                {{  $t("message.businessCard.body", {}, { locale: LoadLangShow }) }}
              </small>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 mb-0">
              <button
                class="
                  btn btn-lg
                  bg-white
                  text-black
                  fw-normal
                  w-100
                  mb-lg-3 mb-4
                "
                data-bs-toggle="modal"
                data-bs-target="#signupModalB2B"
              >
                {{  $t("message.businessCard.button", {}, { locale: LoadLangShow }) }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col position-relative p-lg-2 p-0">
        <div class="position-absolute bottom-0 end-0">
          <img src="@/assets/business_bg.png" alt="" class="img-fluid" />
        </div>
        <div class="card bg-transparent border-0 text-lg-end">
          <div class="card-body">
            <img
              src="@/assets/business.webp"
              alt=""
              class="img-fluid rounded shuttle-business-img"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- </div> -->
  <teleport to="body">
    <div class="container">
      <div class="row">
        <ModalB2B />
      </div>
    </div>
  </teleport>
  <!--end business section-->
    <SignupModalB2B :signupTitle=signupTitle />
  <footer>
    <the-footer></the-footer>
  </footer>
</template>

<script>
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import ModalB2B from '../components/modal/ModalB2B.vue';
import businessHeader from '../components/businessHeader.vue';
import SignupModalB2B from '../components/SignUpModalB2B.vue'
import $ from 'jquery';
import TheFooterVue from '../components/TheFooter.vue';

export default {
  name: 'Business',
  components: {
    businessHeader: businessHeader,
    Carousel,
    Slide,
    Navigation,
    Pagination,
    ModalB2B,
    SignupModalB2B,
    TheFooter: TheFooterVue
  },
  computed:{
    LoadLangShow(){
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  data() {
    return {
      signupTitle: 'business',
      currentSlide: 0,
      currentReviewSlide: 0,
      isNext: false,
      slides: [
        {
          title: 'Flexible Plan',
          bg: '#F8F5FF',
          image: '7',
          ID: 1,
          text: 'Create a Tailor-made plan that suits your business needs.'
        },
        {
          title: 'User Onboarding',
          image: '8',
          ID: 2,
          bg: '#F2FAF7',
          text: 'Assign routes and schedules for your team members.'
        },
        {
          title: 'Relationship Manager',
          image: '9',
          ID: 3,
          bg: '#EFF8FE',
          text: 'Contact with your relationship manager for support when required.'
        },
        {
          title: 'Dashboard and Apps',
          image: '10',
          ID: 4,
          bg: '#FDF1F9',
          text: 'Get access to your personalized dashboard and app to keep track of your fleet.'
        },
        {
          title: 'Flexible Plan',
          bg: '#F8F5FF',
          image: '7',
          ID: 5,
          text: 'Create a Tailor-made plan that suits your business needs.'
        },
        {
          title: 'User Onboarding',
          image: '8',
          ID: 6,
          bg: '#F2FAF7',
          text: 'Assign routes and schedules for your team members.'
        },
        {
          title: 'Relationship Manager',
          image: '9',
          ID: 7,
          bg: '#EFF8FE',
          text: 'Contact with your relationship manager for support when required.'
        },
        {
          title: 'Dashboard and Apps',
          image: '10',
          ID: 8,
          bg: '#FDF1F9',
          text: 'Get access to your personalized dashboard and app to keep track of your fleet.'
        },
        {
          title: 'Flexible Plan',
          bg: '#F8F5FF',
          image: '7',
          ID: 9,
          text: 'Create a Tailor-made plan that suits your business needs.'
        },
        {
          title: 'User Onboarding',
          image: '8',
          ID: 10,
          bg: '#F2FAF7',
          text: 'Assign routes and schedules for your team members.'
        }
      ],
      slidesBangla: [
        {
          title: 'ফ্লেক্সিবল প্ল্যান',
          bg: '#F8F5FF',
          image: '7',
          ID: 1,
          text: 'আপনার কোম্পানীর সুবিধা এবং প্রয়োজন অনুযায়ী তৈরী করুন আপনার ট্রান্সপোর্টেশন প্ল্যান।'
        },
        {
          title: 'ইউজার অনবোর্ডিং',
          image: '8',
          ID: 2,
          bg: '#F2FAF7',
          text: 'আপনার টিম মেম্বারদের জন্য রুট এবং সময় নির্ধারণ করুন।'
        },
        {
          title: 'রিলেশনশিপ ম্যানেজার',
          image: '9',
          ID: 3,
          bg: '#EFF8FE',
          text: 'আপনার যেকোন প্রয়োজনে রিলেশনশিপ ম্যানেজার এর সহায়তা নিন।'
        },
        {
          title: 'ড্যাশবোর্ড এবং অ্যাপ',
          image: '10',
          ID: 4,
          bg: '#FDF1F9',
          text: 'আপনার অধীনে থাকা রাইড বা গাড়ি ব্যবস্থাপনার জন্য আপনি পাচ্ছেন কাস্টমাইজড অ্যাপ এবং ড্যাশবোর্ড।'
        },
        {
          title: 'ফ্লেক্সিবল প্ল্যান',
          bg: '#F8F5FF',
          image: '7',
          ID: 5,
          text: 'আপনার কোম্পানীর সুবিধা এবং প্রয়োজন অনুযায়ী তৈরী করুন আপনার ট্রান্সপোর্টেশন প্ল্যান।'
        },
        {
          title: 'ইউজার অনবোর্ডিং',
          image: '8',
          ID: 6,
          bg: '#F2FAF7',
          text: 'আপনার টিম মেম্বারদের জন্য রুট এবং সময় নির্ধারণ করুন।'
        },
        {
          title: 'রিলেশনশিপ ম্যানেজার',
          image: '9',
          ID: 7,
          bg: '#EFF8FE',
          text: 'আপনার যেকোন প্রয়োজনে রিলেশনশিপ ম্যানেজার এর সহায়তা নিন।'
        },
        {
          title: 'ড্যাশবোর্ড এবং অ্যাপ',
          image: '10',
          ID: 8,
          bg: '#FDF1F9',
          text: 'আপনার অধীনে থাকা রাইড বা গাড়ি ব্যবস্থাপনার জন্য আপনি পাচ্ছেন কাস্টমাইজড অ্যাপ এবং ড্যাশবোর্ড।'
        },
        {
          title: 'ফ্লেক্সিবল প্ল্যান',
          bg: '#F8F5FF',
          image: '7',
          ID: 9,
          text: 'আপনার কোম্পানীর সুবিধা এবং প্রয়োজন অনুযায়ী তৈরী করুন আপনার ট্রান্সপোর্টেশন প্ল্যান।'
        },
        {
          title: 'ইউজার অনবোর্ডিং',
          image: '8',
          ID: 10,
          bg: '#F2FAF7',
          text: 'আপনার টিম মেম্বারদের জন্য রুট এবং সময় নির্ধারণ করুন।'
        }
      ],
      serviceSlides: [
        {
          title: 'Monitor Trips',
          text: `Trip time & date, driver details, passengers' attendance everything you need to know about your trips, it’s all here.`,
          image: '1'
        },
        {
          image: '2',
          title: 'Track vehicles in real-time ',
          text: 'More power to you with real-time trip tracker. The dashboard lets you see location of of your trips and passengers.'
        },
        {
          title: 'Reach out for Support',
          text: 'Anything you need, report issues, edit your plan, request additional services and more. Everything is possible with your dashboard.',
          image: '3'
        },
        {
          title: 'Monitor Trips',
          text: `Trip time & date, driver details, passengers' attendance everything you need to know about your trips, it’s all here.`,
          image: '1'
        },
        {
          image: '2',
          title: 'Track vehicles in real-time ',
          text: 'More power to you with real-time trip tracker. The dashboard lets you see location of of your trips and passengers.'
        },
        {
          title: 'Reach out for Support',
          text: 'Anything you need, report issues, edit your plan, request additional services and more. Everything is possible with your dashboard.',
          image: '3'
        },
        {
          title: 'Monitor Trips',
          text: `Trip time & date, driver details, passengers' attendance everything you need to know about your trips, it’s all here.`,
          image: '1'
        },
        {
          image: '2',
          title: 'Track vehicles in real-time ',
          text: 'More power to you with real-time trip tracker. The dashboard lets you see location of of your trips and passengers.'
        },
        {
          title: 'Reach out for Support',
          text: 'Anything you need, report issues, edit your plan, request additional services and more. Everything is possible with your dashboard.',
          image: '3'
        },
        {
          title: 'Monitor Trips',
          text: `Trip time & date, driver details, passengers' attendance everything you need to know about your trips, it’s all here.`,
          image: '1'
        }
      ],
      serviceSlidesBangla: [
        {
          title: 'ট্রিপ পর্যবেক্ষণ করুন',
          text: `ট্রিপের সময়সূচি, ড্রাইভার এর তথ্য, যাত্রীদের উপস্থিতি ইত্যাদি ট্রিপ সম্পর্কিত সব তথ্য দেখুন।`,
          image: '1'
        },
        {
          image: '2',
          title: 'যানবাহন ট্র্যাক করুন',
          text: 'রিয়েল টাইম ট্রিপ ট্র্যাকার আপনাকে দেবে যেকোন সময়ে আপনার ট্রিপ এবং এমপ্লয়ীদের লোকেশন জেনে নেয়ার সুবিধা।'
        },
        {
          title: 'যেকোন প্রয়োজনে যোগাযোগ করুন',
          text: 'ড্যাশবোর্ড থেকে যেকোন ইস্যু রিপোর্ট করুন, প্ল্যান এডিট করুন এবং নতুন সার্ভিস রিকোয়েস্ট করুন। আপনার প্রয়োজনীয় সবকিছুই করতে পারবেন এই ড্যাশবোর্ডে।',
          image: '3'
        },
        {
          title: 'ট্রিপ পর্যবেক্ষণ করুন',
          text: `ট্রিপের সময়সূচি, ড্রাইভার এর তথ্য, যাত্রীদের উপস্থিতি ইত্যাদি ট্রিপ সম্পর্কিত সব তথ্য দেখুন।`,
          image: '1'
        },
        {
          image: '2',
          title: 'যানবাহন ট্র্যাক করুন',
          text: 'রিয়েল টাইম ট্রিপ ট্র্যাকার আপনাকে দেবে যেকোন সময়ে আপনার ট্রিপ এবং এমপ্লয়ীদের লোকেশন জেনে নেয়ার সুবিধা।'
        },
        {
          title: 'যেকোন প্রয়োজনে যোগাযোগ করুন',
          text: 'ড্যাশবোর্ড থেকে যেকোন ইস্যু রিপোর্ট করুন, প্ল্যান এডিট করুন এবং নতুন সার্ভিস রিকোয়েস্ট করুন। আপনার প্রয়োজনীয় সবকিছুই করতে পারবেন এই ড্যাশবোর্ডে।',
          image: '3'
        },
        {
          title: 'ট্রিপ পর্যবেক্ষণ করুন',
          text: `ট্রিপের সময়সূচি, ড্রাইভার এর তথ্য, যাত্রীদের উপস্থিতি ইত্যাদি ট্রিপ সম্পর্কিত সব তথ্য দেখুন।`,
          image: '1'
        },
        {
          image: '2',
          title: 'যানবাহন ট্র্যাক করুন',
          text: 'রিয়েল টাইম ট্রিপ ট্র্যাকার আপনাকে দেবে যেকোন সময়ে আপনার ট্রিপ এবং এমপ্লয়ীদের লোকেশন জেনে নেয়ার সুবিধা।'
        },
        {
          title: 'যেকোন প্রয়োজনে যোগাযোগ করুন',
          text: 'ড্যাশবোর্ড থেকে যেকোন ইস্যু রিপোর্ট করুন, প্ল্যান এডিট করুন এবং নতুন সার্ভিস রিকোয়েস্ট করুন। আপনার প্রয়োজনীয় সবকিছুই করতে পারবেন এই ড্যাশবোর্ডে।',
          image: '3'
        },
        {
          title: 'ট্রিপ পর্যবেক্ষণ করুন',
          text: `ট্রিপের সময়সূচি, ড্রাইভার এর তথ্য, যাত্রীদের উপস্থিতি ইত্যাদি ট্রিপ সম্পর্কিত সব তথ্য দেখুন।`,
          image: '1'
        }
      ],
      testimonialSlides: [
        {
          title: 'FLEXIBLE AND SUPPORTIVE',
          text: 'During pandemic, they played vital role for picking and dropping our employees with great integrity & safety.',
          icon: '1',
          name: 'Md. Jishan Rahman',
          designation: 'Admin Manager, ME SOLshare Ltd.'
        },
        {
          title: 'QUALITY SERVICE',
          text: 'Shuttle believes in providing quality service and always tries to sustain it.',
          icon: '2',
          name: 'Md. Ahmadul Huq Bhuiyan',
          designation: '(MBT)- 1 ,HRD, Ulkasemi'
        },
        {
          title: 'FLEXIBLE AND SUPPORTIVE',
          text: 'During pandemic, they played vital role for picking and dropping our employees with great integrity & safety.',
          icon: '1',
          name: 'Md. Jishan Rahman',
          designation: 'Admin Manager, ME SOLshare Ltd.'
        },
        {
          title: 'QUALITY SERVICE',
          text: 'Shuttle believes in providing quality service and always tries to sustain it.',
          icon: '2',
          name: 'Md. Ahmadul Huq Bhuiyan',
          designation: '(MBT)- 1 ,HRD, Ulkasemi'
        },
        {
          title: 'FLEXIBLE AND SUPPORTIVE',
          text: 'During pandemic, they played vital role for picking and dropping our employees with great integrity & safety.',
          icon: '1',
          name: 'Md. Jishan Rahman',
          designation: 'Admin Manager, ME SOLshare Ltd.'
        },
        {
          title: 'QUALITY SERVICE',
          text: 'Shuttle believes in providing quality service and always tries to sustain it.',
          icon: '2',
          name: 'Md. Ahmadul Huq Bhuiyan',
          designation: '(MBT)- 1 ,HRD, Ulkasemi'
        },
        {
          title: 'FLEXIBLE AND SUPPORTIVE',
          text: 'During pandemic, they played vital role for picking and dropping our employees with great integrity & safety.',
          icon: '1',
          name: 'Md. Jishan Rahman',
          designation: 'Admin Manager, ME SOLshare Ltd.'
        },
        {
          title: 'QUALITY SERVICE',
          text: 'Shuttle believes in providing quality service and always tries to sustain it.',
          icon: '2',
          name: 'Md. Ahmadul Huq Bhuiyan',
          designation: '(MBT)- 1 ,HRD, Ulkasemi'
        },
        {
          title: 'FLEXIBLE AND SUPPORTIVE',
          text: 'During pandemic, they played vital role for picking and dropping our employees with great integrity & safety.',
          icon: '1',
          name: 'Md. Jishan Rahman',
          designation: 'Admin Manager, ME SOLshare Ltd.'
        },
        {
          title: 'QUALITY SERVICE',
          text: 'Shuttle believes in providing quality service and always tries to sustain it.',
          icon: '2',
          name: 'Md. Ahmadul Huq Bhuiyan',
          designation: '(MBT)- 1 ,HRD, Ulkasemi'
        }
      ],
      testimonialSlidesBangla: [
        {
          title: 'ফ্লেক্সিবল এবং সাপোর্টিভ',
          text: 'প্যান্ডেমিকের সময় শাটেল অত্যন্ত নিষ্ঠার সাথে আমাদের এমপ্লয়ীদের নিরাপদে যাতায়াত নিশ্চিত করে।',
          icon: '1',
          name: 'মোঃ জিসান রাহমান',
          designation: 'অ্যাডমিন ম্যানেজার, এমই সোলশেয়ার লিঃ'
        },
        {
          title: 'মানসম্মত সেবা',
          text: 'শাটল মানসম্পন্ন সেবা প্রদানে বিশ্বাস করে এবং সবসময় সেই মান ধরে রাখার চেষ্টা করে।',
          icon: '2',
          name: 'মোঃ আহমাদুল হক ভুঁইয়া',
          designation: '(এমবিটি)-১, এইচআরডি, উলকাসেমি'
        },
        {
          title: 'ফ্লেক্সিবল এবং সাপোর্টিভ',
          text: 'প্যান্ডেমিকের সময় শাটেল অত্যন্ত নিষ্ঠার সাথে আমাদের এমপ্লয়ীদের নিরাপদে যাতায়াত নিশ্চিত করে।',
          icon: '1',
          name: 'মোঃ জিসান রাহমান',
          designation: 'অ্যাডমিন ম্যানেজার, এমই সোলশেয়ার লিঃ'
        },
        {
          title: 'মানসম্মত সেবা',
          text: 'শাটল মানসম্পন্ন সেবা প্রদানে বিশ্বাস করে এবং সবসময় সেই মান ধরে রাখার চেষ্টা করে।',
          icon: '2',
          name: 'মোঃ আহমাদুল হক ভুঁইয়া',
          designation: '(এমবিটি)-১, এইচআরডি, উলকাসেমি'
        },
        {
          title: 'ফ্লেক্সিবল এবং সাপোর্টিভ',
          text: 'প্যান্ডেমিকের সময় শাটেল অত্যন্ত নিষ্ঠার সাথে আমাদের এমপ্লয়ীদের নিরাপদে যাতায়াত নিশ্চিত করে।',
          icon: '1',
          name: 'মোঃ জিসান রাহমান',
          designation: 'অ্যাডমিন ম্যানেজার, এমই সোলশেয়ার লিঃ'
        },
        {
          title: 'মানসম্মত সেবা',
          text: 'শাটল মানসম্পন্ন সেবা প্রদানে বিশ্বাস করে এবং সবসময় সেই মান ধরে রাখার চেষ্টা করে।',
          icon: '2',
          name: 'মোঃ আহমাদুল হক ভুঁইয়া',
          designation: '(এমবিটি)-১, এইচআরডি, উলকাসেমি'
        },
        {
          title: 'ফ্লেক্সিবল এবং সাপোর্টিভ',
          text: 'প্যান্ডেমিকের সময় শাটেল অত্যন্ত নিষ্ঠার সাথে আমাদের এমপ্লয়ীদের নিরাপদে যাতায়াত নিশ্চিত করে।',
          icon: '1',
          name: 'মোঃ জিসান রাহমান',
          designation: 'অ্যাডমিন ম্যানেজার, এমই সোলশেয়ার লিঃ'
        },
        {
          title: 'মানসম্মত সেবা',
          text: 'শাটল মানসম্পন্ন সেবা প্রদানে বিশ্বাস করে এবং সবসময় সেই মান ধরে রাখার চেষ্টা করে।',
          icon: '2',
          name: 'মোঃ আহমাদুল হক ভুঁইয়া',
          designation: '(এমবিটি)-১, এইচআরডি, উলকাসেমি'
        },
        {
          title: 'ফ্লেক্সিবল এবং সাপোর্টিভ',
          text: 'প্যান্ডেমিকের সময় শাটেল অত্যন্ত নিষ্ঠার সাথে আমাদের এমপ্লয়ীদের নিরাপদে যাতায়াত নিশ্চিত করে।',
          icon: '1',
          name: 'মোঃ জিসান রাহমান',
          designation: 'অ্যাডমিন ম্যানেজার, এমই সোলশেয়ার লিঃ'
        },
        {
          title: 'মানসম্মত সেবা',
          text: 'শাটল মানসম্পন্ন সেবা প্রদানে বিশ্বাস করে এবং সবসময় সেই মান ধরে রাখার চেষ্টা করে।',
          icon: '2',
          name: 'মোঃ আহমাদুল হক ভুঁইয়া',
          designation: '(এমবিটি)-১, এইচআরডি, উলকাসেমি'
        }
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      breakpoints: {
        280: {
          itemsToShow: 1,
          snapAlign: 'center'
        },
        1024: {
          itemsToShow: 3.5,
          snapAlign: 'start'
        }
      },
      breakpoints2: {
        280: {
          itemsToShow: 1,
          snapAlign: 'center'
        },
        1024: {
          itemsToShow: 2.9,
          snapAlign: 'center'
        }
      },
      breakpoints3: {
        280: {
          itemsToShow: 1,
          snapAlign: 'center'
        },
        1024: {
          itemsToShow: 2.9,
          snapAlign: 'center'
        }
      }
    };
  },
  methods: {
    SlideMouseOverHandler() {
      let lists = document.querySelectorAll(
        '#b2b_carousel_work.carousel .carousel__viewport ol.carousel__track>li'
      );
      let activeEl = 'carousel__slide--active';
      lists.forEach(function (el) {
        el.classList.remove(activeEl);
      });
    },
    prevHandler() {
      this.currentSlide > 0 ? this.currentSlide-- : 0;
    },
    nextHandler() {
      this.currentSlide < this.slides.length - 1
        ? this.currentSlide++
        : this.currentSlide;
    },
    nextReviewHandler(e) {
      e.currentTarget.parentNode.setAttribute(
        'style',
        'background: #7F40D3 !important; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 50%; transform: translate(-50%, -50%);'
      );
      const prevButton = e.currentTarget.parentNode.previousSibling;
      prevButton.setAttribute('style', '');
      prevButton.setAttribute(
        'style',
        'background: #fff; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 45%; transform: translate(-50%, -50%);'
      );
      prevButton.childNodes[0].setAttribute('style', 'color: #7F40D3');
      e.currentTarget.setAttribute('style', 'color: #ffffff !important;');
    },
    prevReviewHandler(e) {
      e.currentTarget.parentNode.setAttribute(
        'style',
        'background: #7F40D3 !important; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 45%; transform: translate(-50%, -50%);'
      );
      const nextButton = e.currentTarget.parentNode.nextElementSibling;
      nextButton.setAttribute('style', '');
      nextButton.setAttribute(
        'style',
        'background: #fff; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 50%; transform: translate(-50%, -50%);'
      );
      nextButton.childNodes[0].setAttribute('style', 'color: #7F40D3');
      e.currentTarget.setAttribute('style', 'color: #ffffff !important;');
    }
  },
  mounted() {
    var btn = $('#button');
    $(window).scroll(function() {
      if ($(window).scrollTop() > 500) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });

    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
    });
    // carousel button styling change
    document
      .querySelector('#shuttle_service .carousel__next')
      .setAttribute(
        'style',
        'background: #7F40D3 !important; border-radius: 5px !important; margin: 0 20px;width: 50px; height: 42px;'
      );
    document
      .querySelector('#shuttle_service .carousel__prev')
      .setAttribute(
        'style',
        ' background: #FFFFFF !important; border-radius: 5px !important; margin: 0 20px;width: 50px; height: 42px; cursor: not-allowed;'
      );

    // icon color change
    document
      .querySelector('#shuttle_service .carousel__prev i')
      .setAttribute(
        'style',
        'color: #7F40D3 !important; font-size: 20px !important; padding: 5px 4px !important; cursor: not-allowed;'
      );
    document
      .querySelector('#shuttle_service .carousel__next i')
      .setAttribute(
        'style',
        'color: #ffffff !important; font-size: 20px !important; padding: 5px 4px !important; font-weight: bold;'
      );

    // b2b testimonial carousel next and prev button position change
    document
      .querySelector('#b2b_testimonial .carousel__next')
      .setAttribute(
        'style',
        'background: #7F40D3; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 50%; transform: translate(-50%, -50%);'
      );

    document
      .querySelector('#b2b_testimonial .carousel__prev')
      .setAttribute(
        'style',
        'background: #FFFFFF !important; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 45%; transform: translate(-50%, -50%);'
      );

    document
      .querySelector('#b2b_testimonial .carousel__prev i')
      .setAttribute(
        'style',
        'color: #7F40D3 !important; font-size: 20px !important; padding: 5px 4px !important;'
      );
  },
  watch: {
    currentSlide(val) {
      if (!val) {
        document
          .querySelector('#shuttle_service .carousel__prev')
          .setAttribute(
            'style',
            'cursor: not-allowed; background: #FFFFFF !important; border-radius: 5px !important; margin: 0 20px;width: 50px; height: 42px;'
          );

        document
          .querySelector('#shuttle_service .carousel__prev i')
          .setAttribute(
            'style',
            'color: #7F40D3 !important; font-size: 20px !important; padding: 5px 4px !important; font-weight: bold;'
          );
      } else {
        document
          .querySelector('#shuttle_service .carousel__prev')
          .setAttribute(
            'style',
            'background: #7F40D3 !important; border-radius: 5px !important; margin: 0 20px;width: 50px; height: 42px;'
          );
        document
          .querySelector('#shuttle_service .carousel__prev i')
          .setAttribute(
            'style',
            'color: #ffffff !important; font-size: 20px !important; padding: 5px 4px !important; font-weight: bold;'
          );
      }

      if (val === this.serviceSlides.length - 1) {
        document
          .querySelector('#shuttle_service .carousel__next')
          .setAttribute(
            'style',
            'background: #ffffff !important; border-radius: 5px !important; margin: 0 20px;width: 50px; height: 42px; cursor: not-allowed;'
          );
        document
          .querySelector('#shuttle_service .carousel__next i')
          .setAttribute(
            'style',
            'color: #7F40D3 !important; font-size: 20px !important; padding: 5px 4px !important; font-weight: bold;'
          );
      } else {
        document
          .querySelector('#shuttle_service .carousel__next')
          .setAttribute(
            'style',
            'background: #7F40D3 !important; border-radius: 5px !important; margin: 0 20px;width: 50px; height: 42px;'
          );

        document
          .querySelector('#shuttle_service .carousel__next i')
          .setAttribute(
            'style',
            'color: #ffffff !important; font-size: 20px !important; padding: 5px 4px !important; font-weight: bold;'
          );
      }
    }
  }
};
</script>
<style scoped>
section.container-fluid.mb-lg-5.mb-5.p-lg-3.p-3 {
  background: #f8f9fa !important;
}
.container-fluid.my-5{
    background: #f8f9fa !important;
}
.commute-dashboard .carousel__prev {
  top: 50% !important;
  left: 0% !important;
  transform: translate(-50%, -50%) !important;
}

.commute-dashboard .carousel__next {
  top: 50% !important;
  left: 100% !important;
  transform: translate(-50%, -50%) !important;
}
.shuttle-business-img {
  width: 430px;
  height: 100%;
  object-fit: cover;
}

.business-image {
  border-radius: 15px !important;
}
</style>
<style scoped>
#b2b_testimonial .carousel button.carousel__prev {
  top: 95% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

#b2b_testimonial .carousel button.carousel__next {
  top: 95% !important;
  left: 55% !important;
  transform: translate(-50%, -50%) !important;
}
.business-line {
  position: absolute;
  width: 100px;
  height: 7px;
  background: #6c3a97;
  border-radius: 3px;
}
.business-line2 {
  width: 100px;
  margin: 0 auto;
  height: 7px;
  background: #6c3a97;
  border-radius: 3px;
}
.business-btn {
  background: #6c3a97;
  border-radius: 4px;
  width: 75%;
  height: auto;
}
#b2b_carousel_work .carousel__slide > .carousel__item {
  transform: scale(1.05);
  opacity: 0.8;
  transition: 0.5s;
}

#b2b_carousel_work .carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}

#b2b_carousel_work .carousel__slide--next > .carousel__item {
  transform: scale(0.7) translate(60px);
}

#b2b_carousel_work .carousel__slide--prev > .carousel__item {
  transform: scale(0.7) translate(-60px);
}

#b2b_carousel_work .carousel__slide--active > .carousel__item {
  transform: scale(1.4);
  transition: 0.5s;
}

#b2b_testimonial .carousel__slide > .carousel__item {
  transform: scale(1.05);
  opacity: 0.8;
  transition: 0.5s;
}

#b2b_testimonial .carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}

#b2b_testimonial .carousel__slide--next > .carousel__item {
  transform: scale(0.7) translate(60px);
}

#b2b_testimonial .carousel__slide--prev > .carousel__item {
  transform: scale(0.7) translate(-60px);
}

#b2b_testimonial .carousel__slide--active > .carousel__item {
  transform: scale(1.4);
  transition: 0.5s;
}

#b2b_testimonial button.carousel__prev {
  top: 100% !important;
  left: 50% !important;
  transform: (-50%, -50%) !important;
}

#b2b_carousel_work .carousel__prev {
  top: 95% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

#b2b_carousel_work .carousel__next {
  top: 95% !important;
  left: 55% !important;
  transform: translate(-50%, -50%) !important;
}

#b2b_carousel_work
  .carousel__slide.carousel__slide--active.carousel__slide--visible {
  transform: scale(1.061) translate(0px);
  opacity: 1;
  margin: 0px 8px;
}
#b2b_carousel_work .carousel__slide.carousel__slide--visible:hover {
  transform: scale(1.061) translate(0px);
  opacity: 1;
  margin: 0px 8px;
  /* height: 15rem; */
}

#b2b_carousel_work .carousel__slide.carousel__slide--visible .card:hover,
#b2b_carousel_work .carousel__slide.carousel__slide--next .card:hover {
  height: 15rem;
  margin: 0px 8px;
}
#b2b_carousel_work .carousel__slide.carousel__slide--visible,
#b2b_carousel_work .carousel__slide.carousel__slide--next {
  transform: scale(1.002222) translate(5px);
  opacity: 1;
}

#b2b_carousel_work .carousel__slide.carousel__slide--visible .card,
#b2b_carousel_work .carousel__slide.carousel__slide--next .card {
  height: 14rem;
}

#b2b_carousel_work
  .carousel__slide.carousel__slide--active.carousel__slide--visible
  .card {
  height: 15rem;
}

#b2b_carousel .carousel__slide.carousel__slide--active.carousel__slide--visible,
#b2b_carousel
  .carousel__slide.carousel__slide--active.carousel__slide--visible:hover {
  transform: scale(1.061) translate(0px);
  opacity: 1;
  padding: 0;
}

#b2b_carousel .carousel__slide.carousel__slide--visible,
#b2b_carousel .carousel__slide.carousel__slide--next {
  transform: scaleY(1.002222) translateY(0px);
  opacity: 0.8;
}

/* transform y-axis when hover item */
#b2b_carousel .carousel__slide.carousel__slide--visible:hover,
#b2b_carousel .carousel__slide.carousel__slide--next:hover {
  transform: scaleY(1.002222) translateY(12px);
  opacity: 1;
  padding-bottom: 3px;
  margin-bottom: 0px;
}

/* #b2b_carousel
  .carousel__slide.carousel__slide--active.carousel__slide--visible {
  height: 15rem !important;
} */

#shuttle_service button.carousel__prev {
  border-radius: 0px !important;
}

@media (max-width: 991px) {
  .shuttle-business-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .business-btn {
    width: 100%;
    height: auto;
  }
  small.text-muted.fs-6 br {
    display: none;
  }
  h1.h2.fs-3.fw-bold br {
    display: none;
  }
  .card-body.shuttle-works br {
    display: none;
  }
  #b2b_carousel
    .carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(1) !important;
    opacity: 1;
  }
  #b2b_carousel_work
    .carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(1) !important;
    opacity: 1;
  }
  #b2b_carousel_work .carousel__slide.carousel__slide--visible .card {
    height: 15rem !important;
  }

  #shuttle_service .carousel__prev,
  #shuttle_service .carousel__next {
    display: none;
  }

  #b2b_testimonial .carousel__slide--active > .carousel__item {
    transform: scale(1) !important;
    transition: 0.5s;
  }
  #b2b_testimonial .carousel__slide.carousel__slide--prev {
    margin: -6px !important;
  }

  #b2b_testimonial
    .carousel__slide.carousel__slide--active.carousel__slide--visible[data-v-5cd57864] {
    transform: scale(1) !important;
    opacity: 1;
  }

  /* .carousel__prev {
    left: 40% !important;
  } */
  /* #b2b_carousel_work .carousel__prev {
    top: 95% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  } */
}

@media (max-width: 1023px) and (min-width: 991px) {
  .bottom-rectangle {
    /* margin: 100px 12px; */
    border-radius: 8px;
    width: 30%;
  }

  .top-rectangle {
    width: 30%;
  }
  #b2b_carousel_work .carousel__slide.carousel__slide--visible .card,
  #b2b_carousel_work .carousel__slide.carousel__slide--next .card {
    height: 14.7rem !important;
  }
  #b2b_carousel_work
    .carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(1) translate(0px);
    opacity: 1;
    margin: 0px 8px;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .bottom-rectangle {
    margin: 0px 73px;
    border-radius: 8px;
    width: 30%;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .bottom-rectangle {
    margin: 0px 73px;
    border-radius: 8px;
    width: 30%;
  }
  .position-absolute.top-0.start-0.top-rectangle.mx-xl-2.mx-lg-2.mx-0 img {
    width: 130px;
    padding-left: 10px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1340px) {
  #b2b_carousel_work
    .carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(1) translate(0px);
    opacity: 1;
    margin: 0px 8px;
  }
  #b2b_carousel_work .carousel__slide.carousel__slide--visible .card,
  #b2b_carousel_work .carousel__slide.carousel__slide--next .card {
    height: 14.9rem;
  }

  #b2b_carousel_work
    .carousel__slide.carousel__slide--active.carousel__slide--visible
    .card {
    height: 16rem;
  }
}

@media only screen and (min-device-width: 1341px) {
  #b2b_carousel_work
    .carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(1) translate(0px);
    opacity: 1;
    margin: 0px 8px;
  }
  #b2b_carousel_work .carousel__slide.carousel__slide--visible .card,
  #b2b_carousel_work .carousel__slide.carousel__slide--next .card {
    height: 14.9rem;
  }

  #b2b_carousel_work
    .carousel__slide.carousel__slide--active.carousel__slide--visible
    .card {
    height: 16rem;
  }
}
.bar{
  position: fixed;
  bottom: 30px;
  right: 100px;
  z-index: 9999999999999;
  }
</style>

<style>
/* ol.carousel__pagination.d-lg-none.d-flex {
    justify-content: space-between !important;
    width: 100px !important;
    text-align: center !important;
    padding-left: 25% !important;
} */
</style>
<style>
.pagination-style-reso {
  overflow: hidden;
  padding-left: 10px;
}

#button {
  display: inline-block;
  background-color: #6C3A97;
  border: 1px solid #6C3A97;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bolder;
  padding: 8px 20px;
  text-decoration: none;
  position: fixed;
  bottom: 30px;
  right: 100px;
  transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#button:hover {
  cursor: pointer;
  background-color: #6C3A97;
}
#button:active {
  background-color: #6C3A97;
}
#button.show {
  opacity: 1;
  visibility: visible;
}
</style>