<template>
  <!-- B2BModalRegisterForm -->
  <div
    class="modal fade"
    id="B2BModalRegisterForm"
    tabindex="-1"
    aria-labelledby="B2BModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close btn-sm"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body border-0 ps-0">
          <div class="container">
            <h2 class="h4 text-dark mb-3">Move with us</h2>
            <form class="form" @submit.prevent="submitHandler">
              <div class="mb-3">
                <label for="name" class="form-label mb-1">Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reg.contact_name"
                  id="name"
                  placeholder="Enter name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label mb-1">Email</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="reg.email"
                  id="email"
                  placeholder="Enter email"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="name" class="form-label mb-1">Phone No</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reg.phone"
                  pattern="(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$"
                  id="phone"
                  placeholder="Enter phone number"
                  required
                />
                <span
                  v-if="mobileValidateMsg"
                  class="mobile-validate-msg text-danger ps-2"
                  >{{ mobileValidateMsg }}</span
                >
              </div>
              <div class="mb-3">
                <label for="Organization" class="form-label mb-1">Organization</label>
                <input
                  type="text"
                  class="form-control"
                  id="organization"
                  v-model="reg.company_name"
                  placeholder="Enter name"
                  required
                />
              </div>
              <div class="row mb-4 mt-5">
                <div class="col-12">
                  <button class="submit-btn w-100">submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    :class="{ show: isModalOpen }"
    id="b2bModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div id="sub-notification" class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 text-center" id="icon">
              <h1><i class="fa fa-check-circle"></i></h1>
            </div>
            <div class="col-md-12 text-center" id="notification">
              <p>
                Thank you for signing up. We will get back to you within 24 hours. For any
                queries, call us at +8809638888868
              </p>
              <button
                type="button"
                class="btn btn-danger btn-sm mb-3"
                id="submit"
                data-dismiss="modal"
                @click="closeModal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "B2BModalRegisterForm",
  data() {
    return {
      reg: {
        company_name: "",
        contact_name: "",
        phone: "",
        email: "",
        no_of_employees: 0,
      },
      isModalOpen: false,
      mobileValidateMsg: "",
    };
  },
  methods: {
    submitHandler() {
      if (this.reg.phone.length < 11 || this.reg.phone.length > 11) {
        this.mobileValidateMsg = "Incorrect phone number";
      } else {
        this.mobileValidateMsg = "";
        axios
          .post("https://api.shuttlebd.com/signup", this.reg)
          .then((response) => {
            console.log(response);
            let modal = document.getElementById("b2bModal");
            let regModal = document.getElementById("B2BModalRegisterForm");
            // hide reg modal
            regModal.classList.remove("show");
            // show modal
            modal.style.display = "block";
            regModal.style.display = "none";
          })
          .catch((error) => {
            console.log("error =======", error);
            this.isModalOpen = true;
            let modal = document.getElementById("b2bModal");
            let regModal = document.getElementById("B2BModalRegisterForm");
            // hide reg modal
            regModal.classList.remove("show");
            // show modal
            modal.style.display = "block";
            regModal.style.display = "none";
            console.log("error =======", error);
            // handle erros
          });
      }
    },
    closeModal() {
      this.isModalOpen = false;
      let modal = document.querySelector(".modal-backdrop");

      modal.classList.remove("show");
      let modal2 = document.getElementById("b2bModal");
      modal2.style.display = "none";
      modal2.classList.remove("show");
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.modal {
  font-family: "Montserrat", sans-serif;
}
.modal-header {
  border-bottom: none;
}
.modal-content {
  /* background-color: #242d66; */
  color: #5d5d5e;
  /*font-family: sans-serif;*/
}
.modal-body {
  padding: 0px;
}
.modal-title h4 {
  font-size: 20px;
  padding-top: 8%;
}
.modal-title p {
  padding: 0 10% 0 10%;
  font-weight: normal;
}
.submit-btn {
  background-color: #6c3a97;
  color: #fff;
  font-weight: normal;
  border-radius: 8px;
  border: none;
  text-transform: capitalize;
  padding: 5px;
}
.form-control {
  font-size: 0.8rem !important;
}
</style>
