<template>
  <section class="blog-section">
    <div class="container my-5">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <div class="faq-border"></div>
          <h2 class="text-center mb-5">Blog</h2>

          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-12 col-md-6 col-xl-3 mb-4">
                  <a
                    href="https://shuttlebd.com/blogs/shuttle-rebranding/"
                    style="color: black; text-decoration: none"
                  >
                    <div class="card mr-3">
                      <img
                        src="/assets/images/rent.webp"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">The Shuttle Story - How a team of ...</h5>
                        <p class="card-text">
                          We started this journey in 2018 with only 2 micro busses to help
                          students commute to their universities. Founded by Reyasat
                          Chowdhury, Jawwad Jahangir, and Shah..
                        </p>
                        <a
                          href="https://shuttlebd.com/blogs/shuttle-rebranding/"
                          class="btn btn-primary"
                          >Read more</a
                        >
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-12 col-md-6 col-xl-3 mb-4">
                  <a
                    href="https://shuttlebd.com/blogs/the-state-of-commute-2022/"
                    style="color: black; text-decoration: none"
                  >
                    <div class="card mr-3">
                      <img
                        src="/assets/images/commute.webp"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">The State of Commute<br> 2022</h5>
                        <p class="card-text">
                          Dhaka is one of the biggest megacities in the world with a
                          population of 20.2 million people and growing. Being the hub of
                          career and opportunities in the country, Dhaka ...
                        </p>
                        <a
                          href="https://shuttlebd.com/blogs/the-state-of-commute-2022/"
                          class="btn btn-primary"
                          >Read more</a
                        >
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-12 col-md-6 col-xl-3 mb-4">
                  <a
                    href="https://shuttlebd.com/blogs/develop-your-team-from-within/"
                    style="color: black; text-decoration: none"
                  >
                    <div class="card mr-3">
                      <img
                        src="/assets/images/employee.webp"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">6 tips to help your employees achieve</h5>
                        <p class="card-text">
                          Every business understands the importance of having a great team leading the execution of its strategic goals. The role of employees stretches farther than any patent... 
                        </p>
                        <a
                          href="https://shuttlebd.com/blogs/develop-your-team-from-within/"
                          class="btn btn-primary"
                          >Read more</a
                        >
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-12 col-md-6 col-xl-3 mb-4">
                  <a
                    href="https://shuttlebd.com/blogs/visiting-sairu-hill-resort-in-bandarban/"
                    style="color: black; text-decoration: none"
                  >
                    <div class="card mr-3">
                      <img
                        src="/assets/images/Sairu-hill-resort_blog.webp"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title">Visiting Sairu Hill Resort in Bandarban? </h5>
                        <p class="card-text">
                          The Sairu Hill Resort is located cozily among the clouds of Bandarban’s majestic hill tracks. Sairu Resort is known for its exquisite architecture, luxurious rooms..
                        </p>
                        <a
                          href="https://shuttlebd.com/blogs/visiting-sairu-hill-resort-in-bandarban/"
                          class="btn btn-primary"
                          >Read more</a
                        >
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="blog-button">
            <a href="https://shuttlebd.com/blogs" class="btn btn-primary"
              >See All Blogs</a
            >
          </div> -->
        </div>
      </div>
    </div>
  </section>
  <!-- blog section html part end here -->

  <!-- call to action section html start from here -->
  <section class="call_to_action">
    <div class="container">
      <div class="action_design">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="action_left">
              <div class="action-border"></div>
              <h2>Happy Travels!</h2>
              <p>
                Make the best of your travels with Shuttle rentals. Explore our flexible
                packages and wide range of vehicles to take you on your next adventure.
              </p>
              <a href="" data-bs-toggle="modal" data-bs-target="#exampleModal" id="banner_cta"
                >Rent A Car Today</a
              >
              <img src="/assets/images/shape.png" />
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="action-right">
              <img src="/assets/images/shuttlerental.webp" />
            </div>
            <div class="shape_design">
              <img src="/assets/images/shape.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- call to action section html end here -->
</template>
<style scoped>
.blog-button {
  margin-top: 30px;
  text-align: center;
}
.blog-section h2 {
  font-size: 32px;
  font-weight: 600;
}
.card-text{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.faq-border {
  width: 5.5rem;
  height: 7px;
  background: #a95eea;
  border-radius: 3px;
  margin-bottom: 20px !important;
  margin: auto;
}
.btn-primary {
  background-color: #6c3a97;
  border: 1px solid #6c3a97;
  font-size: 12px;
  margin-top: 20px;
}

/* call to action css start from here */
.call_to_action {
  margin: 50px 0;
}
.action_design {
  background-color: #6c3a97;
  border-radius: 10px;
  /* padding: 50px; */
}
.card-title {
  font-weight: 700;
  font-size: 18px;
}
.action-border {
  width: 5.5rem;
  height: 7px;
  background: #e6ff55;
  border-radius: 3px;
  margin-left: 50px;
  margin-top: 50px;
}
.action_left h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 50px;
}

.action_left p {
  color: #fff;
  padding: 15px 0px;
  padding-left: 50px;
  font-weight: 500;
  text-align: justify;
}
.action_left a {
  background-color: #fff;
  color: #6c3a97;
  padding: 12px 100px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  margin-left: 50px;
  position: absolute;
}
.action-right img {
  padding-top: 50px;
  padding-right: 50px;
  position: relative;
}
.shape_design img {
  max-width: 100%;
  margin-top: -97px;
}
/*  call to action css end here */

@media (min-width: 768px) and (max-width: 1024px) {
  /* call to action css start */
  .action_design {
    padding: 20px 15px 50px 15px;
  }
  .action-border {
    margin-left: 0px;
  }
  .action_left h2 {
    font-size: 32px;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 0px;
  }
  .action_left p {
    padding: 5px 0px;
  }
  .action_left a {
    text-align: center;
    display: block;
    margin-left: 0px;
  }
  .action_left img {
    display: none;
  }
  .action-right img {
    width: 100%;
    padding-top: 90px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  /* call to action css start */
  /* .action_design {
    padding: 50px 0px;
  } */
  .action_left h2 {
    font-size: 25px;
    font-weight: 600;
    padding-top: 15px;
    padding-left: 25px;
  }
  .action_left p {
    padding: 5px 25px;
  }
  .action_left a {
    text-align: center;
    display: block;
    /* margin-left: 25px; */
    margin-right: 25px;
    padding: 12px 50px;
    margin-left: 40px;
  }
  .action-border {
    margin-left: 25px;
  }
  .action_left img {
    display: none;
  }
  .action-right img {
    width: 100%;
    padding-top: 30px;
    padding: 20px 25px;
    margin-top: 65px;
  }
  .shape_design img {
    margin-top: -30px;
  }

  /*  blog section css strat */
  .blog-button a {
    padding: 12px 100px;
    display: block;
  }
}
</style>
