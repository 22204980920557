<template>
  <div class="container-fluid footer-feature">
    <div class="row text-start">
      <!-- <footer class="text-start text-start"> -->
      <div class="card shadow-lg border-0 col-md-12">
        <section class="card-body">
          <div class="container ps-0">
            <div class="row">
              <div class="col-md-3 col-lg-3 col-xl-3 col-12 mt-3 mx-lg-2">
                <h6 class="text-capitalize mb-4 font-weight-bold">
                  {{ $t("message.footer.company.title", {}, { locale: LoadLangShow }) }}
                </h6>
                <p>
                  <a target="_blank" href="https://api.shuttlebd.com/terms-&-conditions">{{
                    $t("message.footer.company.list.l1", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
                <p>
                  <a target="_blank" href="https://api.shuttlebd.com/privacy-policy">{{
                    $t("message.footer.company.list.l2", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
                <p>
                  <a target="_blank" href="http://join.shuttlebd.com/faq">{{
                    $t("message.footer.company.list.l3", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
                <p>
                  <a target="_blank" href="https://drive.google.com/drive/u/2/folders/1XuAI87MwyZySO9WF6Q4yLn6YXtQNyjnD">Resources</a>
                </p>
              </div>

              <div class="col-md-3 col-lg-3 col-xl-2 col-12 mt-3">
                <h6 class="text-capitalize mb-4 font-weight-bold">
                  {{ $t("message.footer.service.title", {}, { locale: LoadLangShow }) }}
                </h6>
                <p>
                  <a href="/">{{
                    $t("message.footer.service.list.l1", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
                <p>
                  <a href="/business">{{
                    $t("message.footer.service.list.l2", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
                <p>
                  <a href="/partner">{{
                    $t("message.footer.service.list.l3", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
                <p>
                  <a href="/rental">{{
                    $t("message.footer.service.list.l4", {}, { locale: LoadLangShow })
                  }}</a>
                </p>
              </div>

              <div class="col-md-3 col-lg-3 col-xl-2 col-12 mt-3 mx-auto">
                <h6 class="text-capitalize mb-4 font-weight-bold">
                  {{ $t("message.footer.contact.title", {}, { locale: LoadLangShow }) }}
                </h6>
                <p>
                  {{
                    $t("message.footer.contact.list.phone", {}, { locale: LoadLangShow })
                  }}
                </p>
                <p>
                  {{
                    $t("message.footer.contact.list.open", {}, { locale: LoadLangShow })
                  }}
                </p>
                <p>
                  {{
                    $t("message.footer.contact.list.email", {}, { locale: LoadLangShow })
                  }}
                  info@shuttlebd.com
                </p>
              </div>

              <div class="col-md-4 col-lg-3 col-xl-3 col-12 mt-3">
                <h6 class="text-capitalize font-weight-bold">
                  {{ $t("message.footer.follow.title", {}, { locale: LoadLangShow }) }}
                </h6>
                <div class="d-flex flex-row">
                  <p>
                    <a target="_blank" href="https://www.facebook.com/shuttlebd"
                      ><i class="bi bi-facebook m-1 footer-icon"></i
                    ></a>
                  </p>
                  <p>
                    <a target="_blank" href="https://www.instagram.com/shuttlebangladesh"
                      ><i class="bi bi-instagram m-1 footer-icon"></i
                    ></a>
                  </p>
                  <p>
                    <a target="_blank" href="https://twitter.com/shuttle_bd"
                      ><i class="bi bi-twitter m-1 footer-icon"></i
                    ></a>
                  </p>
                  <p>
                    <a target="_blank" href="https://www.linkedin.com/company/shuttlebd"
                      ><i class="bi bi-linkedin m-1 footer-icon"></i
                    ></a>
                  </p>
                </div>
                <div class="source-media">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.easytransport.shuttle"
                    ><img src="@/assets/playstore.webp" alt="" class="img-fluid mb-2" /></a
                  ><br />
                  <a
                    target="_blank"
                    href="https://apps.apple.com/app/shuttlebd/id1459741215"
                    ><img src="@/assets/appstore.webp" alt="" class="img-fluid"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- </footer> -->
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div
        class="row text-lg-center text-center d-flex flex-row overflow-hidden p-2 w-100 ms-0 text-white"
        style="background: #6c3a97"
      >
        <div class="col-md-12">
          © Copyright 2023. All rights reserved
          <span>
            <img
              src="@/assets/Logo-shuttle.png"
              class="img-fluid text-white"
              width="30"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    LoadLangShow() {
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
.source-media .img-fluid {
  width: 120px;
  height: 50px;
}
.text-start {
  color: #6c3a97;
}
.footer-feature p {
  font-size: 12px;
}

.footer-icon {
  font-size: 20px;
  letter-spacing: 3px;
  color: #6c3a97;
}
@media (max-width: 991px) {
  .row.text-lg-center.text-center.d-flex.flex-row.overflow-hidden.p-2.w-100.ms-0.text-white {
    text-align: left !important;
    font-size: 12px;
  }
}
</style>
