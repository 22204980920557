import { createStore } from 'vuex'

const store = createStore({
  state:{
    getSignUpTitle : localStorage.getItem('getSignUpTitle'),
    LoadLang : localStorage.getItem('LoadLang'),
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

export default store
