<template>
  <!--  -->
  <!-- <footer id="footer">
	<div class="container-fluid">
		<div class="row">
            <div class="col-md-4 col-lg-4 col-sm-12" id="shuttle-info">
              <ul class="privacy-terms">
                <p><a href="/partner/faq">FAQ</a></p>
                <p><a target="_blank" href="/partner/terms-conditions">Terms & Conditions</a></p>
                <p><a target="_blank" href="/partner/privacy-policy">Privacy & Policy</a></p>
              </ul>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12" id="shuttle-contact">
                <p>Contact us:</p>
                <p id="ftrNubmer">+8809638888868</p>
                <p>(Saturday - Thursday: 6:00AM - 9:00PM)</p>
                <p>Email: info@shuttlebd.com</p>
            </div>
            <div class="col-md-4 col-lg-4 mx-auto text-center" id="icon-social">
              <ul>
                <li><a target="_blank" href="https://www.facebook.com/shuttlebd"><i class="bi bi-facebook mr-4"></i></a></li>
                <li><a target="_blank" href="https://twitter.com/shuttle_bd"><i class="bi bi-twitter mr-4"></i></a></li>
                <li><a target="_blank" href="https://www.linkedin.com/company/shuttlebd"><i class="bi bi-linkedin mr-4"></i></a></li>
                <li><a target="_blank" href="https://www.instagram.com/shuttlebangladesh"><i class="bi bi-instagram"></i></a></li>
              </ul>
              <p>© 2021 Shuttle Technologies Limited</p>
            </div>
		</div>
	</div>
</footer> -->

  <footer>
    <div class="container-fluid" id="partnerFooterWork">
      <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-12" id="shuttle-info">
          <ul class="privacy-terms">
            <p><a href="/partner/faq">FAQ</a></p>
            <p>
              <a target="_blank" href="/partner/terms-conditions"
                >Terms &amp; Conditions</a
              >
            </p>
            <p>
              <a target="_blank" href="/partner/privacy-policy">Privacy &amp; Policy</a>
            </p>
            <p>
              <a target="_blank" href="https://drive.google.com/drive/u/2/folders/1XuAI87MwyZySO9WF6Q4yLn6YXtQNyjnD">Resources</a>
            </p>
          </ul>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-12" id="shuttle-contact">
          <p>Contact us:</p>
          <p id="ftrNubmer">+8801880199801</p>
          <p>(Saturday - Thursday: 6:00AM - 9:00PM)</p>
          <p>Email: info@shuttlebd.com</p>
        </div>
        <div class="col-md-4 col-lg-4 mx-auto text-center icon-social" id="icon-social">
          <ul>
            <li>
              <a target="_blank" href="https://www.facebook.com/shuttlebd"
                ><i class="bi bi-facebook mr-4"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/shuttle_bd"
                ><i class="bi bi-twitter mr-4"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="https://www.linkedin.com/company/shuttlebd"
                ><i class="bi bi-linkedin mr-4"></i
              ></a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/shuttlebangladesh"
                ><i class="bi bi-instagram"></i
              ></a>
            </li>
          </ul>
          <p class="icon-social-copyright">
            &copy; {{ new Date().getFullYear() }} Shuttle Technologies Limited
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
p.icon-social-copyright {
  text-align: left;
  padding: 15% 0px 0 4% !important;
}
#partnerFooterWork .icon-social ul {
  display: flex !important;
  list-style-type: none !important;
  justify-content: left !important;
  padding-inline-start: 0 !important;
}
#partnerFooterWork .icon-social li a {
  text-decoration: none;
  color: #fff;
}
#partnerFooterWork .icon-social li a i {
  font-size: 25px;
}
#partnerFooterWork .icon-social ul li {
  list-style-type: none;
  padding-inline: 4%;
}
div#partnerFooterWorkdiv.icon-social ul li {
  list-style-type: none;
  padding: 1%;
}
#partnerFooterWork footer a {
  text-decoration: none;
  /* text-transform: capitalize; */
  color: #fff;
}
#partnerFooterWork footer a:hover {
  text-decoration: none;
}
.privacy-terms a {
  color: #fff !important;
  text-decoration: none;
}

@media screen and (min-width: 571px) and (max-width: 767px) {
  #topbar {
    display: none;
  }
  #partnerFooterWork #app nav ul li {
    padding-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  #app nav ul li a {
    font-weight: bold;
  }
  #partnerFooterWork footer {
    text-align: center;
  }
  #partnerFooterWork ul.privacy-terms {
    position: relative;
  }
  #partnerFooterWork #shuttle-info ul {
    padding-inline-start: 0;
  }
  #partnerFooterWork .icon-social ul {
    justify-content: center !important;
  }
  #partnerFooterWork .icon-social p {
    text-align: center !important;
  }
  #partnerFooterWork .icon-social p {
    padding-top: 0 !important;
    position: relative !important;
  }
  #partnerFooterWork #shuttle-support-content .col-md-2 {
    margin: 0% 0 10% 0 !important;
  }
  #partnerFooterWork #shuttle-support h1 {
    padding: 0 0 70px 0 !important;
  }
  #partnerFooterWork #shuttle-support {
    padding: 70px 0 !important;
  }
}
@media screen and (max-width: 570px) {
  #topbar {
    display: none;
  }
  #partnerFooterWork #app nav ul li {
    padding-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  #app nav ul li a {
    font-weight: bold;
  }
  #partnerFooterWork footer {
    text-align: center !important;
  }
  #partnerFooterWork ul.privacy-terms {
    position: relative !important;
  }
  #partnerFooterWork .col-md-4 .col-lg-4 {
    width: 100% !important;
  }
  #partnerFooterWork #social-platform ul li {
    padding: 2% !important;
  }
  #partnerFooterWork #social-platform ul li a i {
    font-size: 25px !important;
    padding: 0 !important;
  }
  #partnerFooterWork #social-platform p {
    padding-left: 0;
    padding-top: 20px !important;
  }
  #partnerFooterWork #shuttle-info ul {
    /* margin-left: -15%; */
    padding-inline-start: 0 !important;
  }
  #partnerFooterWork #shuttle-support {
    padding: 50px !important;
    text-align: center !important;
    background-color: #fff !important;
  }
  #partnerFooterWork #shuttle-support h1 {
    padding: 0 8% !important;
    font-size: 18px !important;
    font-weight: bold !important;
    letter-spacing: 2px !important;
  }
  #partnerFooterWork #shuttle-support-content {
    padding: 30px !important;
  }
  #partnerFooterWork #shuttle-support-content .col-md-2 {
    margin: 11% 0 11% 0 !important;
  }
  #partnerFooterWork #shuttle-support img.robi,
  img.undp {
    width: 95% !important;
  }
  #partnerFooterWork #shuttle-support img.ac {
    width: 95% !important;
  }
  #partnerFooterWork #shuttle-support img.citi {
    width: 95% !important;
  }
  #partnerFooterWork .icon-social ul {
    justify-content: center !important;
  }
  #partnerFooterWork .icon-social p {
    text-align: center !important;
    padding-top: 0 !important;
    position: Relative !important;
  }
}
</style>

<style scoped>
/* @import url('../../public/assets/css/partner/index.css');
@import url('../../public/assets/css/business/default.css'); */
a:hover {
  color: white;
}
</style>
