import { createApp } from 'vue';
import store from './store'

import BaseCard from './components/baseCard/BaseCard.vue';
import App from './App.vue';
import 'vue3-carousel/dist/carousel.css';

import router from './router/index.js';
import i18n from './plugins/i18n';

const app = createApp(App).use(store);

app.use(i18n);
app.use(router);

app.component('base-card', BaseCard);
app.mount('#app');
