import { createI18n } from "vue-i18n";
import en from "../locales/en.json"
import de from "../locales/de.json"
import bn from "../locales/bn.json"

// const messages = {
//     'en': {
//         welcomeMsg: 'Welcome to Your Vue.js App',
//         guide: 'For a guide and recipes on how to configure / customize this project,',
//         checkout: 'check out the',
//         plugins: 'Installed CLI Plugins',
//         links: 'Essential Links',
//         ecosystem: 'Ecosystem'
//     },
//     'es': {
//         welcomeMsg: 'Bienvenido a tu aplicación Vue.js',
//         guide: 'Para una guía y recetas sobre cómo configurar / personalizar este proyecto,',
//         checkout: 'revisar la',
//         plugins: 'Plugins de CLI instalados',
//         links: 'Enlaces esenciales',
//         ecosystem: 'Ecosistema'
//     }
// };

// const i18n = new VueI18n({
//     locale: 'en', // set locale
//     fallbackLocale: 'es', // set fallback locale
//     messages, // set locale messages
// });

// export default i18n;



const i18n = createI18n({
    legacy: false,
    locale: "ja",
    globalInjection: true,
    messages: {
      en: {
        message:  en
      },
      ar: {
        message: {
          language: "العربية",
          greeting: "السلام عليكم",
          title: "Arabic Title !",
        }
      },
      es: {
        message: {
          language: "Español",
          greeting: "Hola !",
          title: "Esp Title !",
        }
      },
      de:{
        message: de
      },
      bn:{
        message: bn
      }
    }
  });

  export default i18n;
