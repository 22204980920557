<template>
  <div class="container px-0">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="@/assets/logo/shuttle.svg" alt="" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse pt-2" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link ps-0">{{  $t("message.navbar.home", {}, { locale: LoadLangShow }) }}</router-link>
            </li>
            <li class="nav-item ps-0">
              <router-link to="/business" class="nav-link ps-0"
                >{{  $t("message.navbar.business", {}, { locale: LoadLangShow }) }}</router-link
              >
            </li>
            <li class="nav-item ps-0">
              <router-link to="/partner" class="nav-link ps-0"
                >{{  $t("message.navbar.partner", {}, { locale: LoadLangShow }) }}</router-link
              >
            </li>
            <li class="nav-item ps-0">
              <!-- <a class="nav-link" href="http://rental.shuttlebd.com/">{{  $t("message.navbar.rental", {}, { locale: LoadLangShow }) }}</a> -->
              <a class="nav-link" href="/rental">{{  $t("message.navbar.rental", {}, { locale: LoadLangShow }) }}</a>
            </li>
            <li class="nav-item ps-0">
              <router-link to="/shuttle-for-school" class="nav-link ps-0"
                >{{  $t("message.navbar.sfs", {}, { locale: LoadLangShow }) }}</router-link
              >
            </li>
            <li class="nav-item ps-0">
              <a class="nav-link" href="https://www.shuttlebd.com/blogs">{{  $t("message.navbar.blog", {}, { locale: LoadLangShow }) }}</a>
            </li>
          </ul>

          <form class="d-flex">
            <select v-model="lang" class="form-select border-0 shadow-none" @change="emitToLoadLang($event)">
              <option value="en">English</option>
              <option value="bn">বাংলা</option>
            </select>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'BusinessHeader',
  data() {
    return {
      lang: (this.$store.state.LoadLang) ? (this.$store.state.LoadLang) : "en",
    };
  },
  computed:{
    LoadLangShow(){
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  methods: {
      emitToLoadLang(){
        localStorage.setItem("LoadLang",this.lang);
        window.location.reload();
        return;
      }
  }
};
</script>

<style scoped>
.navbar-brand {
  padding-right: 10rem;
}
.navbar-brand img {
  width: 65px;
}
.navbar-nav > li {
  padding-left: 0px;
  padding-right: 25px;
  position: relative;
}
.navbar-nav li {
  position: relative;
}

.navbar-nav li a {
  color: #111632 !important;
}

.active {
  border-bottom: 3px solid #150b0b;
  border-radius: 2.3px;
}

@media (max-width: 991px) {
  .navbar-brand {
    padding-right: 0px;
  }
  .navbar-nav > li {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-nav > li > a:before {
    left: 0px;
  }
}
</style>
