<template>
  <div
    class="
      col-md-12 col-lg-6 col-sm-12 col-12
      pb-lg-5 pb-0
      justify-content-between
      text-lg-start text-center
    "
  >
    <span class="position-relative">
      <div class="position-absolute top-0 start-0">
        <!-- <img
          src="@/assets/Vector.png"
          alt="Google Logo"
          class="ps-lg-1 ms-5 mx-4 img-fluid d-lg-flex d-md-none d-sm-none d-none"
        /> -->
      </div>
      <img
        :src="require(`@/assets/Credit/${currentImage}.png`)"
        class="img-fluid w-75"
      />
      <div class="position-absolute top-50 start-0 translate-middle">
        <!-- <img src="@/assets/dropoff.png" class="img-fluid ms-5 w-75" /> -->
      </div>
    </span>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 col-12">
    <h4 class="mb-5 p-0 text-start">{{  $t("message.buyCredit.headline", {}, { locale: LoadLangShow }) }}</h4>
    <div class="row">
      <div class="ul p-1">
        <li
          class="mb-1 d-flex active shadow buy_credit"
          data-image="Tap_on_the_wallet"
        >
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">{{  $t("message.buyCredit.body.list1", {}, { locale: LoadLangShow }) }}</p>
        </li>
        <li
          class="mb-1 d-flex buy_credit"
          data-image="Slide_to_select_recharge_amount"
        >
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">{{  $t("message.buyCredit.body.list2", {}, { locale: LoadLangShow }) }}</p>
        </li>
        <li
          class="mb-1 d-flex buy_credit"
          data-image="Tap_on_Proceed_to_payment"
        >
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">{{  $t("message.buyCredit.body.list3", {}, { locale: LoadLangShow }) }}</p>
        </li>
        <li
          class="mb-1 d-flex buy_credit"
          data-image="Tap_confirm_and_your_credit"
        >
          <span class="fw-bold"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-dot"
              viewBox="0 0 16 16"
            >
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" /></svg></span>
          <p class="text-start px-2 pt-0">
            {{  $t("message.buyCredit.body.list4", {}, { locale: LoadLangShow }) }}
          </p>
        </li>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BuyCredit',
  data() {
    return {
      intervalTime: 3000,
      currentImage: 'Tap_on_the_wallet',
      currentImageIndex: 0,
      images: [
        'Tap_on_the_wallet',
        'Slide_to_select_recharge_amount',
        'Tap_on_Proceed_to_payment',
        'Tap_confirm_and_your_credit'
      ],
      interval: null
    };
  },
  computed:{
    LoadLangShow(){
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  methods: {
    doSetTimeout(item) {
      let lists = document.querySelectorAll('.buy_credit');
      lists.forEach((list) => {
        list.classList.remove('active');
        list.classList.remove('shadow');
      });
      // get the current list item value from data attribute
      let currentImage = item.getAttribute('data-image');
      // set the current image
      this.currentImage = currentImage;
      item.classList.add('active');
      item.classList.add('shadow');
    }
  },
  activated() {
    // let lists = document.querySelectorAll('.buy_credit');
    this.interval = setInterval(() => {
      this.currentImageIndex =
        this.currentImageIndex + 1 >= this.images.length
          ? 0
          : this.currentImageIndex + 1;
      this.doSetTimeout(
        document.querySelector(
          `.buy_credit[data-image="${this.images[this.currentImageIndex]}"]`
        )
      );
    }, this.intervalTime);
  },
  deactivated() {
    clearInterval(this.interval);
  },
  mounted() {
    this.currentImage = this.images[this.currentImageIndex];
  },
  unmounted() {
    this.currentImageIndex = 0;
    this.currentImage = this.images[this.currentImageIndex];
    clearInterval(this.interval);
  }
};
</script>
<style scoped>
.buy_credit {
  height: fit-content;
}
.buy_credit.active {
  height: fit-content;
  background: #a95eea;
  color: #ffffff;
  border-radius: 10px;
}
.buy_credit span {
  color: #6c3a97;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: -10px;
}
.buy_credit.active span svg {
  display: none;
}
.buy_credit.active p {
  color: #ffffff;
  margin-bottom: 0px !important;
  padding: 10px 15px !important;
}
@media only screen and (max-width: 991px) {
  .buy_credit.active {
    /* border: none; */
    transform: scaleZ(0.8) translate(-15px);
    transition: 0.5s ease-in-out;
    border-radius: 10px;
  }
}
</style>
