<template>
  <div>
    <section id="loading">
      <div id="loading-content"></div>
    </section>
    <section class="partner-header-section">
      <div class="container-fluid">
        <Slider></Slider>
        <div class="row">
          <div class="partner-header-content">
              <navbar></navbar>
            <!-- <Banner></Banner> -->
          </div>
        </div>
      </div>
    </section>
    <router-view></router-view>
    <Reason></Reason>
    <Safety></Safety>
    <Support></Support>
    <PartnerFooter></PartnerFooter>
  </div>
</template>
<script>
import Reason from "../components/partner/Reason.vue";
import Safety from "../components/partner/Safety.vue";
import Support from "../components/partner/partial/SupportedBy.vue";
import Slider from "../components/partner/partial/Slider.vue";
import navbar from "../components/partner/include/Header.vue";
import PartnerFooter from "../components/TheFooterPartner.vue"
// import Banner from "../components/partner/partial/Banner.vue";
export default {
  components: { 
    Reason, 
    Safety, 
    Support, 
    Slider, 
    navbar, 
    // Banner,
    PartnerFooter
  },
};
</script>

<style>
@media screen and (max-width: 570px) {
	.arrow{display: none;}
	#partner-header-work img {
        width: 65px !important;
    }
}
#partner-header-work .navbar-nav .nav-link:hover,
#partner-header-work .navbar-nav .nav-link:focus,
#partner-header-work .navbar-nav .nav-link.active {
    color: #fff !important;
    text-shadow: 0 0 1px #fff;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff;
    background: transparent;
}
@media screen and (max-width: 570px) {
	#topbar{ display: none;
	}
	#partner-header-work .navbar-toggler i{
		color: #fff;
		font-size: 28px;
	}
	#partner-header-work .navbar-collapse { flex-basis: 100%; background: rgba(0, 0, 0, 0.7); text-align: center; margin: 0 -7.3% -2.6% -8.3%; flex-grow: 1;
    }
    #partner-header-work #app nav ul li { padding-right: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
}

/* footer */

@media screen and (min-width: 571px) and (max-width: 767px){
	#topbar{ display: none;
	}
  #partner-header-work #app nav ul li { padding-right: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
	#app nav ul li a { font-weight: bold;
	}
	#partnerFooterWork footer {
		text-align: center;
	}
	#partnerFooterWork ul.privacy-terms{
		position: relative;
	}
	#partnerFooterWork #shuttle-info ul {
		padding-inline-start: 0;
	}
	#partnerFooterWork #icon-social ul {justify-content: center;}
	#partnerFooterWork #icon-social p { text-align: center;}
	#partnerFooterWork #icon-social p {
		padding-top: 0;
		position: relative;
	}
	#partnerFooterWork #shuttle-support-content .col-md-2 {
		margin: 0% 0 10% 0;
	}
	#partnerFooterWork #shuttle-support h1 {padding:0 0 70px 0;}
	#partnerFooterWork #shuttle-support { padding: 70px 0;}
}
@media screen and (max-width: 570px) {
	#topbar{ display: none;
	}
  #partnerFooterWork #app nav ul li { padding-right: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
	#app nav ul li a { font-weight: bold;
	}
    #partnerFooterWork footer{ text-align: center;
	}
	#partnerFooterWork ul.privacy-terms{
		position: relative;
	}
    #partnerFooterWork .col-md-4 .col-lg-4{ width: 100%;
    }
	#partnerFooterWork #social-platform ul li{
		padding: 2%;
	}
	#partnerFooterWork #social-platform ul li a i{
		font-size: 25px;
		padding: 0 !important;
	}
	#partnerFooterWork #social-platform p{ padding-left: 0;
		padding-top: 20px;
	}
	#partnerFooterWork #shuttle-info ul{
		/* margin-left: -15%; */
		padding-inline-start: 0;
	}
	#partnerFooterWork #shuttle-support{
		padding: 50px;
		text-align: center;
		background-color: #fff;
		}
        #partnerFooterWork #shuttle-support h1{
		padding: 0 8%;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 2px;
	
		}
		#partnerFooterWork #shuttle-support-content{
			padding: 30px;
		}
		#partnerFooterWork #shuttle-support-content .col-md-2{
			margin: 11% 0 11% 0;
		}
		#partnerFooterWork #shuttle-support img.robi, img.undp {
			width: 95%;
		}
		#partnerFooterWork #shuttle-support img.ac {
			width: 95%;
		}
		#partnerFooterWork #shuttle-support img.citi {
			width: 95%;
		}
		#partnerFooterWork #icon-social ul{
			justify-content: center;
		}
		#partnerFooterWork #icon-social p { text-align: center; padding-top: 0; position:Relative;}
}
</style>

<style scoped>
/* @import url('../../public/assets/css/partner/index.css'); */
/* @import url('../../public/assets/css/business/default.css'); */
  /* eslint-disable */
#app{opacity: 1;}
input:focus, select:focus{
    border: 1px solid lightgray !important;
    box-shadow: none !important;
}
  /* .header .title{font-size: 20px;font-family: 'Roboto', sans-serif;} */
  .header .help{font-size: 14px; text-align: right;}
  .header .help a{ text-decoration: none; color: #333;}
  .btn-sm{font-size: 16px; text-transform: uppercase;color: #fff;background-color: #262C66;padding: 1px 20px;}
  .btn-sm:hover{color: #fff;}
  .form-control{background-color: #E5E5E5; height: calc(1.5em + 1.0rem + -4px) !important;}

  /* index css */
#partner-header-work .navbar-nav .nav-link:hover,
#partner-header-work .navbar-nav .nav-link:focus{
    color: #fff !important;
    text-shadow: 0 0 1px #fff;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff;
    background: transparent;
}
#partner-header-work .navbar-nav .nav-link.active {
    border-radius: 5px;
}
section.partner-header-section .partner-header-title{
	padding: 21% 0 0% 4%; 
}
section.partner-header-section{
    position: relative;
    width: 100%;
}
.partner-header-content{
    position: absolute;
    top: 0%;
	width: 100%;
	padding-right: 0 !important;
    padding-left: 0 !important;
}
section.partner-header-section .partner-header-content .login{
	position: absolute;
	top: 6%;
	right: 3%;
}
.login a{
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    background-color: #262C66;
    padding: 1px 20px;
}
.login a:hover{
    color: #fff;
}
section.partner-header-section .partner-header-title h4{
	color: #fff;
	font-weight: bold;
}
section.partner-header-section .partner-header-title a{
    font-size: 16px;
    padding: 0.5% 1% 0.5% 1%;
    margin: 1% 0 0 0;
    text-transform: uppercase;
    color: #fff;
    background-color: #262C66;
}
/* section.partner-header-section .partner-header-title a:hover, 
section.partner-header-section .partner-header-title a:focus,
section.partner-header-section .partner-header-title a:active{
	background-color: #262C66 !important;
} */
#topbar{
	background-color:  #262C66;
}

@media screen and (max-width: 570px) {
	.arrow{display: none;}
	#partner-header-work img {
        width: 65px !important;
    }
	.mt-2{
		margin-top: -8% !important;
	}
	section.partner-header-section .partner-header-title{
		padding: 160px 0 0 10px; 
	}
	section.partner-header-section .partner-header-title a{
		font-size: 12px;
	}
	section.partner-header-section .partner-header-title h4{
		font-size: 15px;
	}
	.login a {
		font-size: 12px;
		padding: 2px 15px;
	}
}	
@media screen and (min-width: 571px) and (max-width: 767px){
	.arrow{display: none;}
	section.partner-header-section img {
        width: 25%;
	}
	section.partner-header-section .partner-header-title{
		padding: 300px 0 0 10px; 
	}
	section.partner-header-section .partner-header-title a{
		font-size: 12px;
	}
	section.partner-header-section .partner-header-title h4{
		font-size: 15px;
	}
	.login a {
		font-size: 12px;
		padding: 2px 15px;
	}
	
}
@media screen and (min-width: 768px) and (max-width: 1366px){
	section.partner-header-section .partner-header-title{
		padding: 300px 0 0 15px; 
	}
}
@media screen and (min-width: 1024px) and (max-width: 1366px){
	section.partner-header-section .partner-header-title{
		padding: 300px 0 0 15px; 
	}
}

/* default css */
/* @import url(//db.onlinewebfonts.com/c/0b51833ff62e4af8acb5fd3e2bf59e97?family=SF+Pro+Display); */
body{
	font-family: 'Roboto', sans-serif;
}
img { max-width: 100%; }
#topbar{
	background-color: #4882A5;
	height: 28px;
}
#topbar p{
	padding-top: 6px;
	padding-right: 74px;
	color: #fff;
	font-size: 12px;
	letter-spacing: 2px;
}
#app nav{
	/* linear-gradient(90deg, rgb(255 252 252 / 58%) 0%, rgb(0 0 0) 100%, rgba(0,212,255,1) 100%); */
	background: linear-gradient(90deg, rgb(255 255 255 / 88%) 0%, rgb(0 0 0 / 78%) 100%, rgba(0,212,255,1) 100%);
}
#app nav ul li{padding-right: 50px;}
#app nav ul li a{
	/* font-family: SF Pro Display; */
	text-transform: uppercase;
    color: #ececec !important;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: normal;
}
#icon-social ul{
	display: flex; list-style-type: none;justify-content: left;padding-inline-start: 0 !important;
}

#partner-header-work .navbar-nav .nav-link:hover,
#partner-header-work .navbar-nav .nav-link:focus,
#partner-header-work .navbar-nav .nav-link.active {
    color: #fff !important;
    text-shadow: 0 0 1px #fff;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff;
    background: transparent;
   }
/* Header css end here*/

 /*footer css*/
footer{
	padding: 40px;
    background: rgba(0, 0, 0, 0.1);
	font-size: 14px;
	color: #fff;
	background-color: #242D66;
	/*font-family: arial;*/
}
#shuttle-info{ position: relative;}
#partnerFooterWork ul.privacy-terms{
	position: absolute;
	bottom: 0;
	padding-inline-start: 0;
	width: 100%;
	margin-block-end: 0;
}
#partnerFooterWork footer ul li{
	list-style-type: none !important;
	padding-inline: 5% !important;
}
#partnerFooterWork footer a{
	text-decoration: none;
	/* text-transform: capitalize; */
	color: #fff;
}
#partnerFooterWork footer a:hover{
	text-decoration: none;
}
#shuttle-contact p{line-height: 15px; }
#social-platform ul{
	display: flex;
}
#social-platform ul li a i{
	font-size: 25px;
}
#icon-social li a i {
    font-size: 25px;
}
/* #social-platform p{
	padding-left: 40px;
	padding-top: 45px;
} */
#icon-social p {
	text-align: left;
    padding-top: 10.5%;
	/* position: absolute; */
	bottom: 0;
}
/*shuttle support css*/
#shuttle-support{
	padding-bottom: 100px;
	text-align: center;
	background-color: #fff;
}
#shuttle-support h1{
	padding: 70px;
	font-size: 25px;
	font-weight: bold;
	letter-spacing: 2px;
}
#shuttle-support img.robi, img.undp{
	width: 100px;
}
#shuttle-support img.citi{width: 166px;margin-right: 19px;}
#shuttle-support img.ac{width: 162px;}
#shuttle-support img.briddhi{width: 162px;}
/* #shuttle-support .ac{
	padding-top: 1.5rem;
} */

/*css for mobile responsive*/
@media screen and (max-width: 570px) {
	#topbar{ display: none;
	}
	#partner-header-work .navbar-toggler i{
		color: #fff;
		font-size: 28px;
	}
	#partner-header-work .navbar-collapse { flex-basis: 100%; background: rgba(0, 0, 0, 0.7); text-align: center; margin: 0 -7.3% -2.6% -8.3%; flex-grow: 1;
    }
    #partner-header-work #app nav ul li { padding-right: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
	#app nav ul li a { font-weight: bold;
	}
	#header-section .header-title h1{
		font-size: 25px;
	}
	#header-section .header-title{
		text-align: center;
	}
	#header-section .header-title a{
		font-size: 18px;
		border-radius: 5px;
	}
	#header-section .header-contact p{
		font-size: 13.5px;
		letter-spacing: 0;
    }
    footer{ text-align: center;
	}
	#partnerFooterWork ul.privacy-terms{
		position: relative;
	}
    .col-md-4 .col-lg-4{ width: 100%;
    }
	#social-platform ul li{
		padding: 2%;
	}
	#social-platform ul li a i{
		font-size: 25px;
		padding: 0 !important;
	}
	#social-platform p{ padding-left: 0;
		padding-top: 20px;
	}
	#partnerFooterWork #shuttle-info ul{
		/* margin-left: -15%; */
		padding-inline-start: 0;
	}
	#shuttle-support{
		padding: 50px;
		text-align: center;
		background-color: #fff;
		}
		#shuttle-support h1{
		padding: 0 8%;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 2px;
	
		}
		#shuttle-support-content{
			padding: 30px;
		}
		#shuttle-support-content .col-md-2{
			margin: 11% 0 11% 0;
		}
		#shuttle-support img.robi, img.undp {
			width: 95%;
		}
		#shuttle-support img.ac {
			width: 95%;
		}
		#shuttle-support img.citi {
			width: 95%;
		}
		#icon-social ul{
			justify-content: center;
		}
		#icon-social p { text-align: center; padding-top: 0; position:Relative;}
}
@media screen and (min-width: 571px) and (max-width: 767px){
	#partner-header-work .navbar-toggler i{
		color: #fff;
		font-size: 28px;
	}
	#topbar{ display: none;
	}
	#partner-header-work .navbar-collapse { flex-basis: 100%; background: rgba(0, 0, 0, 0.7);text-align: center; margin: 0 -4.5% -2.6% -4.5%; flex-grow: 1;
  }
  #partner-header-work #app nav ul li { padding-right: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
	#app nav ul li a { font-weight: bold;
	}
	footer {
		text-align: center;
	}
	#partnerFooterWork ul.privacy-terms{
		position: relative;
	}
	#shuttle-info ul {
		padding-inline-start: 0;
	}
	#partnerFooterWork #icon-social ul {justify-content: center;}
	#partnerFooterWork #icon-social p { text-align: center;}
	#partnerFooterWork #icon-social p {
		padding-top: 0;
		position: relative;
	}
	#partnerFooterWork #shuttle-support-content .col-md-2 {
		margin: 0% 0 10% 0;
	}
	#shuttle-support h1 {padding:0 0 70px 0;}
	#shuttle-support { padding: 70px 0;}
}
@media screen and (min-width: 768px) and (max-width: 1024px){ 
	
}
/* eslint-enable */
</style>