<template>
  <header>
    <the-header class="px-0"></the-header>
  </header>
  <!-- <section> -->
  <div class="container mb-lg-5 py-lg-5">
    <!-- Back to top signup --> 
    <a
      id="button"
      href="#"
      class="btn-block text-capitalize"
      data-bs-toggle="modal"
      v-show="activeTab != 'DriveTab'"
      data-bs-target="#b2cModal"
      @click="direction = 'b2c'"
      >{{ $t("message.tab.daily.button", {}, { locale: LoadLangShow }) }}</a
    >

    <div class="row row-cols-1 row-cols-md-1 row-cols-sm-1">
      <div class="col-lg-6 col-md-12 col-sm-12 col">
        <button
          class="btn header-buttons shadow-none"
          :class="{ active: activeTab === 'RideTab' }"
          @click="(activeTab = 'RideTab'), (direction = 'b2c')"
        >
          {{ $t("message.tab.daily.title", {}, { locale: LoadLangShow }) }}
        </button>
        <button
          class="btn header-buttons shadow-none"
          :class="{ active: activeTab === 'BusinessTab' }"
          @click="(activeTab = 'BusinessTab'), (direction = 'b2b')"
        >
          {{ $t("message.tab.business.title", {}, { locale: LoadLangShow }) }}
        </button>
        <button
          class="btn header-buttons shadow-none"
          :class="{ active: activeTab === 'DriveTab' }"
          @click="activeTab = 'DriveTab'"
        >
          {{ $t("message.tab.driver.title", {}, { locale: LoadLangShow }) }}
        </button>
      </div>
    </div>
    <!-- <div class="row justify-content-xl-center"> -->
    <div class="row justify-content-between">
      <keep-alive>
        <component :is="activeTab" />
      </keep-alive>
    </div>
    <div class="row text-lg-center d-none">
      <div class="col"></div>
      <div class="col"></div>
      <div class="col d-flex">
        <div
          class="header-dots"
          :class="{ active: activeTab === 'RideTab' }"
          @click="activeTab = 'RideTab'"
        ></div>
        <div
          class="header-dots"
          :class="{ active: activeTab === 'BusinessTab' }"
          @click="activeTab = 'BusinessTab'"
        ></div>
        <div
          class="header-dots"
          :class="{ active: activeTab === 'DriveTab' }"
          @click="activeTab = 'DriveTab'"
        ></div>
      </div>
    </div>
  </div>
  <!-- </section> -->
  <!-- </div> -->

  <!-- <main> -->
  <div class="container my-5">
    <!--Daily COMMUTE Section START-->
    <div class="row d-flex flex-lg-column">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
        <div class="daily-commute-line"></div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <h1 class="h3 daily-commute-text mb-5">
          {{ $t("message.commute.title", {}, { locale: LoadLangShow }) }}
        </h1>

        <div class="row d-flex justify-content-lg-between">
          <div class="col-lg-3 col-md-12 col-sm-12 pe-5 me-0">
            <p>
              {{ $t("message.commute.body", {}, { locale: LoadLangShow }) }}
            </p>
          </div>
          <div class="col-lg-9 col-md-12 col-sm-12 col-12">
            <div class="row d-flex justify-content-start">
              <div class="col">
                <Carousel
                  v-if="LoadLangShow == 'bn'"
                  id="b2c-carousel-daily"
                  :wrap-around="true"
                  :settings="settings"
                  :breakpoints="breakpoints"
                  :autoplay="5000"
                >
                  <Slide
                    v-for="slide in slidesBangla"
                    :key="slide.ID"
                    @mouseover="SlideMouseOverHandler()"
                    :data-slide-index="slide.ID"
                  >
                    <base-card :style="{ background: slide.bg }" :id="slide.ID">
                      <template v-slot:icon>
                        <img
                          :src="require(`@/assets/slides/slide${slide.image}.png`)"
                          class="card-img-top img-fluid my-2 w-25 border rounded-circle"
                          :alt="slide.title"
                        />
                      </template>
                      <template v-slot:title>
                        <h6 class="slide-title">{{ slide.title }}</h6>
                      </template>
                      <template v-slot:content>
                        <p class="slide-text">
                          {{ slide.text }}
                        </p>
                      </template>
                    </base-card>
                  </Slide>
                </Carousel>
                <Carousel
                  v-else
                  id="b2c-carousel-daily"
                  :wrap-around="true"
                  :settings="settings"
                  :breakpoints="breakpoints"
                  :autoplay="5000"
                >
                  <Slide
                    v-for="slide in slides"
                    :key="slide.ID"
                    @mouseover="SlideMouseOverHandler()"
                    :data-slide-index="slide.ID"
                  >
                    <base-card :style="{ background: slide.bg }" :id="slide.ID">
                      <template v-slot:icon>
                        <img
                          :src="require(`@/assets/slides/slide${slide.image}.webp`)"
                          class="card-img-top img-fluid my-2 w-25 border rounded-circle"
                          :alt="slide.title"
                        />
                      </template>
                      <template v-slot:title>
                        <h6 class="slide-title">{{ slide.title }}</h6>
                      </template>
                      <template v-slot:content>
                        <p class="slide-text">
                          {{ slide.text }}
                        </p>
                      </template>
                    </base-card>
                  </Slide>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Daily COMMUTE Section END-->
  <!--BUSINESS Section END-->
  <div class="container my-5">
    <div class="row my-5">
      <div class="col-lg-12 col-md-12 col-sm-12 position-relative">
        <span class="d-lg-flex d-md-none d-none d-flex mt-2">
          <div class="position-absolute top-0 start-0">
            <img
              src="@/assets/rectangle.png"
              alt=""
              class="img-fluid mb-5 w-75"
              width="100"
              height="100"
            />
          </div>
        </span>
        <div class="card h-100 position-relative..">
          <div class="card-body implement-back-building">
            <div class="row transportation-team">
              <div class="col-lg-6 col-md-12 col-sm-12 tranportation-content">
                <img
                  src="@/assets/movingbusinessforward.webp"
                  class="img-fluid w-75 rounded"
                  alt=""
                  width="500"
                />
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row d-flex flex-column moving-business-style">
                  <div class="col">
                    <div class="d-flex flex-row-reverse bd-highlight">
                      <div class="p-0 bd-highlight">
                        <h1 class="h3 fw-bold" style="color: #a95eea">
                          {{
                            $t(
                              "message.movingBusiness.title",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div class="col mb-3">
                    <div class="d-flex flex-row-reverse bd-highlight">
                      <div class="bd-highlight">
                        <p style="color: #a95eea">
                          {{
                            $t(
                              "message.movingBusiness.body",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col mb-2 mt-0">
                    <div class="d-flex flex-lg-row-reverse bd-highlight">
                      <div class="bd-highlight w-50">
                        <button
                          class="btn btn-primary w-100 p-1"
                          style="background: #6c3a97"
                          data-bs-toggle="modal"
                          data-bs-target="#b2cModal"
                          type="button"
                          @click="direction = 'b2b'"
                        >
                          {{
                            $t(
                              "message.movingBusiness.button",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="position-absolute bottom-0 start-0 d-lg-flex d-md-none d-none">
            <!-- <img
              src="../assets/move-bus-forward-bg.png"
              height="120"
              class="img-fluid building-img"
              style="opacity: 0.2"
            /> -->
          </div>
          <div class="position-absolute bottom-0 end-0 d-lg-flex d-flex mt-4">
            <img
              src="../assets/move-bus-forward-bg.png"
              height="120"
              class="building-img"
              style="opacity: 0.2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--BUSINESS Section END-->
  <!--REVIEW Section START-->
  <div class="container my-5 book-seat">
    <div class="row text-lg-center text-sm-start py-5">
      <div class="col-12 col-md-12 col-lg-12 my-5">
        <h1 class="h3">
          {{ $t("message.reviews.headline", {}, { locale: LoadLangShow }) }}
        </h1>
      </div>
      <div
        class="col-12 col-md-12 col-lg-12 px-lg-3 review-section-bg"
        style="min-height: 400px"
      >
        <Carousel
          v-if="LoadLangShow == 'bn'"
          id="review"
          class=""
          :breakpoints="breakpoints2"
          :itemsToShow="2.95"
          :wrapAround="true"
          :mouseDrag="false"
        >
          <Slide v-for="slide in testimonialSlidesBangla" :key="slide">
            <div class="carousel__item my-5 py-5 px-2">
              <div class="row d-flex flex-column">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title p-0 m-0">
                        <div class="row d-flex justify-content-between">
                          <div class="col-9 text-warning">
                            <small class="d-flex flex-row bd-highlight">
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                            </small>
                          </div>
                          <div class="col-3 text-start text-lg-end">
                            <img
                              src="../assets/quotes.png"
                              alt=""
                              class="img-fluid w-50"
                            />
                          </div>
                        </div>
                      </h5>
                      <div class="row text-start text-lg-start">
                        <div class="col-12 mb-2">
                          <small class="fw-bold" style="font-size: 12px">{{
                            slide.title
                          }}</small>
                        </div>
                        <div class="col-12 mb-3">
                          <p style="font-size: 0.7rem">
                            {{ slide.text }}
                          </p>
                        </div>
                        <div class="col-12 d-flex flex-row">
                          <div class="row">
                            <div class="col">
                              <img
                                :src="require(`@/assets/t${slide.icon}.png`)"
                                alt=""
                                class="img-fluid rounded-circle"
                                width="40"
                                height="20"
                              />
                            </div>
                          </div>
                          <div class="row d-flex flex-column px-2">
                            <h6 class="h6 m-0">{{ slide.name }}</h6>
                            <small style="font-size: 10px" class="mt-0">{{
                              slide.designation
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <navigation
              ><template #next>
                <i class="bi bi-arrow-right" @click="nextReviewHandler($event)"></i>
              </template>
              <template #prev>
                <i
                  class="bi bi-arrow-left"
                  @click="prevReviewHandler($event)"
                ></i> </template
            ></navigation>
          </template>
        </Carousel>
        <Carousel
          v-else
          id="review"
          class=""
          :breakpoints="breakpoints2"
          :itemsToShow="2.95"
          :wrapAround="true"
          :mouseDrag="false"
        >
          <Slide v-for="slide in testimonialSlides" :key="slide">
            <div class="carousel__item my-5 py-5 px-2">
              <div class="row d-flex flex-column">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title p-0 m-0">
                        <div class="row d-flex justify-content-between">
                          <div class="col-9 text-warning">
                            <small class="d-flex flex-row bd-highlight">
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                              <div class="p-1 bd-highlight">
                                <i class="bi bi-star-fill"></i>
                              </div>
                            </small>
                          </div>
                          <div class="col-3 text-start text-lg-end">
                            <img
                              src="../assets/quotes.png"
                              alt=""
                              class="img-fluid w-50"
                            />
                          </div>
                        </div>
                      </h5>
                      <div class="row text-start text-lg-start">
                        <div class="col-12 mb-2">
                          <small class="fw-bold" style="font-size: 12px">{{
                            slide.title
                          }}</small>
                        </div>
                        <div class="col-12 mb-3">
                          <p style="font-size: 0.7rem">
                            {{ slide.text }}
                          </p>
                        </div>
                        <div class="col-12 d-flex flex-row">
                          <div class="row">
                            <div class="col">
                              <img
                                :src="require(`@/assets/t${slide.icon}.webp`)"
                                alt=""
                                class="img-fluid rounded-circle"
                                width="40"
                                height="20"
                              />
                            </div>
                          </div>
                          <div class="row d-flex flex-column px-2">
                            <h6 class="h6 m-0">{{ slide.name }}</h6>
                            <small style="font-size: 10px" class="mt-0">{{
                              slide.designation
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <navigation
              ><template #next>
                <i class="bi bi-arrow-right" @click="nextReviewHandler($event)"></i>
              </template>
              <template #prev>
                <i
                  class="bi bi-arrow-left"
                  @click="prevReviewHandler($event)"
                ></i> </template
            ></navigation>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
  <!--REVIEW Section END-->
  <!--Book Ride Buy Credit Refer a route Section Start-->
  <div class="container my-5 book-ride-option">
    <div class="row text-center me-lg-3 me-5 my-5">
      <div
        class="col-lg-7 col-md-10 col-sm-12 col-12 offset-lg-4 offset-md-2 offset-0 align-self-center"
      >
        <button
          class="btn header-buttons shadow-none"
          @click="activeTab2 = 'BookRide'"
          :class="{ active: activeTab2 === 'BookRide' }"
        >
          {{ $t("message.bookingProcess.button", {}, { locale: LoadLangShow }) }}
        </button>
        <button
          class="btn header-buttons shadow-none"
          @click="activeTab2 = 'BuyCredit'"
          :class="{ active: activeTab2 === 'BuyCredit' }"
        >
          {{ $t("message.buyCredit.button", {}, { locale: LoadLangShow }) }}
        </button>
        <!-- <button
            class="btn header-buttons shadow-none"
            @click="activeTab2 = 'ReferARoute'"
            :class="{ active: activeTab2 === 'ReferARoute' }"
          >
            Refer a Route
          </button> -->
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="row text-center app-feature">
      <keep-alive>
        <component :is="activeTab2" />
      </keep-alive>
    </div>
  </div>

  <!--Book Ride Buy Credit Refer a route Section End-->
  <!--Number Section START-->
  <div class="container-fluid my-5" style="background-color: #fcf9ff">
    <div class="col pt-3 journey-statistics container">
      <div class="container mb-2">
        <div class="business-line2"></div>
      </div>
      <h1 class="h3 text-center mb-3 fw-bold">
        {{ $t("message.journeyInNumbers.headline", {}, { locale: LoadLangShow }) }}
      </h1>
      <div class="row row-cols-1 row-cols-md-1 row-cols-lg-3 py-3 text-center">
        <div class="col">
          <img src="@/assets/ride-icon.webp" alt="" class="img-fluid w-25 mb-lg-4 mb-2" />
          <h4 class="fw-bolder">
            {{ $t("message.journeyInNumbers.rideCount", {}, { locale: LoadLangShow }) }}
          </h4>
          <p class="fw-bold">
            {{ $t("message.journeyInNumbers.rideTitle", {}, { locale: LoadLangShow }) }}
          </p>
        </div>
        <div class="col">
          <img
            src="@/assets/vehicle_icon.webp"
            alt=""
            class="img-fluid w-25 mb-lg-4 mb-2"
          />
          <h4 class="fw-bolder">
            {{
              $t("message.journeyInNumbers.passengerCount", {}, { locale: LoadLangShow })
            }}
          </h4>
          <p class="fw-bold">
            {{
              $t("message.journeyInNumbers.passengerTitle", {}, { locale: LoadLangShow })
            }}
          </p>
        </div>
        <div class="col">
          <img
            src="@/assets/Mobile_Icon.webp"
            alt=""
            class="img-fluid w-25 mb-lg-4 mb-2"
          />
          <h4 class="fw-bolder">
            {{ $t("message.journeyInNumbers.routeCount", {}, { locale: LoadLangShow }) }}
          </h4>
          <p class="fw-bold">
            {{ $t("message.journeyInNumbers.routeTitle", {}, { locale: LoadLangShow }) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--Number Section END-->
  <!--Driver Income Section START-->
  <div class="container my-5">
    <div class="row mb-5">
      <div class="col-lg-12 col-md-12 col-sm-12 position-relative">
        <span class="d-lg-flex d-md-none d-none d-flex mt-lg-3">
          <div class="position-absolute top-0 end-0">
            <img src="@/assets/rectangle.png" alt="" class="img-fluid" />
          </div>
        </span>
        <div class="card income-feature" style="background: #6c3a97; color: white">
          <div class="card-body">
            <div class="row d-flex flex-row justify-content-start">
              <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="row d-flex flex-column income-feature-content">
                  <div class="col">
                    <h1 class="h2 fs-3 fw-bold">
                      {{
                        $t("message.earnFixedIncome.title", {}, { locale: LoadLangShow })
                      }}
                    </h1>
                  </div>
                  <div class="col mb-3 fixed-income-text">
                    <small>
                      {{
                        $t(
                          "message.earnFixedIncome.body.b1",
                          {},
                          { locale: LoadLangShow }
                        )
                      }}
                      <br />
                      {{
                        $t(
                          "message.earnFixedIncome.body.b2",
                          {},
                          { locale: LoadLangShow }
                        )
                      }}
                    </small>
                  </div>
                  <div class="col">
                    <button class="btn bg-white reg-drive w-50 mb-2">
                      <a
                        class="fw-bold"
                        href="/partner"
                        style="text-decoration: none; color: #6c3a97"
                        >{{
                          $t(
                            "message.earnFixedIncome.button",
                            {},
                            { locale: LoadLangShow }
                          )
                        }}</a
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-12 mb-2 mt-lg-3">
                <div class="row justify-content-lg-end justify-content-start">
                  <img
                    src="@/assets/driver.webp"
                    class="img-fluid w-50 rounded me-lg-5 me-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Driver Income Section END-->
  <!-- </main> -->

  <teleport to="body">
    <ModalB2BVue />
  </teleport>

  <section class="registration-panel pt-5">
    <!-- signup Form -->
    <div
      class="modal fade"
      id="b2cModal"
      tabindex="-1"
      aria-labelledby="B2BModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header" style="background-color: rgba(218, 218, 218, 0.22)">
            <button
              type="button"
              class="btn-close btn-sm"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="close()"
            ></button>
          </div>
          <div
            :class="
              direction == 'b2b'
                ? 'modal-body border-0 ps-0 pt-0 business'
                : 'modal-body border-0 ps-0 pt-0 myself'
            "
            id="signupModalFormShow2"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div
                  :class="
                    direction == 'b2b'
                      ? 'col-12 col-md-8-half col-lg-3-half pt-2 business'
                      : 'col-12 col-md-8-half col-lg-3-half pt-2 myself'
                  "
                >
                  <div class="header pb-2 px-3">
                    <p>
                      {{ $t("message.signupForm.title", {}, { locale: LoadLangShow }) }}
                    </p>
                    <!-- <h1>{{direction}}</h1> -->
                  </div>
                  <div class="title px-3 pb-2">
                    <div class="row">
                      <div class="col-6 col-md-6 col-lg-6">
                        <button
                          type="button"
                          :class="direction == 'b2c' ? 'btn active' : 'btn'"
                          id="daily"
                          @click="dailyRide()"
                        >
                          {{
                            $t("message.signupForm.option1", {}, { locale: LoadLangShow })
                          }}
                        </button>
                      </div>
                      <div class="col-6 col-md-6 col-lg-6">
                        <button
                          type="button"
                          :class="direction == 'b2b' ? 'btn active' : 'btn'"
                          id="business"
                          @click="businessRide()"
                        >
                          {{
                            $t("message.signupForm.option2", {}, { locale: LoadLangShow })
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- myself form -->
                  <div
                    class="login-content text-left px-3 pt-4 pb-2"
                    v-if="direction == 'b2c'"
                  >
                    <form @submit.prevent="dailyRideForm2()">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="name">{{
                            $t("message.signupForm.name", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="text"
                            v-model="name"
                            name="name"
                            id="pName"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="email" class="pt-3">{{
                            $t("message.signupForm.email", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="email"
                            v-model="email"
                            name="email"
                            id="pEmail"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="mobile" class="pt-3">{{
                            $t("message.signupForm.mobile", {}, { locale: LoadLangShow })
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="mobile"
                              name="mobile"
                              id="pMobile"
                              class="form-control mr-2"
                              @change="validateData"
                              autocomplete="address-level4"
                              required
                            />
                          </div>
                          <span
                            style="font-size: 13px; padding-left: 10px"
                            v-if="mobileValidateMsg"
                            class="text-danger"
                            >{{ mobileValidateMsg }}</span
                          >
                          <img
                            v-if="mobileValidateMsg"
                            class="pt-2"
                            width="25px"
                            height="25px"
                            src="/assets/images/partner/error.png"
                            alt=""
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="mobile" class="pt-3">{{
                            $t(
                              "message.signupForm.institution",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="institution"
                              name="name"
                              id="pName"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="city" class="pt-3">{{
                            $t(
                              "message.signupForm.pickupLocation",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="pickup"
                            id="Pickup Location"
                            placeholder=""
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="city" class="pt-3">{{
                            $t(
                              "message.signupForm.dropOffArea",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="dropoff"
                            id="Dropoff Location"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 px-3 text-center login-loader">
                          <span class="text-danger" v-if="error">{{ error }}</span>
                          <!-- <div v-if="loading" class="loader text-center"></div> -->
                        </div>
                      </div>
                      <div class="form-group row pt-4">
                        <div class="col-md-12 text-center">
                          <button type="submit" class="btn btn-sm px-4 py-2 submit">
                            {{
                              $t(
                                "message.signupForm.button",
                                {},
                                { locale: LoadLangShow }
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- organization form -->
                  <div
                    class="login-content text-left px-3 pt-4 pb-2"
                    v-if="direction == 'b2b'"
                  >
                    <form @submit.prevent="businessRideForm2()">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="name">{{
                            $t("message.signupForm.company", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="text"
                            v-model="companyname"
                            name="name"
                            id="name"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="mobile" class="pt-3">{{
                            $t(
                              "message.signupForm.employeeNo",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="noofemployees"
                              name="mobile"
                              id="pMobile"
                              class="form-control mr-2"
                              @change="validateData"
                              autocomplete="address-level4"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="name" class="pt-3">{{
                            $t(
                              "message.signupForm.contactName",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <input
                            type="text"
                            v-model="contactname"
                            name="name"
                            id="name"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="email" class="pt-3">{{
                            $t("message.signupForm.email", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="email"
                            v-model="organizationemail"
                            name="email"
                            id=""
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="mobile" class="pt-3">{{
                            $t("message.signupForm.mobile", {}, { locale: LoadLangShow })
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="b2bMobile"
                              name="mobile"
                              id="pMobile"
                              class="form-control mr-2"
                              @change="validateData"
                              autocomplete="address-level4"
                              required
                            />
                          </div>
                          <span
                            style="font-size: 13px; padding-left: 10px"
                            v-if="mobileValidateMsg"
                            class="text-danger"
                            >{{ mobileValidateMsg }}</span
                          >
                          <img
                            v-if="mobileValidateMsg"
                            class="pt-2"
                            width="25px"
                            height="25px"
                            src="/assets/images/partner/error.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 px-3 text-center login-loader">
                          <span class="text-danger" v-if="error">{{ error }}</span>
                          <!-- <div v-if="loading" class="loader text-center"></div> -->
                        </div>
                      </div>
                      <div class="form-group row pt-4">
                        <div class="col-md-12 text-center">
                          <button type="submit" class="btn btn-sm px-4 py-2 submit">
                            {{
                              $t(
                                "message.signupForm.button",
                                {},
                                { locale: LoadLangShow }
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- thank you modal body -->
          <div class="modal-body" id="thankYouModalBody2" style="display: none">
            <div class="row">
              <div class="col-md-12 text-center" id="icon">
                <h1><i class="fa fa-check-circle"></i></h1>
              </div>
              <div class="col-md-12 text-center" id="notification">
                <p class="px-3">
                  {{ $t("message.modal.thankYou.body", {}, { locale: LoadLangShow }) }}
                </p>
                <button
                  type="button"
                  class="btn btn-danger btn-sm mb-3"
                  id="submit"
                  data-bs-dismiss="modal"
                  aria-label="close"
                  @click="close()"
                >
                  {{ $t("message.modal.thankYou.button", {}, { locale: LoadLangShow }) }}
                </button>
              </div>
            </div>
          </div>
          <!-- thank you end -->
        </div>
      </div>
    </div>
    <footer>
      <the-footer></the-footer>
    </footer>
  </section>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";

import RideTab from "../components/RideTab.vue";
import BusinessTab from "../components/BusinessTab.vue";
import DriveTab from "../components/DriveTab.vue";
import BookRide from "../components/BookRide.vue";
import BuyCredit from "../components/BuyCredit.vue";
import ReferARoute from "../components/ReferARoute.vue";

import ModalB2BVue from "../components/modal/ModalB2B.vue";
import TheHeaderVue from "../components/TheHeader.vue";
// import SignupModalB2C from '../components/SignUpModalB2C.vue';
import $ from "jquery";
import axios from "axios";
import TheFooterVue from "../components/TheFooter.vue";

export default {
  name: "Home",
  data() {
    return {
      name: "",
      mobile: "",
      mobileValidateMsg: "",
      email: "",
      institution: "",
      pickup: "",
      dropoff: "",
      city: 1,
      password: "",
      areaList: [],
      error: "",
      loading: "",
      companyname: "",
      b2bMobile: "",
      contactname: "",
      noofemployees: "",
      organizationemail: "",
      isModalOpen: false,

      direction: "b2c",
      activeTab: "RideTab",
      activeTab2: "BookRide",
      currentReviewSlide: 0,
      //daily commute data below
      slides: [
        {
          title: "Fixed Rates",
          id: "Fixed-Rates",
          ID: 1,
          image: "1",
          bg: "#F8F5FF",
          text:
            "Whether it’s the peak of rush hour or the rare slow morning, enjoy fixed rates on your daily commute. ",
        },
        {
          title: "Safe Trips",
          id: "Safe-Trips",
          image: "2",
          ID: 2,
          bg: "#F2FAF7",
          text:
            "A safe ride starts with our partners. Our drivers are carefully vetted carefully after background checks.",
        },
        {
          title: "Zero Cancellations",
          id: "Zero-Cancellations",
          image: "3",
          bg: "#EFF8FE",
          ID: 3,
          text:
            "Pre-booking trips guarantee that our partners are 100% on-board with trips and have no reason to cancel.",
        },
        {
          title: "Rides For Women",
          id: "Rides-For-Women",
          image: "4",
          ID: 4,
          bg: "#FDF1F9",
          text:
            "In addition to regular rides, Shuttle for women provides rides dedicated to driving women forward.",
        },
        {
          title: "Citywide Coverage",
          id: "Citywide-Coverage",
          image: "5",
          ID: 5,
          bg: "#EFF8FE",
          text:
            "Hello Dhaka! We have you covered. Our routes span across 75 routes throughout the city and we’re constantly adding more.",
        },
        {
          title: "Shared Rides",
          id: "Shared-Rides",
          bg: "#F2FAF7",
          image: "6",
          ID: 6,
          text:
            "Sharing your ride ensures that you can enjoy an affordable commute without compromising on comfort.",
        },
        {
          title: "Fixed Rates",
          id: "Fixed-Rates",
          bg: "#F8F5FF",
          image: "1",
          ID: 7,
          text:
            "Whether it’s the peak of rush hour or the rare slow morning, enjoy fixed rates on your daily commute. ",
        },
        {
          title: "Safe Trips",
          id: "Safe-Trips",
          bg: "#F2FAF7",
          image: "2",
          ID: 8,
          text:
            "A safe ride starts with our partners. Our drivers are carefully vetted carefully after background checks.",
        },
        {
          title: "Zero Cancellations",
          id: "Zero-Cancellations",
          bg: "#EFF8FE",
          image: "3",
          ID: 9,
          text:
            "Pre-booking trips guarantee that our partners are 100% on-board with trips and have no reason to cancel.",
        },
        {
          title: "Rides For Women",
          id: "Rides-For-Women",
          bg: "#FDF1F9",
          image: "4",
          ID: 10,
          text:
            "In addition to regular rides, Shuttle for women provides rides dedicated to driving women forward.",
        },
      ],
      slidesBangla: [
        {
          title: "ফিক্সড রেট",
          id: "Fixed-Rates",
          ID: 1,
          image: "1",
          bg: "#F8F5FF",
          text:
            "রাশ আওয়ার কিংবা অফপিক, প্রতিদিনের যাতায়াতে উপভোগ করুন ফিক্সড রেট এর ভাড়া।",
        },
        {
          title: "নিরাপদ প্রতিদিনের যাতায়াত",
          id: "Safe-Trips",
          image: "2",
          ID: 2,
          bg: "#F2FAF7",
          text:
            "যাতায়াতে আপনার নিরাপত্তা নিশ্চিত করতে আমাদের প্রত্যেক ড্রাইভারকে সতর্কতার সাথে যাচাই করা হয়।",
        },
        {
          title: "জিরো ক্যান্সেলেশন",
          id: "Zero-Cancellations",
          image: "3",
          bg: "#EFF8FE",
          ID: 3,
          text:
            "সিট প্রিবুক করার ফলে আপনার সম্পর্কে ড্রাইভার আগে থেকেই অবহিত থাকেন, তাই রাইড ক্যান্সেল হওয়ার ঝামেলা থাকেনা।",
        },
        {
          title: "ফিমেল অনলি",
          id: "Rides-For-Women",
          image: "4",
          ID: 4,
          bg: "#FDF1F9",
          text:
            "রেগুলার রাইডের পাশাপাশি রয়েছে নারীদের নিরাপদে যাতায়াতের জন্য ফিমেল অনলি সার্ভিস।",
        },
        {
          title: "শহরজুড়ে কভারেজ",
          id: "Citywide-Coverage",
          image: "5",
          ID: 5,
          bg: "#EFF8FE",
          text:
            "হ্যালো ঢাকা! শহরজুড়ে যাতায়াতের জন্য আমাদের জন্য রয়েছে ৮০ টি রুট। এছাড়াও আমরা প্রতিনিয়ত রুটসংখ্যা বাড়ানোর জন্য কাজ করে যাচ্ছি।",
        },
        {
          title: "শেয়ারে যাতায়াত করুন",
          id: "Shared-Rides",
          bg: "#F2FAF7",
          image: "6",
          ID: 6,
          text: "শেয়ারে রাইড নিন, খরচ শেয়ার করুন। সাশ্রয় করুন প্রতিদিনের যাতায়াতে।",
        },
        {
          title: "ফিক্সড রেট",
          id: "Fixed-Rates",
          bg: "#F8F5FF",
          image: "1",
          ID: 7,
          text:
            "রাশ আওয়ার কিংবা অফপিক, প্রতিদিনের যাতায়াতে উপভোগ করুন ফিক্সড রেট এর ভাড়া।",
        },
        {
          title: "নিরাপদ প্রতিদিনের যাতায়াত",
          id: "Safe-Trips",
          bg: "#F2FAF7",
          image: "2",
          ID: 8,
          text:
            "যাতায়াতে আপনার নিরাপত্তা নিশ্চিত করতে আমাদের প্রত্যেক ড্রাইভারকে সতর্কতার সাথে যাচাই করা হয়।",
        },
        {
          title: "জিরো ক্যান্সেলেশন",
          id: "Zero-Cancellations",
          bg: "#EFF8FE",
          image: "3",
          ID: 9,
          text:
            "সিট প্রিবুক করার ফলে আপনার সম্পর্কে ড্রাইভার আগে থেকেই অবহিত থাকেন, তাই রাইড ক্যান্সেল হওয়ার ঝামেলা থাকেনা।",
        },
        {
          title: "ফিমেল অনলি",
          id: "Rides-For-Women",
          bg: "#FDF1F9",
          image: "4",
          ID: 10,
          text:
            "রেগুলার রাইডের পাশাপাশি রয়েছে নারীদের নিরাপদে যাতায়াতের জন্য ফিমেল অনলি সার্ভিস।",
        },
      ],
      // testimonial data below
      testimonialSlides: [
        {
          title: "CUSTOMIZED ROUTES",
          id: "CUSTOMIZED-ROUTES",
          text:
            "The way Shuttle provides customized routes & offers their services are really convenient for office-going people.",
          icon: "1",
          name: "Tasnia Islam Rifa",
          designation: "Student, IUT",
        },
        {
          title: "AFFORDABLE AC CARS",
          id: "AFFORDABLE-AC-CARS",
          text:
            "I prefer using Shuttle over any other medium because the cars were air conditioned and the fare was comparatively less.",
          icon: "2",
          name: "Farzana Hossain",
          designation: "Senior executive, Your Counselor",
        },
        {
          title: "ALWAYS RELIABLE",
          id: "ALWAYS-RELIABLE",
          text:
            "I didn’t have to worry about finding a ride to work every morning because Shuttle provided me with that certainty.",
          icon: "3",
          name: "Anika Mehrin",
          designation: "Business Executive , Transcom.",
        },
        {
          title: "CUSTOMIZED ROUTES",
          id: "CUSTOMIZED-ROUTES",
          text:
            "The way Shuttle provides customized routes & offers their services are really convenient for office-going people.",
          icon: "1",
          name: "Tasnia Islam Rifa",
          designation: "Student, IUT",
        },
        {
          title: "AFFORDABLE AC CARS",
          id: "AFFORDABLE-AC-CARS",
          text:
            "I prefer using Shuttle over any other medium because the cars were air conditioned and the fare was comparatively less.",
          icon: "2",
          name: "Farzana Hossain",
          designation: "Senior executive, Your Counselor",
        },
        {
          title: "ALWAYS RELIABLE",
          id: "ALWAYS-RELIABLE",
          text:
            "I didn’t have to worry about finding a ride to work every morning because Shuttle provided me with that certainty.",
          icon: "3",
          name: "Anika Mehrin",
          designation: "Business Executive , Transcom.",
        },
        {
          title: "CUSTOMIZED ROUTES",
          id: "CUSTOMIZED-ROUTES",
          text:
            "The way Shuttle provides customized routes & offers their services are really convenient for office-going people.",
          icon: "1",
          name: "Tasnia Islam Rifa",
          designation: "Student, IUT",
        },
        {
          title: "AFFORDABLE AC CARS",
          id: "AFFORDABLE-AC-CARS",
          text:
            "I prefer using Shuttle over any other medium because the cars were air conditioned and the fare was comparatively less.",
          icon: "2",
          name: "Farzana Hossain",
          designation: "Senior executive, Your Counselor",
        },
        {
          title: "ALWAYS RELIABLE",
          id: "ALWAYS-RELIABLE",
          text:
            "I didn’t have to worry about finding a ride to work every morning because Shuttle provided me with that certainty.",
          icon: "3",
          name: "Anika Mehrin",
          designation: "Business Executive , Transcom.",
        },
      ],
      testimonialSlidesBangla: [
        {
          title: "কাস্টোমাইজড রুট",
          id: "CUSTOMIZED-ROUTES",
          text:
            "শাটেল যেভাবে চাকরিজীবীদের সুবিধা অনুযায়ী কাস্টমাইজড রুট এর ব্যবস্থা করে তা সত্যিই প্রশংসনীয়",
          icon: "1",
          name: "তাসনিয়া ইসলাম রিফা",
          designation: "ছাত্রী, আই ইউ টি",
        },
        {
          title: "কম খরচে এসি গাড়ি!",
          id: "AFFORDABLE-AC-CARS",
          text:
            "প্রতিদিনের যাতায়াতে শাটেলই পছন্দ কারণ, এতে সবচেয়ে সাশ্রয়ী রেটে এসি গাড়িতে যাতায়াত করা যায়।",
          icon: "2",
          name: "ফারজানা হোসেইন",
          designation: "সিনিয়র এক্সিকিউটিভ, ইওর কাউন্সিলর",
        },
        {
          title: "সবসময় নির্ভরযোগ্য",
          id: "ALWAYS-RELIABLE",
          text:
            "শাটেল রাইডের নিশ্চয়তা দেয়। তাই, আমাকে প্রতিদিন সকালে অফিসের জন্য রাইড খুঁজে পেতে চিন্তা করতে হয়না।",
          icon: "3",
          name: "আনিকা মেহরিন",
          designation: "বিজনেস এক্সিকিউটিভ, ট্রান্সকম",
        },
        {
          title: "কাস্টোমাইজড রুট",
          id: "CUSTOMIZED-ROUTES",
          text:
            "শাটেল যেভাবে চাকরিজীবীদের সুবিধা অনুযায়ী কাস্টমাইজড রুট এর ব্যবস্থা করে তা সত্যিই প্রশংসনীয়",
          icon: "1",
          name: "তাসনিয়া ইসলাম রিফা",
          designation: "ছাত্রী, আই ইউ টি",
        },
        {
          title: "কম খরচে এসি গাড়ি!",
          id: "AFFORDABLE-AC-CARS",
          text:
            "প্রতিদিনের যাতায়াতে শাটেলই পছন্দ কারণ, এতে সবচেয়ে সাশ্রয়ী রেটে এসি গাড়িতে যাতায়াত করা যায়।",
          icon: "2",
          name: "ফারজানা হোসেইন",
          designation: "সিনিয়র এক্সিকিউটিভ, ইওর কাউন্সিলর",
        },
        {
          title: "সবসময় নির্ভরযোগ্য",
          id: "ALWAYS-RELIABLE",
          text:
            "শাটেল রাইডের নিশ্চয়তা দেয়। তাই, আমাকে প্রতিদিন সকালে অফিসের জন্য রাইড খুঁজে পেতে চিন্তা করতে হয়না।",
          icon: "3",
          name: "আনিকা মেহরিন",
          designation: "বিজনেস এক্সিকিউটিভ, ট্রান্সকম",
        },
        {
          title: "কাস্টোমাইজড রুট",
          id: "CUSTOMIZED-ROUTES",
          text:
            "শাটেল যেভাবে চাকরিজীবীদের সুবিধা অনুযায়ী কাস্টমাইজড রুট এর ব্যবস্থা করে তা সত্যিই প্রশংসনীয়",
          icon: "1",
          name: "তাসনিয়া ইসলাম রিফা",
          designation: "ছাত্রী, আই ইউ টি",
        },
        {
          title: "কম খরচে এসি গাড়ি!",
          id: "AFFORDABLE-AC-CARS",
          text:
            "প্রতিদিনের যাতায়াতে শাটেলই পছন্দ কারণ, এতে সবচেয়ে সাশ্রয়ী রেটে এসি গাড়িতে যাতায়াত করা যায়।",
          icon: "2",
          name: "ফারজানা হোসেইন",
          designation: "সিনিয়র এক্সিকিউটিভ, ইওর কাউন্সিলর",
        },
        {
          title: "সবসময় নির্ভরযোগ্য",
          id: "ALWAYS-RELIABLE",
          text:
            "শাটেল রাইডের নিশ্চয়তা দেয়। তাই, আমাকে প্রতিদিন সকালে অফিসের জন্য রাইড খুঁজে পেতে চিন্তা করতে হয়না।",
          icon: "3",
          name: "আনিকা মেহরিন",
          designation: "বিজনেস এক্সিকিউটিভ, ট্রান্সকম",
        },
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },
      },
      breakpoints2: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 2.9,
          snapAlign: "center",
        },
      },
    };
  },
  created() {
    // this.emitter.on('direction', d => {
    //     this.direction = d;
    // })
  },
  components: {
    TheHeader: TheHeaderVue,
    RideTab,
    BusinessTab,
    DriveTab,
    BookRide,
    BuyCredit,
    ReferARoute,
    Carousel,
    Slide,
    Navigation,
    ModalB2BVue,
    // SignupModalB2C,
    TheFooter: TheFooterVue,
  },
  computed: {
    LoadLangShow() {
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  methods: {
    SlideMouseOverHandler() {
      let lists = document.querySelectorAll(
        "#b2c-carousel-daily.carousel .carousel__viewport ol.carousel__track>li"
      );
      let activeEl = "carousel__slide--active";
      lists.forEach(function (el) {
        el.classList.remove(activeEl);
      });
    },
    nextReviewHandler(e) {
      e.currentTarget.parentNode.setAttribute(
        "style",
        "background: #7F40D3 !important; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 50%; transform: translate(-50%, -50%);"
      );
      const prevButton = e.currentTarget.parentNode.previousSibling;
      prevButton.setAttribute("style", "");
      prevButton.setAttribute(
        "style",
        "background: #fff; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 45%; transform: translate(-50%, -50%);"
      );
      prevButton.childNodes[0].setAttribute(
        "style",
        "color: #7F40D3; height: auto; width: 100%;"
      );
      e.currentTarget.setAttribute(
        "style",
        "color: #ffffff !important; height: auto; width: 100%;"
      );
    },
    prevReviewHandler(e) {
      e.currentTarget.parentNode.setAttribute(
        "style",
        "background: #7F40D3 !important; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 44%; transform: translate(-50%, -50%);"
      );
      const nextButton = e.currentTarget.parentNode.nextElementSibling;
      nextButton.setAttribute("style", "");
      nextButton.setAttribute(
        "style",
        "background: #fff; border-radius: 5px !important; width: 50px; height: 42px; top: 100%; left: 50%; transform: translate(-50%, -50%);"
      );
      nextButton.childNodes[0].setAttribute(
        "style",
        "color: #7F40D3; height: auto; width: 100%;"
      );
      e.currentTarget.setAttribute(
        "style",
        "color: #ffffff !important; height: auto; width: 100%;"
      );
    },
    dailyRideForm2: function () {
      const b2bData = {
        name: this.name,
        cusemail: this.email,
        institution: this.institution,
        phone: this.mobile,
        pickup: this.pickup,
        dropoff: this.dropoff,
      };
      
      if (this.mobile.length < 11 || this.mobile.length > 11) {
        this.mobileValidateMsg = "Incorrect phone number";
      } else {
        this.mobileValidateMsg = "";
        axios
          .post("https://api.shuttlebd.com/women-register", b2bData)
          .then((response) => {
            console.log(response);
            this.isModalOpen = true;
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          })
          .catch((error) => {
            console.log("error =======", error);
            this.isModalOpen = true;
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          });
      }
    },
    dailyRide: function () {
      this.direction = "b2c";
      $(".title #business").removeClass("active");
      $(".title #daily").addClass("active");
      this.isModalOpen = true;
      this.b2bMobile = "";
      this.companyname = "";
      this.noofemployees = "";
      this.contactname = "";
      this.organizationemail = "";
    },
    businessRide: function () {
      this.direction = "b2b";
      $(".title #daily").removeClass("active");
      $(".title #business").addClass("active");
      this.isModalOpen = true;
      this.mobile = "";
      this.name = "";
      this.email = "";
      this.institution = "";
      this.pickup = "";
      this.dropoff = "";
    },
    businessRideForm2: function () {
      const companydata = {
        company_name: this.companyname,
        contact_name: this.contactname,
        phone: this.b2bMobile,
        email: this.organizationemail,
        no_of_employees: this.noofemployees,
      };
      if (this.b2bMobile.length < 11 || this.b2bMobile.length > 11) {
        this.mobileValidateMsg = "Incorrect phone number";
      } else {
        this.mobileValidateMsg = "";
        axios
          .post("https://api.shuttlebd.com/signup", companydata)
          .then((response) => {
            console.log(response);
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          })
          .catch((error) => {
            console.log("error =======", error);
            this.isModalOpen = true;
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          });
      }
    },
    close() {
      // if (this.direction == 'b2c') {
      //   this.direction = 'b2b'
      // } else {
      this.direction = "b2c";
      if (this.activeTab === "BusinessTab") {
        this.direction = "b2b";
      }
      // }
      $("#thankYouModalBody2").css("display", "none");
      $("#signupModalFormShow2").css("display", "block");
      this.mobile = "";
      this.companyname = "";
      this.noofemployees = "";
      this.contactname = "";
      this.organizationemail = "";
      this.name = "";
      this.email = "";
      this.b2bMobile = "";
      this.institution = "";
      this.pickup = "";
      this.dropoff = "";
    },
  },
  mounted() {
    $(document).ready(function () {
      this.isModalOpen = true;
      var elements = document.getElementsByTagName("input");
      for (let i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function (e) {
          e.target.setCustomValidity("");
          if (!e.target.validity.valid) {
            e.target.setCustomValidity("অনুগ্রহ করে এই ঘরটি পুরন করুন।");
          }
          elements[i].oninput = function (e) {
            e.target.setCustomValidity("");
          };
        };
      }
    });

    var btn = $("#button");
    $(window).scroll(function () {
      if ($(window).scrollTop() > 350) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    let prev = document.querySelector("#review .carousel__prev");
    let next = document.querySelector("#review .carousel__next");

    prev.setAttribute(
      "style",
      "background: #FFFFFF !important; border-radius: 5px !important; margin: -6px 20px;width: 40px; height: 42px;"
    );
    next.setAttribute(
      "style",
      "background: #7F40D3 !important; border-radius: 5px !important; margin: -6px 20px;width: 40px; height: 42px;"
    );

    document
      .querySelector("#review .carousel__prev i")
      .setAttribute(
        "style",
        "color: #7F40D3 !important; font-size: 20px !important; padding: 5px 4px !important; width: 100%; height: 100%;"
      );
    document
      .querySelector("#review .carousel__next i")
      .setAttribute(
        "style",
        "color: #ffffff !important; font-size: 20px !important; padding: 5px 4px !important; font-weight: bold;width: 100%; height: 100%;"
      );
  },
  watch: {},
};
</script>

<style scoped>
img.building-img {
  display: none;
}
.business-line2 {
  width: 100px;
  margin: 0 auto;
  height: 7px;
  background: #6c3a97;
  border-radius: 3px;
}
.income-feature-content.d-flex.flex-column {
  padding: 20px 36px;
}
#Fixed-Rates {
  color: #a368cf;
}
#Fixed-Rates .slide-title {
  font-weight: bold;
}
#Safe-Trips {
  color: #13573a;
}
#Safe-Trips .slide-title {
  font-weight: bold;
}
#Zero-Cancellations {
  color: #2c518e;
}
#Zero-Cancellations .slide-title {
  font-weight: bold;
}
#Rides-For-Women {
  color: #af3e79;
}
#Rides-For-Women .slide-title {
  font-weight: 700;
}
#Citywide-Coverage {
  color: #2c518e;
}
#Citywide-Coverage .slide-title {
  font-weight: bold;
}
#Shared-Rides {
  color: #af3e79;
}
#Shared-Rides .slide-title {
  font-weight: bold;
}

img.building-img {
  opacity: 0.6;
}
.col-12.col-md-12.col-lg-12.px-lg-3.bg-light {
  background-color: #fcf9ff !important;
}
.bar {
  position: fixed;
  bottom: 30px;
  right: 64px;
  z-index: 9999999999999;
}
.position-absolute.top-0.start-0 {
  top: -3px !important;
}

/* modal */

input:focus,
select:focus {
  border: 1px solid lightgray;
  box-shadow: none;
}
.title button {
  font-size: 18px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #6c3a97;
}
/* .title button:active,button:focus,button:visited{ color: #fff; background-color: #6c3a97; } */
.active {
  color: #fff;
  background-color: #6c3a97 !important;
  border: 1px solid #6c3a97;
}
label {
  color: #000000;
}
.header p:first-child {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}
.header p:nth-child(2) {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000;
}
label::after {
  content: " *";
  font-size: 18px;
  color: red;
}
.modal-header {
  border-bottom: none;
}
.submit {
  background-color: #6c3a97;
  color: #fff;
}

.mobile-validate-msg {
  font-size: 14px;
}
.mobile-validate-msg img {
  width: 20px;
}
.modal {
  font-family: "Montserrat", sans-serif;
}
.modal-header {
  border-bottom: none;
}
.modal-content {
  /* background-color: #242d66; */
  color: #5d5d5e;
  /*font-family: sans-serif;*/
}
.modal-body {
  padding: 0px;
}
.modal-title h4 {
  font-size: 20px;
  padding-top: 8%;
}
.modal-title p {
  padding: 0 10% 0 10%;
  font-weight: normal;
}
.submit-btn {
  background-color: #6c3a97;
  color: #fff;
  font-weight: normal;
  border-radius: 8px;
  border: none;
  text-transform: capitalize;
  padding: 5px;
}
.form-control {
  font-size: 0.8rem !important;
}
.modal-body.border-0.ps-0.pt-0.myself {
  background-color: rgba(218, 218, 218, 0.22);
}
.modal-body.border-0.ps-0.pt-0.business {
  background-color: rgba(218, 218, 218, 0.22);
}
</style>
<style>
i.bi.bi-star-fill {
  font-size: 10px;
  letter-spacing: -5px;
}
.container-fluid.my-5 {
  background-color: #fcf9ff;
}
.container-fluid.my-5 h1 {
  font-size: 32px;
}
.reg-drive {
  color: #6c3a97;
}
.income-feature h1 {
  font-size: 20px !important;
}
.income-feature p {
  font-size: 17px;
  letter-spacing: 1px;
  padding: 10px 0 10px 0;
}
.header-dots {
  content: ".";
  width: 6px;
  height: 6px;
  background-color: #3f3a3a;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.4;
  cursor: pointer;
}
.header-dots.active {
  transform: scale(1.5);
}
.header-buttons {
  border: none;
  font-size: 0.8rem;
  border-radius: 55px;
  color: #8a8a8a;
  padding: 0.3rem 1rem !important;
  cursor: pointer !important;
  font-weight: 600;
  transition: all 0.3s ease;
}
.header-buttons.active {
  background: #a95eea;
  color: #ffffff;
}

.tabBtn {
  background: #6c3a97;
  border: 1px solid #e5e5e5;
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.tabBtn:hover {
  color: #ffffff;
  background: #6c3a97;
}

.daily-commute-line {
  width: 5.5rem;
  height: 7px;
  background: #6c3a97;
  border-radius: 3px;
}

.slide-title {
  font-size: 16px;
  font-weight: 500;
  margin: 1rem 0;
}

.slide-text {
  font-size: 14px;
  font-weight: 400 !important;
  text-align: start;
}

#review .carousel__slide > .carousel__item {
  transform: scale(1.05);
  opacity: 0.8;
  transition: 0.5s;
}

#review .carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}

#review .carousel__slide--next > .carousel__item {
  transform: scale(0.7) translate(60px);
}

#review .carousel__slide--prev > .carousel__item {
  transform: scale(0.7) translate(-60px);
}

#review .carousel__slide--active > .carousel__item {
  transform: scale(1.4);
  transition: 0.5s;
}

#review .carousel__prev {
  top: 95% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

#review .carousel__next {
  top: 95% !important;
  left: 55% !important;
  transform: translate(-50%, -50%) !important;
}

#review.carousel__slide.carousel__slide--active.carousel__slide--visible {
  transform: scale(1.061) translate(0px);
  opacity: 1;
}
#review.carousel__slide.carousel__slide--visible:hover {
  transform: scale(1.061) translate(0px);
  opacity: 1;
}
#review.carousel__slide.carousel__slide--visible,
#review.carousel__slide.carousel__slide--next {
  transform: scale(1.002222) translate(5px);
  opacity: 1;
}

.translate-middle-x {
  transform: translateX(-47%) !important;
}

.translate-middle-y {
  transform: translateY(0) !important;
}
.journey-statistics h4 {
  color: #6c3a97 !important;
}

#b2c-carousel-daily .carousel__slide > .carousel__item {
  transform: scale(1.05);
  opacity: 0.8;
  transition: 0.5s;
}

#b2c-carousel-daily .carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}

#b2c-carousel-daily .carousel__slide--next > .carousel__item {
  transform: scale(0.7) translate(60px);
}

#b2c-carousel-daily .carousel__slide--prev > .carousel__item {
  transform: scale(0.7) translate(-60px);
}

#b2c-carousel-daily .carousel__slide--active > .carousel__item {
  transform: scale(1.4);
  transition: 0.5s;
}

#b2c-carousel-daily .carousel__prev {
  top: 95% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

#b2c-carousel-daily .carousel__next {
  top: 95% !important;
  left: 55% !important;
  transform: translate(-50%, -50%) !important;
}

#b2c-carousel-daily .carousel__slide.carousel__slide--active.carousel__slide--visible {
  transform: scale(1.061) translate(0px);
  opacity: 1;
  margin: 0px 8px;
}
#b2c-carousel-daily .carousel__slide.carousel__slide--visible:hover {
  transform: scale(1.061) translate(0px);
  opacity: 1;
  margin: 0px 8px;
}
#b2c-carousel-daily .carousel__slide.carousel__slide--visible,
#b2c-carousel-daily .carousel__slide.carousel__slide--next {
  transform: scale(1.002222) translate(5px);
  opacity: 1;
}

.review-section-bg {
  background: #f8f9fa;
  padding: 10px;
}
.implement-back-building {
  background: url(/img/move-bus-forward-bg.ddd5cd76.png) no-repeat 95% 100%;
  background-size: 100% 122px;
  /* position: absolute; */
  bottom: 0 !important;
}
.moving-business-style {
  margin-top: 70px;
}
@media (max-width: 991px) {
  /* .review-section-bg {
    background: #fff;
  } */
  .col-lg-3.col-md-12.col-sm-12.pe-5.me-0 {
    padding-right: 20px !important;
  }
  h1.h3.daily-commute-text.mb-5 {
    margin-bottom: 8% !important;
  }
  .col.mb-3.fixed-income-text br {
    display: none;
  }
  img.img-fluid.w-75.rounded {
    width: 100% !important;
  }
  #review li.carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(0.72);
    opacity: 1;
  }
  #review .carousel__slide.carousel__slide--visible {
    transform: scale(1) translate(0px);
    opacity: 0.6;
  }
  #review .carousel__prev {
    left: 44% !important;
  }

  #b2c-carousel-daily .carousel__slide.carousel__slide--active.carousel__slide--visible {
    transform: scale(1) translate(0px);
    opacity: 1;
    margin: 0px;
  }
  .row.text-center.app-feature {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 30px;
  }
  .col-md-12.col-lg-6.col-sm-12.col-12.pb-lg-5.pb-0.book-process-instruction-img {
    padding-left: 50px;
  }
  .row.transportation-team {
    display: flex;
    flex-direction: column-reverse;
    color: #a95eea;
  }
  .position-absolute.bottom-0.end-0.d-lg-flex.d-flex {
    top: 150px;
  }
  .col-lg-6.col-md-12.col-sm-12.tranportation-content {
    margin-top: 55px;
  }
  button.btn.btn-primary.w-100 {
    background-color: #6c3a97 !important;
    border-color: #6c3a97 !important;
  }
  .book-seat.my-5,
  .book-seat .my-5 {
    text-align: center;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .book-seat .row.text-lg-center.text-sm-start.py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0 !important;
  }
  .container.my-5.book-ride-option {
    margin-top: 0rem !important;
  }
  .header-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7px;
    height: 7px;
    padding: 0;
  }
  .w-50 {
    width: 100% !important;
  }
  .flex-row-reverse {
    flex-direction: unset !important;
  }
  h1.h3.fw-bold {
    font-size: 20px !important;
  }
  .bd-highlight p {
    font-size: 16px !important;
  }
  button.btn.btn-primary.w-100 {
    font-size: 16px !important;
  }
  .container.mb-lg-5.py-lg-5 {
    padding-top: 20px;
  }
  .translate-middle-x {
    transform: translateX(18%) !important;
  }
  img.img-fluid.border-0.w-75.rounded.p-2 {
    width: 100% !important;
    height: 220px;
  }
  .journey-statistics {
    text-align: left;
  }
  .journey-statistics h1 {
    font-size: 20px !important;
  }
  .col-lg-6.col-md-12.col-sm-12.col-12.mb-2.mt-lg-3 {
    padding-top: 30px;
  }
  button.btn.bg-white.reg-drive.w-50.mb-2 {
    font-size: 15px;
  }
  .building-img {
    width: 100% !important;
    opacity: 1;
  }

  #button {
    display: inline-block;
    background-color: #6c3a97;
    border: 1px solid #6c3a97;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bolder;
    padding: 8px 20px;
    text-decoration: none;
    position: fixed;
    bottom: 30px;
    right: 100px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  #button:hover {
    cursor: pointer;
    background-color: #6c3a97;
  }
  #button:active {
    background-color: #6c3a97;
  }
  #button.show {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1024px) {
  .building-img {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-7.col-md-10.col-sm-12.col-12.offset-lg-4.offset-md-2.offset-0.align-self-center {
    margin-left: 32%;
  }
}
@media (max-width: 768px) {
  .title button {
    font-size: 12px !important;
  }
  label {
    font-size: 12px !important;
  }
  button.btn.btn-sm.px-4.py-2.submit {
    font-size: 12px !important;
  }
}
@media (max-width: 576px) {
  .title button {
    font-size: 11px !important;
  }
  label {
    font-size: 11px !important;
  }
  button.btn.btn-sm.px-4.py-2.submit {
    font-size: 11px !important;
  }
  .header p {
    font-size: 18px !important;
  }
}
@media (max-width: 400px) {
  .header p {
    font-size: 12px !important;
  }
  .title button {
    font-size: 6px !important;
  }
  label {
    font-size: 10px !important;
  }
  button.btn.btn-sm.px-4.py-2.submit {
    font-size: 8px !important;
  }
}
</style>
