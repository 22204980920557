<template>
  <div id="supporter">
    <div class="container py-5">
      <h3 class="m-0 pb-5">আমাদের পৃষ্ঠপোষক</h3>
      <div class="row justfy-content-center" id="supporter-content" style="justify-content: space-evenly">
        <div class="col-6 col-lg-2 col-md-2">
          <img
            src="../../../../public/assets/images/business/robi.webp"
            class="robi"
          />
        </div>
        <div class="col-6 col-lg-2 col-md-2">
          <img
            src="../../../../public/assets/images/business/undp.webp"
            class="undp"
          />
        </div>
        <div class="col-6 col-lg-2 col-md-2">
          <img src="../../../../public/assets/images/business/ac.webp" class="ac" />
        </div>
        <div class="col-6 col-lg-2 col-md-2">
          <img src="../../../../public/assets/images/women/citi.png" class="citi" />
        </div>
			<div class="col-6 col-lg-2 col-md-2 briddhi-box">
				<img src="../../../../public/assets/images/business/LogoBBriddhi.webp" class="briddhi">
			</div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  #supporter{ text-align: center; background-color: #fff;}
  #supporter h3 {font-size: 28px;}
  #supporter-content img.robi, img.undp{width: 100px;}
  #supporter-content img.citi{width: 166px;margin-right: 19px;}
  #supporter-content img.ac{width: 162px;}
  #shuttle-content img.briddhi,img.briddhi{width: 162px;}
@media screen and (max-width: 570px) {
	#supporter-content{ padding: 0 35px; }
	#supporter-content .col-md-2{ margin: 0 0 11% 0; }
	#supporter-content img.robi, img.undp {width: 74%;}
	#supporter-content img.ac {width: 95%;}
	#supporter-content img.citi {width: 95%;}
}
@media screen and (min-width: 571px) and (max-width: 767px){
	#supporter-content .col-md-2 {margin: 0% 0 10% 0;}
}

</style>