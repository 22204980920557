<template>
	<div class="row">
		<Carousel
            id="partner_carousel_work"
                :wrap-around="true"
                :items-to-show="1"
                :breakpoints="breakpoints"
                :autoplay="3000"
            >
            <Slide
                v-for="slide in slidesBangla"
                :key="slide.ID"
                @mouseover="SlideMouseOverHandler()"
                :data-slide-index="slide.ID" class="carousel-item"
            >
				<img :src="require(`@/assets/partner/banner${slide.image}.webp`)" class="card-img-top img-fluid" :alt="slide.title"/>
            </Slide>
            <template #addons>
                <Pagination
                    class="d-flex pagination-style-reso"
                />
            </template>
        </Carousel>
		<div class="row">
			<div class="banner-title">
				<h4>শাটেল পার্টনার হয়ে যান,<br> নিশ্চিত ইনকাম নিয়ে বাড়ি যান</h4>
				<!-- <router-link class="btn btn-sm reg" to='/partner/registration'>
					সাইন আপ করুন
					<img src="/assets/images/partner/arrow_forward.png" class="pl-3">
				</router-link> -->
			</div>
			<!-- <div class="banner-login" v-if="!this.$store.state.imageInformation">
				<router-link class="btn btn-sm" to='/partner/login'>
					লগ ইন
					<img src="/assets/images/partner/arrow_forward.png" class="pl-3">
				</router-link>
			</div> -->
			<div class="banner-login" v-if="this.$store.state.imageInformation">
				<router-link class="btn btn-sm" to='/partner/profile'>
					প্রোফাইল
					<img src="../../../../public/assets/images/partner/arrow_forward.png" class="pl-3">
				</router-link>
			</div>
			<div class="banner-login" v-if="this.$store.state.type === 'Personal'">
				<router-link class="btn btn-sm" to='/partner/fleet-person-profile'>
					প্রোফাইল
					<img src="../../../../public/assets/images/partner/arrow_forward.png" class="pl-3">
				</router-link>
			</div>
			<div class="banner-login" v-if="this.$store.state.type === 'Company'">
				<router-link class="btn btn-sm" to='/partner/fleet-company-profile'>
					প্রোফাইল
					<img src="../../../../public/assets/images/partner/arrow_forward.png" class="pl-3">
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
export default {
	data() {
		return {	
			slidesBangla: [
				{
				title: 'ফ্লেক্সিবল প্ল্যান',
				bg: '#F8F5FF',
				image: '2',
				ID: 1,
				text: 'আপনার কোম্পানীর সুবিধা এবং প্রয়োজন অনুযায়ী তৈরী করুন আপনার ট্রান্সপোর্টেশন প্ল্যান।'
				},
				{
				title: 'ইউজার অনবোর্ডিং',
				image: '1',
				ID: 2,
				bg: '#F2FAF7',
				text: 'আপনার টিম মেম্বারদের জন্য রুট এবং সময় নির্ধারণ করুন।'
				},
				{
				title: 'রিলেশনশিপ ম্যানেজার',
				image: '3',
				ID: 3,
				bg: '#EFF8FE',
				text: 'আপনার যেকোন প্রয়োজনে রিলেশনশিপ ম্যানেজার এর সহায়তা নিন।'
				}
			],
		}
	},
	components: {
    Carousel,
    Slide,
    Pagination,
    // Navigation,
  },
}
</script>

<style>
#partner_carousel_work .pagination-style-reso {
    overflow: hidden;
    padding-left: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
<style scoped>

section#partner_carousel_work {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.carousel-item img{
    width: 100%;
    height: 500px;
}
.carousel-indicators li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #333;
}
	.reg{-webkit-appearance: none;}
	.banner-login{position: absolute; top: 100px; right: 20px;}
	.banner-title{ position: absolute; bottom: 50px; padding-left: 50px;;}
	.banner-login a{ font-size: 16px; text-transform: uppercase; color: #fff; background-color: #262C66; padding: 1px 20px; }
	.banner-title h4{ color: #fff; font-weight: bold; }
	.banner-title a{ font-size: 16px; padding: 0.5% 1% 0.5% 1%; margin: 1% 0 0 0; text-transform: uppercase; color: #fff; background-color: #262C66; }
	.banner-title img, .banner-login img {width: auto;}
@media screen and (max-width: 570px) {
	.carousel-item img{ width: 100%; height: 350px; }
	.banner-title a{ font-size: 12px; }
	.banner-title h4{ font-size: 15px; }
	.banner-login a { font-size: 12px; padding: 2px 15px; }
	.banner-title{ position: absolute; bottom: 25px; padding-left: 25px;}
}

@media screen and (min-width: 768px) and (max-width: 1366px){
	#shuttle-support-partner{display: none;}
}
@media screen and (min-width: 1366px){
	.carousel-item img{
		height: 100%;
	}
}
</style>