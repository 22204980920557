<template>
    <nav class="navbar navbar-expand-md navbar-light shadow-none" id="partner-header-work">
        <div class="container-fluid">
            <img onclick="window.location.href='/partner'" style="cursor: pointer; width: 65px"  class="navbar-brand" src="../../../../public/assets/images/partner.svg">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span><i class="bi bi-list"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto" id="menubar">
                    <li class="nav-item">
                        <a class="nav-link" href="/">Home</a>
                        <!-- <router-link class="nav-link" to='/'>Home</router-link> -->
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/business">Business</a>
                        <!-- <router-link class="nav-link" to='/business'>Business</router-link> -->
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/rental">Rental</a>
                        <!-- <router-link class="nav-link" to='/rental'>Rental</router-link> -->
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" href="/partner">Partner</a>
                        <!-- <router-link class="nav-link" to='/partner'>Partner</router-link> -->
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/shuttle-for-school">School</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.shuttlebd.com/blogs">Blog</a>
                    </li>
                </ul>
            </div>
        </div>  
    </nav>
</template>

<script>
export default {
}
</script>
<style>
#partner-header-work .navbar-toggler {
    color: #fff !important;
}

#partner-header-work .navbar-nav .nav-link {
    color: #ececec !important;
}
#partner-header-work .navbar-nav .nav-link:hover,
#partner-header-work .navbar-nav .nav-link:focus{
    color: #fff !important;
    text-shadow: 0 0 1px #fff;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff;
    background: transparent;
}
#partner-header-work .navbar-nav .nav-link.active {
    border-radius: 5px;
}
</style>
<style scoped>
#app nav ul li {
    padding-right: 31px;
}
</style>