<template>
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Terms & Condition</title>
</head>

<body>
    <section style="padding: 0 12%">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title" style="text-align: center;">
                       
                        <h1 style="padding: 0 14%"><strong>MOBILE APP TERMS &amp; CONDITIONS TEMPLATE</strong></h1>
                        <p style="text-transform: uppercase"><strong>http://www.shuttlebd.com/</strong></p>
                        <p><span style="font-weight: 400; color: darkgray">Last updated [December, 2020]</span></p>
                        <p><strong><em>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY</em></strong></p>
                       
                    </div>
                    <div class="content">
                        <h1><strong>AGREEMENT TO TERMS</strong></h1>
                        <p><span style="font-weight: 400;">These Terms and Conditions constitute a legally binding
                                agreement made between you,
                                whether personally or on behalf of an entity (&ldquo;you&rdquo;) and [business entity
                                name]</span> <span style="font-weight: 400;">(&ldquo;we,&rdquo; &ldquo;us&rdquo; or
                                &ldquo;our&rdquo;), concerning your access to
                                and use of the Shuttle App as well as any other media form, media channel, mobile App or
                                mobile application
                                related, linked, or otherwise connected thereto (collectively, the &ldquo;App&rdquo;).
                                You agree that by
                                accessing the App, you have read, understood, and agree to be bound by all of these
                                Terms and Conditions Use. IF
                                YOU DO NOT AGREE WITH ALL OF THESE TERMS and CONDITIONS, THEN YOU ARE EXPRESSLY
                                PROHIBITED FROM USING THE APP
                                AND YOU MUST DISCONTINUE USE IMMEDIATELY.&nbsp;&nbsp;</span></p>
                       
                        <p><span style="font-weight: 400;">Supplemental terms and conditions or documents that may be
                                posted on the App from
                                time to time are hereby expressly incorporated herein by reference. We reserve the
                                right, in our sole
                                discretion, to make changes or modifications to these Terms and Conditions at any time
                                and for any reason. We
                                will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of
                                these Terms and Conditions
                                and you waive any right to receive specific notice of each such change. It is your
                                responsibility to
                                periodically review these Terms and Conditions to stay informed of updates. You will be
                                subject to, and will be
                                deemed to have been made aware of and to have accepted, the changes in any revised Terms
                                and Conditions by your
                                continued use of the App after the date such revised Terms are posted.&nbsp;</span></p>
                       
                        <p><span style="font-weight: 400;">The information provided on the App is not intended for
                                distribution to or use by any
                                person or entity in any jurisdiction or country where such distribution or use would be
                                contrary to law or
                                regulation or which would subject us to any registration requirement within such
                                jurisdiction or country.
                                Accordingly, those persons who choose to access the App from other locations do so on
                                their own initiative and
                                are solely responsible for compliance with local laws, if and to the extent local laws
                                are applicable.</span>
                        </p>
                        <p><br /><br /></p>
                        <h1><strong>INTELLECTUAL PROPERTY RIGHTS</strong></h1>
                        <p><span style="font-weight: 400;">Unless otherwise indicated, the App is our proprietary
                                property and all source code,
                                databases, functionality, software, App designs, text, photographs, and
                                graphics on the App
                                (collectively, the &ldquo;Content&rdquo;) and the trademarks, service marks, and logos
                                contained therein (the
                                &ldquo;Marks&rdquo;) are owned or controlled by us or licensed to us, and are protected
                                by copyright and
                                trademark laws and various other intellectual property rights and unfair competition
                                laws of the United States,
                                foreign jurisdictions, and international conventions. The Content and the Marks are
                                provided on the App
                                &ldquo;AS IS&rdquo; for your information and personal use only. Except as expressly
                                provided in these Terms of
                                Use, no part of the App and no Content or Marks may be copied, reproduced, aggregated,
                                republished, uploaded,
                                posted, publicly displayed, encoded, translated, transmitted, distributed, sold,
                                licensed, or otherwise
                                exploited for any commercial purpose whatsoever, without our express prior written
                                permission.</span></p>
                       
                        <p><span style="font-weight: 400;">Provided that you are eligible to use the App, you are
                                granted a limited license to
                                access and use the App and to download or print a copy of any portion of the Content to
                                which you have properly
                                gained access solely for your personal, non-commercial use. We reserve all rights not
                                expressly granted to you
                                in and to the App, Content and the Marks.</span></p>
                       
                        <h1><strong>USER REPRESENTATIONS</strong></h1>
                        <p><span style="font-weight: 400;">By using the App, you represent and warrant that: [(1) all
                                registration information
                                you submit will be true, accurate, current, and complete;</span> <span
                                style="font-weight: 400;">(2) you will
                                maintain the accuracy of such information and promptly update such registration
                                information as
                                necessary;]</span> <span style="font-weight: 400;">(3) you have the legal capacity and
                                you agree to comply with
                                these Terms of Use; [(4) you are not under the age of 13;] (5) not a minor in the
                                jurisdiction in which you
                                reside[, or if a minor, you have received parental permission to use the App]; (6) you
                                will not access the App
                                through automated or non-human means, whether through a bot, script or otherwise; (7)
                                you will not use the App
                                for any illegal or unauthorized purpose; and (8) your use of the App will not violate
                                any applicable law or
                                regulation.</span></p>
                       
                        <p><span style="font-weight: 400;">If you provide any information that is untrue, inaccurate,
                                not current, or
                                incomplete, we have the right to suspend or terminate your account and refuse any and
                                all current or future use
                                of the App (or any portion thereof).&nbsp;</span></p>
                       
                        <h1><strong>USER REGISTRATION</strong></h1>
                        <p><span style="font-weight: 400;">You may be required to register with the App. You agree be responsible for all use of your account. We
                                reserve the right to remove,
                                reclaim, or change a username you select if we determine, in our sole discretion, that
                                such username is
                                inappropriate, obscene, or otherwise objectionable.</span></p>
                        <p><span style="font-weight: 400;">&nbsp;</span></p>
                        <h1><strong>PROHIBITED ACTIVITIES</strong></h1>
                        <p><span style="font-weight: 400;">You may not access or use the App for any purpose other than
                                that for which we make
                                the App available. The App may not be used in connection with any commercial endeavors
                                except those that are
                                specifically endorsed or approved by us.&nbsp;</span></p>
                       
                        <p><span style="font-weight: 400;">As a user of the App, you agree not to:</span></p>
                       
                        <ol>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">systematically retrieve data
                                    or other content from the
                                    App to create or compile, directly or indirectly, a collection, compilation,
                                    database, or directory without
                                    written permission from us.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">make any unauthorized use of
                                    the App, including
                                    collecting usernames and/or email addresses of users by electronic or other means
                                    for the purpose of sending
                                    unsolicited email, or creating user accounts by automated means or under false
                                    pretenses.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">use the App to advertise or
                                    offer to sell goods and
                                    services.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">circumvent, disable, or
                                    otherwise interfere with
                                    security-related features of the App, including features that prevent or restrict
                                    the use or copying of any
                                    Content or enforce limitations on the use of the App and/or the Content contained
                                    therein.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">engage in unauthorized framing
                                    of or linking to the
                                    App.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">make improper use of our
                                    support services or submit
                                    false reports of abuse or misconduct.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">interfere with, disrupt, or
                                    create an undue burden on
                                    the App or the networks or services connected to the App.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">attempt to impersonate another
                                    user or person or use
                                    the username of another user.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">sell or otherwise transfer
                                    your profile.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">use any information obtained
                                    from the App in order to
                                    harass, abuse, or harm another person.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">use the App as part of any
                                    effort to compete with us
                                    or otherwise use the App and/or the Content for any revenue-generating endeavor or
                                    commercial
                                    enterprise.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">decipher, decompile,
                                    disassemble, or reverse engineer
                                    any of the software comprising or in any way making up a part of the App.</span>
                            </li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">attempt to bypass any measures
                                    of the App designed to
                                    prevent or restrict access to the App, or any portion of the App.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">harass, annoy, intimidate, or
                                    threaten any of our
                                    employees or agents engaged in providing any portion of the App to you.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">delete the copyright or other
                                    proprietary rights
                                    notice from any Content.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">copy or adapt the App&rsquo;s
                                    software, including but
                                    not limited to Flash, PHP, HTML, JavaScript, or other code.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">upload or transmit (or attempt
                                    to upload or to
                                    transmit) viruses, Trojan horses, or other material, including excessive use of
                                    capital letters and spamming
                                    (continuous posting of repetitive text), that interferes with any party&rsquo;s
                                    uninterrupted use and
                                    enjoyment of the App or modifies, impairs, disrupts, alters, or interferes with the
                                    use, features,
                                    functions, operation, or maintenance of the App.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">disparage, tarnish, or
                                    otherwise harm, in our opinion,
                                    us and/or the App.</span></li>
                            <li style="font-weight: 400;"><span style="font-weight: 400;">use the App in a manner
                                    inconsistent with any
                                    applicable laws or regulations.</span></li>
                        </ol>
                        <p><span style="font-weight: 400;">&nbsp;</span></p>
                        <h1><strong>GUIDELINES FOR REVIEWS</strong></h1>
                        <p><span style="font-weight: 400;">We may provide you areas on the App to leave reviews or
                                ratings. When posting a
                                review, you must comply with the following criteria: (1) you should have firsthand
                                experience; (2) your reviews should not contain offensive profanity,
                                or abusive, racist,
                                offensive, or hate language; (3) your reviews should not contain discriminatory
                                references based on religion,
                                race, gender, national origin, age, marital status, sexual orientation, or disability;
                                (4) your reviews should
                                not contain references to illegal activity; (5) you should not be affiliated with
                                competitors if posting
                                negative reviews; (6) you should not make any conclusions as to the legality of conduct;
                                (7) you may not post
                                any false or misleading statements; and (8) you may not organize a campaign encouraging
                                others to post reviews,
                                whether positive or negative.&nbsp;</span></p>
                       
                        <p><span style="font-weight: 400;">We may accept, reject, or remove reviews in our sole
                                discretion. We have absolutely
                                no obligation to screen reviews or to delete reviews, even if anyone considers reviews
                                objectionable or
                                inaccurate. Reviews are not endorsed by us, and do not necessarily represent our
                                opinions or the views of any of
                                our affiliates or partners. We do not assume liability for any review or for any claims,
                                liabilities, or losses
                                resulting from any review. By posting a review, you hereby grant to us a perpetual,
                                non-exclusive, worldwide,
                                royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce,
                                modify, translate,
                                transmit by any means, display, perform, and/or distribute all content relating to
                                reviews.</span></p>
                        <p><span style="font-weight: 400;">&nbsp;</span><strong>&nbsp;</strong></p>
                        <h1><strong>MOBILE APPLICATION LICENSE</strong></h1>
                        <h2><strong>Use License</strong></h2>
                        <p><span style="font-weight: 400;">If you access the App via a mobile application, then we grant
                                you a revocable,
                                non-exclusive, non-transferable, limited right to install and use the mobile application
                                on wireless electronic
                                devices owned or controlled by you, and to access and use the mobile application on such
                                devices strictly in
                                accordance with the terms and conditions of this mobile application license contained in
                                these Terms of Use. You
                                shall not: (1) decompile, reverse engineer, disassemble, attempt to derive the source
                                code of, or decrypt the
                                application; (2) make any modification, adaptation, improvement, enhancement,
                                translation, or derivative work
                                from the application; (3) violate any applicable laws, rules, or regulations in
                                connection with your access or
                                use of the application; (4) remove, alter, or obscure any proprietary notice (including
                                any notice of copyright
                                or trademark) posted by us or the licensors of the application; (5) use the application
                                for any revenue
                                generating endeavor, commercial enterprise, or other purpose for which it is not
                                designed or intended; (6) make
                                the application available over a network or other environment permitting access or use
                                by multiple devices or
                                users at the same time; (7) use the application for creating a product, service, or
                                software that is, directly
                                or indirectly, competitive with or in any way a substitute for the application; (8) use
                                the application to send
                                automated queries to any website or to send any unsolicited commercial e-mail; or (9)
                                use any proprietary
                                information or any of our interfaces or our other intellectual property in the design,
                                development, manufacture,
                                licensing, or distribution of any applications, accessories, or devices for use with the
                                application.</span></p>
                       
                        <h2><strong>Apple and Android Devices</strong></h2>
                        <p><span style="font-weight: 400;">The following terms apply when you use a mobile application
                                obtained from either the
                                Apple Store or Google Play (each an &ldquo;App Distributor&rdquo;) to access the App:
                                (1) the license granted to
                                you for our mobile application is limited to a non-transferable license to use the
                                application on a device that
                                utilizes the Apple iOS or Android operating systems, as applicable, and in accordance
                                with the usage rules set
                                forth in the applicable App Distributor&rsquo;s terms of service; (2) we are responsible
                                for providing any
                                maintenance and support services with respect to the mobile application as specified in
                                the terms and conditions
                                of this mobile application license contained in these Terms of Use or as otherwise
                                required under applicable
                                law, and you acknowledge that each App Distributor has no obligation whatsoever to
                                furnish any maintenance and
                                support services with respect to the mobile application; (3) in the event of any failure
                                of the mobile
                                application to conform to any applicable warranty, you may notify the applicable App
                                Distributor, and the App
                                Distributor, in accordance with its terms and policies, may refund the purchase price,
                                if any, paid for the
                                mobile application, and to the maximum extent permitted by applicable law, the App
                                Distributor will have no
                                other warranty obligation whatsoever with respect to the mobile application; (5) you must comply with applicable
                                third-party terms of
                                agreement when using the mobile application, e.g., if you have a VoIP application, then
                                you must not be in
                                violation of their wireless data service agreement when using the mobile application;
                                and (6) you acknowledge
                                and agree that the App Distributors are third-party beneficiaries of the terms and
                                conditions in this mobile
                                application license contained in these Terms of Use, and that each App Distributor will
                                have the right (and will
                                be deemed to have accepted the right) to enforce the terms and conditions in this mobile
                                application license
                                contained in these Terms of Use against you as a third-party beneficiary
                                thereof.&nbsp;&nbsp;</span></p>
                        <p><span style="font-weight: 400;">&nbsp;</span></p>
                       
                        <h1><strong>APP MANAGEMENT</strong></h1>
                        <p><span style="font-weight: 400;">We reserve the right, but not the obligation, to:&nbsp;(1)
                                monitor the App for
                                violations of these Terms of Use; (2) take appropriate legal action against anyone who,
                                in our sole discretion,
                                violates the law or these Terms of Use, including without limitation, reporting such
                                user to law enforcement
                                authorities; (3) in our sole discretion and without limitation, refuse, restrict access
                                to, limit the
                                availability of, or disable (to the extent technologically feasible) any of your
                                Contributions or any portion
                                thereof; (4) in&nbsp;our sole discretion and without limitation, notice, or liability,
                                to remove from the App or
                                otherwise disable all files and content that are excessive in size or are in any way
                                burdensome to our systems;
                                and (5) otherwise manage the App in a manner designed to protect our rights and property
                                and to facilitate the
                                proper functioning of the App.</span></p>
                       
                        <p><span style="font-weight: 400;">&nbsp;</span></p>
                        <h1><strong>COPYRIGHT INFRINGEMENTS</strong></h1>
                        <p><span style="font-weight: 400;">We respect the intellectual property rights of others. If you
                                believe that any
                                material available on or through the App infringes upon any copyright you own or
                                control, please immediately
                                notify us using the contact information provided below (a &ldquo;Notification&rdquo;). A
                                copy of your
                                Notification will be sent to the person who posted or stored the material addressed in
                                the Notification. Please
                                be advised that pursuant to federal law you may be held liable for damages if you make
                                material
                                misrepresentations in a Notification. Thus, if you are not sure that material located on
                                or linked to by the App
                                infringes your copyright, you should consider first contacting an attorney.]</span></p>
                       
                        <h1><strong>TERM AND TERMINATION</strong></h1>
                        <p><span style="font-weight: 400;">These Terms of Use shall remain in full force and effect
                                while you use the App.
                                WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN
                                OUR SOLE DISCRETION AND
                                WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE APP (INCLUDING BLOCKING
                                CERTAIN IP ADDRESSES), TO ANY
                                PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                                REPRESENTATION, WARRANTY,
                                OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE
                                MAY TERMINATE YOUR USE OR
                                PARTICIPATION IN THE APP OR DELETE [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT
                                YOU POSTED AT ANY TIME,
                                WITHOUT WARNING, IN OUR SOLE DISCRETION.&nbsp;</span></p>
                       
                        <p><span style="font-weight: 400;">If we terminate or suspend your account for any reason, you
                                are prohibited from
                                registering and creating a new account under your name, a fake or borrowed name, or the
                                name of any third party,
                                even if you may be acting on behalf of the third party. In addition to terminating or
                                suspending your account,
                                we reserve the right to take appropriate legal action, including without limitation
                                pursuing civil, criminal,
                                and injunctive redress.</span></p>
                       
                        <h1><strong>MODIFICATIONS AND INTERRUPTIONS&nbsp;</strong></h1>
                        <p><span style="font-weight: 400;">We reserve the right to change, modify, or remove the
                                contents of the App at any time
                                or for any reason at our sole discretion without notice. However, we have no obligation
                                to update any
                                information on our App. We also reserve the right to modify or discontinue all or part
                                of the App without notice
                                at any time. We will not be liable to you or any third party for any modification, price
                                change, suspension, or
                                discontinuance of the App.&nbsp;</span></p>
                       
                        <p><span style="font-weight: 400;">We cannot guarantee the App will be available at all times.
                                We may experience
                                hardware, software, or other problems or need to perform maintenance related to the App,
                                resulting in
                                interruptions, delays, or errors. We reserve the right to change, revise, update,
                                suspend, discontinue, or
                                otherwise modify the App at any time or for any reason without notice to you. You agree
                                that we have no
                                liability whatsoever for any loss, damage, or inconvenience caused by your inability to
                                access or use the App
                                during any downtime or discontinuance of the App. Nothing in these Terms of Use will be
                                construed to obligate us
                                to maintain and support the App or to supply any corrections, updates, or releases in
                                connection
                                therewith.</span></p>
                       
                        <h1><strong>GOVERNING LAW&nbsp;</strong></h1>
                        <p><span style="font-weight: 400;">These Terms of Use and your use of the App are governed by
                                and construed in
                                accordance with the laws of the People&rsquo;s Republic of Bangladesh applicable to
                                agreements made and to be
                                entirely performed within the State, without regard to its conflict of law
                                principles.&nbsp;</span></p>

                        <p><span style="font-weight: 400;">&nbsp;</span></p>
                        <h1><strong>USER DATA</strong></h1>
                        <p><span style="font-weight: 400;">We will maintain certain data that you transmit to the App
                                for the purpose of
                                managing the App, as well as data relating to your use of the App. Although we perform
                                regular routine backups
                                of data, you are solely responsible for all data that you transmit or that relates to
                                any activity you have
                                undertaken using the App. You agree that we shall have no liability to you for any loss
                                or corruption of any
                                such data, and you hereby waive any right of action against us arising from any such
                                loss or corruption of such
                                data.</span></p>
                        <p><strong>&nbsp;</strong></p>
                        <h1><strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></h1>
                        <p><span style="font-weight: 400;">Visiting the App, sending us emails, and completing online
                                forms constitute
                                electronic communications. You consent to receive electronic communications, and you
                                agree that all agreements,
                                notices, disclosures, and other communications we provide to you electronically, via
                                email and on the App,
                                satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO
                                THE USE OF ELECTRONIC
                                SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
                                POLICIES, AND RECORDS
                                OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE APP.&nbsp;</span></p>
                        <p><br /><br /></p>                        
                        <h1><strong>CANCELLATION POLICY&nbsp;</strong></h1>
                        <ul>
                        <li>Rides can be cancelled any time without credits being deducted until 120 minutes prior the scheduled ride time.</li>
                        <li>Ride cancellation within 120 minutes prior the scheduled ride time will be subject to credit deduction equivalent to 50% of the ride fare.</li>
                        <li>Ride cancellation in the last 30 minutes from the trip schedule will not be allowed and any individual failing to take the ride will be charged the entire ride fare.</li>
                        <li>If Shuttle is late for more than 20 minutes and if for that reason any individual cancels her ride, Team Shuttle will investigate the issue and reimburse the credits deducted within the next 48 hours. In such cases, Shuttlers are requested to inform Team Shuttle immediately by calling the hotline 09638888868</li>
                        </ul>
                        <p><br /><br /></p>          
                        <h1><strong>CONTACT US&nbsp;</strong></h1>
                        <p><span style="font-weight: 400;">In order to resolve a complaint regarding the App or to
                                receive further information
                                regarding use of the App, please contact us at:</span><strong>&nbsp;</strong></p>
                       
                        <p><span style="font-weight: 400;">Shuttle</span></p>
                        <p><span style="font-weight: 400;">Tropical Molla Tower, 10</span><span
                                style="font-weight: 400;">th</span><span style="font-weight: 400;"> Floor, Middle Badda,
                                Dhaka, Bangladesh</span></p>
                        <p><span style="font-weight: 400;">Phone:</span></p>
                        <p><span style="font-weight: 400;">info@shuttlebd.com</span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</body>

</html>
</template>

<style scoped>

        section {
            font-style: arial
        }

        h1 {
            font-size: 30px;
        }

        p {
            font-size: 17px
        }

        b {
            font-size: 18px;
            font-weight: bold
        }
</style>