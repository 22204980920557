<template>
  <!-- <header>
    <the-header></the-header>
  </header> -->
  <!-- <div class="container"> -->
    <router-view></router-view>
  <!-- </div> -->
  <!-- <footer>
    <the-footer></the-footer>
  </footer> -->
</template>

<script>
// import TheHeaderVue from './components/TheHeader.vue';
// import TheFooterVue from './components/TheFooter.vue';

export default {
  name: 'App',
  components: {
    // TheHeader: TheHeaderVue,
    // TheFooter: TheFooterVue
  }
};
</script>

<style>
  #app {
    font-family: 'Montserrat', sans-serif;
  }
</style>
