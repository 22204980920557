import axios from 'axios';
// import config from "../config/config";

// User Auth Service axios Instance
const axiosAuthClient = axios.create({
  baseURL: 'https://rental.shuttlengine.com/api/v1/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.csrf_token,
    Authorization: localStorage.getItem('token'),
    'Access-Control-Allow-Origin': '*'
  }
});

export default axiosAuthClient;
