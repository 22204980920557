<template>
    <!-- Button trigger modal -->
<button type="button" class="btn btn-primary" id="modalBandarban" data-bs-toggle="modal" data-bs-target="#staticBackdrop" hidden>
  Launch static backdrop modal
</button>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog">
  <div class="modal-dialog modal-lg">
    
    <div class="modal-content" role="document">
      
    <div class="modal-header">
       
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
        <img src="/assets/images/coxbanner.png">
        <div class="content">
        <p><b>Destination: Dhaka-Khulna</b></p>
        <p><b>Vehicle type: Sedan (Standard)</b></p>
        <p><b>Starting from: BDT 11000 (Condition Applied)</b></p>
        
        <p><b>Terms & Conditions:</b></p>
        <ul>
            <li><b>1.</b> This rate includes all kinds of fuel, bridge, ferry tolls and driver cost. 
            </li>
            <li><b>2.</b> This rate is for both way pick & drop up to the main city only.</li>
            <li><b>3.</b> If you want to go to Upazilas/Villages from the main city then additional charges will be added upon discussion.</li>
            <li><b>4.</b> Please Call/SMS/WhatsApp to our Hotline:017*******</li>
            <li><b>5.</b> This price may increase during the Eid/Puja/Christmas time, depending on the market supply and demand.</li>
            <li><b>6.</b> 50% of total payment needs to be paid in advance as a booking charge.</li>
            
            
        </ul>
        <a href="" data-bs-toggle="modal"
              data-bs-target="#exampleModal">Book Now</a>
        </div>
    </div>
  </div>
</div>


</template>
<script>
export default {
  
}
</script>
<style scoped>
.modal-body img {
  height: auto;
  max-width: 100%;
}
.modal-content {
  border-radius: 15px;
}
.modal-content img {
  margin-top: -49px;
}
.modal-header .btn-close {
  z-index: 9;
}
.modal-body {
  padding: 0;
}
.content {
  padding: 15px;
}
.content a {
  background-color: #6c3a97;
  color: #fff;
  padding: 12px 50px;
  text-align: center;
  /* display: block; */
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 20px;
  margin: 0 auto;
  display: block;
  width: 220px;
  margin: auto;
}
.content ul li {
  list-style: none;
  padding-bottom: 10px;
}
</style>
