<template>
  <div class="col-lg-5 col-md-12 col-sm-12 col-12">
    <div class="row d-flex flex-column my-4">
      <div class="col">
        <h1 class="h2 mb-3">
          {{  $t("message.tab.business.heading", {}, { locale: LoadLangShow }) }}
        </h1>
        <p class="mb-4 text-muted">
          {{  $t("message.tab.business.body", {}, { locale: LoadLangShow }) }}
        </p>
      </div>
    </div>
    <div class="row ps-3">
      <div class="col-lg-8 col-12 ps-0">
        <button
          class="btn w-100 tabBtn text-capitalize shadow-none"
          data-bs-toggle="modal"
          data-bs-target="#b2cModal"
          @click="getDirection()"
        >
          {{  $t("message.tab.business.button", {}, { locale: LoadLangShow }) }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-7 col-md-12 col-sm-12 text-center col-12 image-container">
    <div class="position-relative">
      <span class="position-absolute bottom-0 end-0 translate-middle-x">
        <img
          src="@/assets/rectangle.png"
          alt=""
          class="img-fluid bg-transparent d-lg-flex d-md-none d-none"
        />
      </span>
      <span class="position-absolute top-0 start-0 custom-business-rectangle">
        <img
          src="@/assets/rectangle.png"
          alt=""
          class="img-fluid bg-transparent d-lg-flex d-md-none d-none"
        />
      </span>
      <div class="card border-0 rounded bg-transparent p-0">
        <div class="card-body p-lg-3 p-0">
          <img
            src="@/assets/b2btab.webp"
            alt=""
            class="img-fluid border-0 rounded"
          />
        </div>
      </div>
      <div class="row text-center d-lg-none d-none">
        <div
          class="col-lg-5 col-md-5 col-sm-5 offset-md-5 offset-sm-5 col-5 ps-lg-0 d-flex text-center offset-lg-6"
        >
          <div class="header-dots"></div>
          <div class="header-dots active"></div>
          <div class="header-dots"></div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
    <modal-b2-b-vue></modal-b2-b-vue>
  </teleport>
</template>
<script>
import ModalB2BVue from '@/components/modal/ModalB2B.vue';

export default {
  name: 'BusinessTab',
  components: {
    ModalB2BVue
  },
  computed:{
    LoadLangShow(){
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  methods:{
    getDirection(){
      this.$emit('direction', 'b2b');
    }
  }
};
</script>
<style scoped>
.translate-middle-x {
  transform: translateX(-50%) !important;
  margin-bottom: -20px;
  margin-right: -20px;
}
.translate-middle-y {
  transform: translateX(40%) !important;
  margin-top: -20px;
}
.image-container {
  margin-top: -34px;
}
@media only screen and (min-device-width: 280px) and (max-device-width: 567px) {
  .translate-middle-x {
    display: none;
  }
  .translate-middle-y {
    display: none;
  }
  .card-body {
    padding: 7px !important;
  }
  .image-container {
    margin-top: 20px;
    margin-right: 0px;
  }
}
@media only screen and (min-device-width: 568px) and (max-device-width: 767px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(-2%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: 20px;
    margin-right: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 990px) {
  .translate-middle-x {
    transform: translateX(-60%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(-5%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: 20px;
    margin-right: 0px;
  }
}
@media only screen and (min-device-width: 991px) and (max-device-width: 1023px) {
  .translate-middle-x {
    transform: translateX(-2%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    margin-right: -15px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1120px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: 2px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    margin-right: -44px;
  }
}
@media only screen and (min-device-width: 1120px) and (max-device-width: 1320px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: 2px;
  }
  .translate-middle-y {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    margin-right: -44px;
  }
}
/* @media only screen and (min-device-width: 1201px) and (max-device-width: 1400px) {
  .translate-middle-x {
    transform: translateX(-2%) !important;
    margin: -5px;
  }
  .translate-middle-y {
    transform: translateX(30%) !important;
    margin: 0px;
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    margin-top: -35px;
    text-align: end !important;
    margin-right: -2.8%;
  }
} */
@media only screen and (min-device-width: 1321px) {
  .translate-middle-x {
    transform: translateX(0%) !important;
    margin: 0px;
  }
  .custom-business-rectangle {
    transform: translate(0);
  }
  .card-body {
    padding: 14px !important;
  }
  .image-container {
    /* width: 35rem; */
    margin-top: -35px;
    text-align: end;
    margin-right: -2.8%;
  }
}
</style>
