<template>
  <section class="registration-panel pt-5">
    <!-- signup Form -->
    <div
      class="modal fade"
      id="signupModalB2B"
      tabindex="-1"
      aria-labelledby="B2BModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header" style="background-color: rgba(218, 218, 218, 0.22)">
            <button
              type="button"
              class="btn-close btn-sm"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="close2()"
            ></button>
          </div>
          <div
            :class="
              title
                ? 'modal-body border-0 ps-0 pt-0 business'
                : 'modal-body border-0 ps-0 pt-0 myself'
            "
            id="signupModalFormShow2"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div
                  :class="
                    title
                      ? 'col-12 col-md-8-half col-lg-3-half pt-2 business'
                      : 'col-12 col-md-8-half col-lg-3-half pt-2 myself'
                  "
                >
                  <div class="header pb-2 px-3">
                    <p>
                      {{ $t("message.signupForm.title", {}, { locale: LoadLangShow }) }}
                    </p>
                  </div>
                  <div class="title px-3 pb-2">
                    <div class="row">
                      <div class="col-6 col-md-6 col-lg-6">
                        <button
                          type="button"
                          :class="title ? 'btn' : 'btn active'"
                          id="daily"
                          @click="dailyRide()"
                        >
                          {{
                            $t("message.signupForm.option1", {}, { locale: LoadLangShow })
                          }}
                        </button>
                      </div>
                      <div class="col-6 col-md-6 col-lg-6">
                        <button
                          type="button"
                          :class="title ? 'btn active' : 'btn'"
                          id="business"
                          @click="businessRide()"
                        >
                          {{
                            $t("message.signupForm.option2", {}, { locale: LoadLangShow })
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- myself form -->
                  <div class="login-content text-left px-3 pt-4 pb-2" v-if="!title">
                    <form @submit.prevent="dailyRideForm2()">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="name">{{
                            $t("message.signupForm.name", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="text"
                            v-model="name"
                            name="name"
                            id="pName"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="email" class="pt-3">{{
                            $t("message.signupForm.email", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="email"
                            v-model="email"
                            name="email"
                            id="pEmail"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="mobile" class="pt-3">{{
                            $t("message.signupForm.mobile", {}, { locale: LoadLangShow })
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="mobile"
                              name="mobile"
                              id="pMobile"
                              class="form-control mr-2"
                              @change="validateData"
                              autocomplete="address-level4"
                              required
                            />
                          </div>
                          <span
                            style="font-size: 13px; padding-left: 10px"
                            v-if="mobileValidateMsg"
                            class="text-danger"
                            >{{ mobileValidateMsg }}</span
                          >
                          <img
                            v-if="mobileValidateMsg"
                            class="pt-2"
                            width="25px"
                            height="25px"
                            src="/assets/images/partner/error.png"
                            alt=""
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="mobile" class="pt-3">{{
                            $t(
                              "message.signupForm.institution",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="institution"
                              name="name"
                              id="pName"
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="city" class="pt-3">{{
                            $t(
                              "message.signupForm.pickupLocation",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="pickup"
                            id="Pickup Location"
                            placeholder=""
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="city" class="pt-3">{{
                            $t(
                              "message.signupForm.dropOffArea",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="dropoff"
                            id="Dropoff Location"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 px-3 text-center login-loader">
                          <span class="text-danger" v-if="error">{{ error }}</span>
                          <!-- <div v-if="loading" class="loader text-center"></div> -->
                        </div>
                      </div>
                      <div class="form-group row pt-4">
                        <div class="col-md-12 text-center">
                          <button type="submit" class="btn btn-sm px-4 py-2 submit">
                            {{
                              $t(
                                "message.signupForm.button",
                                {},
                                { locale: LoadLangShow }
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- organization form -->
                  <div
                    class="login-content text-left px-3 pt-4 pb-2"
                    v-if="title == 'business'"
                  >
                    <form @submit.prevent="businessRideForm2()">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="name">{{
                            $t("message.signupForm.company", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="text"
                            v-model="companyname"
                            name="name"
                            id="name"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="mobile" class="pt-3">{{
                            $t(
                              "message.signupForm.employeeNo",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="noofemployees"
                              name="mobile"
                              id="pMobile"
                              class="form-control mr-2"
                              @change="validateData"
                              autocomplete="address-level4"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="name" class="pt-3">{{
                            $t(
                              "message.signupForm.contactName",
                              {},
                              { locale: LoadLangShow }
                            )
                          }}</label>
                          <input
                            type="text"
                            v-model="contactname"
                            name="name"
                            id="name"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label for="email" class="pt-3">{{
                            $t("message.signupForm.email", {}, { locale: LoadLangShow })
                          }}</label>
                          <input
                            type="email"
                            v-model="organizationemail"
                            name="email"
                            id=""
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="mobile" class="pt-3">{{
                            $t("message.signupForm.mobile", {}, { locale: LoadLangShow })
                          }}</label>
                          <div class="d-flex">
                            <input
                              type="text"
                              v-model="b2bMobile"
                              name="mobile"
                              id="pMobile"
                              class="form-control mr-2"
                              @change="validateData"
                              autocomplete="address-level4"
                              required
                            />
                          </div>
                          <span
                            style="font-size: 13px; padding-left: 10px"
                            v-if="mobileValidateMsg"
                            class="text-danger"
                            >{{ mobileValidateMsg }}</span
                          >
                          <img
                            v-if="mobileValidateMsg"
                            class="pt-2"
                            width="25px"
                            height="25px"
                            src="/assets/images/partner/error.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 px-3 text-center login-loader">
                          <span class="text-danger" v-if="error">{{ error }}</span>
                          <!-- <div v-if="loading" class="loader text-center"></div> -->
                        </div>
                      </div>
                      <div class="form-group row pt-4">
                        <div class="col-md-12 text-center">
                          <button type="submit" class="btn btn-sm px-4 py-2 submit">
                            {{
                              $t(
                                "message.signupForm.button",
                                {},
                                { locale: LoadLangShow }
                              )
                            }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- thank you modal body -->
          <div class="modal-body" id="thankYouModalBody2" style="display: none">
            <div class="row">
              <div class="col-md-12 text-center" id="icon">
                <h1><i class="fa fa-check-circle"></i></h1>
              </div>
              <div class="col-md-12 text-center" id="notification">
                <p class="px-3">
                  {{ $t("message.modal.thankYou.body", {}, { locale: LoadLangShow }) }}
                </p>
                <button
                  type="button"
                  class="btn btn-danger btn-sm mb-3"
                  id="submit"
                  data-bs-dismiss="modal"
                  aria-label="close"
                  @click="close2()"
                >
                  {{ $t("message.modal.thankYou.button", {}, { locale: LoadLangShow }) }}
                </button>
              </div>
            </div>
          </div>
          <!-- thank you end -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  props: ["signupTitle", "signupTitle2"],
  data() {
    return {
      name: "",
      mobile: "",
      mobileValidateMsg: "",
      email: "",
      institution: "",
      pickup: "",
      dropoff: "",
      city: 1,
      password: "",
      daily: false,
      business: false,
      areaList: [],
      error: "",
      loading: "",
      companyname: "",
      b2bMobile: "",
      contactname: "",
      noofemployees: "",
      organizationemail: "",
      isModalOpen: false,
      title: this.signupTitle,
      tTitle: "",
    };
  },
  computed: {
    LoadLangShow() {
      return this.$store.state.LoadLang ? this.$store.state.LoadLang : "en";
    },
  },
  created() {
    // this.emitter.on('signUpTitle', t => {
    //         this.tTitle = t;
    // })
  },
  mounted() {
    $(document).ready(function () {
      this.isModalOpen = true;
      var elements = document.getElementsByTagName("input");
      for (let i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function (e) {
          e.target.setCustomValidity("");
          if (!e.target.validity.valid) {
            e.target.setCustomValidity("অনুগ্রহ করে এই ঘরটি পুরন করুন।");
          }
          elements[i].oninput = function (e) {
            e.target.setCustomValidity("");
          };
        };
      }
    });
  },
  methods: {
    dailyRideForm2: function () {
      const b2bData = {
        name: this.name,
        cusemail: this.email,
        institution: this.institution,
        phone: this.mobile,
        pickup: this.pickup,
        dropoff: this.dropoff,
      };
      if (this.mobile.length < 11 || this.mobile.length > 11) {
        this.mobileValidateMsg = "Incorrect phone number";
      } else {
        this.mobileValidateMsg = "";
        axios
          .post("https://api.shuttlebd.com/women-register", b2bData)
          .then((response) => {
            console.log(response);
            this.isModalOpen = true;
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          })
          .catch((error) => {
            console.log("error =======", error);
            this.isModalOpen = true;
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          });
      }
    },
    dailyRide: function () {
      // this.daily = true;
      // this.business = false;
      this.title = "";
      $(".title #business").removeClass("active");
      $(".title #daily").addClass("active");
      this.isModalOpen = true;
      this.b2bMobile = "";
      this.companyname = "";
      this.noofemployees = "";
      this.contactname = "";
      this.organizationemail = "";
    },
    businessRide: function () {
      // this.business = true;
      // this.daily = false;
      this.title = "business";
      $(".title #daily").removeClass("active");
      $(".title #business").addClass("active");
      this.isModalOpen = true;
      this.mobile = "";
      this.name = "";
      this.email = "";
      this.institution = "";
      this.pickup = "";
      this.dropoff = "";
    },
    businessRideForm2: function () {
      const companydata = {
        company_name: this.companyname,
        contact_name: this.contactname,
        phone: this.b2bMobile,
        email: this.organizationemail,
        no_of_employees: this.noofemployees,
      };
      if (this.b2bMobile.length < 11 || this.b2bMobile.length > 11) {
        this.mobileValidateMsg = "Incorrect phone number";
      } else {
        this.mobileValidateMsg = "";
        axios
          .post("https://api.shuttlebd.com/signup", companydata)
          .then((response) => {
            console.log(response);
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          })
          .catch((error) => {
            console.log("error =======", error);
            this.isModalOpen = true;
            $("#thankYouModalBody2").css("display", "block");
            $("#signupModalFormShow2").css("display", "none");
          });
      }
    },
    close2() {
      if (this.title == "business" && this.signupTitle2) {
        this.title = "";
      } else {
        this.title = "business";
      }
      $("#thankYouModalBody2").css("display", "none");
      $("#signupModalFormShow2").css("display", "block");
      this.mobile = "";
      this.companyname = "";
      this.noofemployees = "";
      this.contactname = "";
      this.organizationemail = "";
      this.name = "";
      this.email = "";
      this.b2bMobile = "";
      this.institution = "";
      this.pickup = "";
      this.dropoff = "";
    },
  },
};
</script>

<style scoped>
input:focus,
select:focus {
  border: 1px solid lightgray;
  box-shadow: none;
}
.title button {
  font-size: 18px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #6c3a97;
}
/* .title button:active,button:focus,button:visited{ color: #fff; background-color: #6c3a97; } */
.active {
  color: #fff;
  background-color: #6c3a97 !important;
  border: 1px solid #6c3a97;
}
label {
  color: #000000;
}
.header p:first-child {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}
.header p:nth-child(2) {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000;
}
label::after {
  content: " *";
  font-size: 18px;
  color: red;
}
.modal-header {
  border-bottom: none;
}
.submit {
  background-color: #6c3a97;
  color: #fff;
}

.mobile-validate-msg {
  font-size: 14px;
}
.mobile-validate-msg img {
  width: 20px;
}
.modal {
  font-family: "Montserrat", sans-serif;
}
.modal-header {
  border-bottom: none;
}
.modal-content {
  /* background-color: #242d66; */
  color: #5d5d5e;
  /*font-family: sans-serif;*/
}
.modal-body {
  padding: 0px;
}
.modal-title h4 {
  font-size: 20px;
  padding-top: 8%;
}
.modal-title p {
  padding: 0 10% 0 10%;
  font-weight: normal;
}
.submit-btn {
  background-color: #6c3a97;
  color: #fff;
  font-weight: normal;
  border-radius: 8px;
  border: none;
  text-transform: capitalize;
  padding: 5px;
}
.form-control {
  font-size: 0.8rem !important;
}
.modal-body.border-0.ps-0.pt-0.myself {
  background-color: rgba(218, 218, 218, 0.22);
}
.modal-body.border-0.ps-0.pt-0.business {
  background-color: rgba(218, 218, 218, 0.22);
}
</style>
