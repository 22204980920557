<template>
  <section class="header">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-6 logo-part">
          <div class="logo">
            <nav class="navbar navbar-expand-lg navbar-expand-sm navbar-light bg-white">
              <div class="container">
                <a href="https://www.shuttlebd.com/"
                  ><img src="/assets/images/logo.png"
                /></a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/business">Business</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/partner">Partner</a>
                    </li>
                     <li class="nav-item active" style="margin-right: 10px;">
                      <a class="nav-link active" href="/rental">Rental</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/shuttle-for-school">School</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://www.shuttlebd.com/blogs">Blog</a>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div
            class="right-part"
            style="background-image: url('../assets/images/rental.webp')"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* navbar css start from here */
.navbar-toggler:focus {
  box-shadow: none !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  font-weight: 500;
  color: #111632;
  font-size: 16px;
  padding-right: 25px;
}
a.nav-link.active {
  border-bottom: 3px solid #000;
  width: 70px;
  text-align: center;
  border-radius: 3px;
}
.right-part {
  /* background-image: url("../assets/images/rental.png"); */
  height: 600px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.logo img {
  /* height: 65px; */
  width: 65px;
  margin-left: -20px;
}
.navbar-expand-lg {
  font-weight: 700;
}
.navbar-nav {
  margin-left: 70px;
  color: #000;
  font-weight: 500;
}
@media only screen and (min-width: 1824px) {
  /* .navbar-expand-lg {
    margin-left: 48%;
  } */
  .right-part {
    height: 660px;
  }
}
/* .row>* {
    padding-right: 0px !important;
  } */

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .bg-white {
    background-color: transparent !important;
  }
  .navbar-expand-lg {
    margin-left: 2px;
  }
  .navbar-nav {
    margin-left: 50px;
  }
  .right-part {
    height: 394px;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .bg-white {
    background-color: transparent !important;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .bg-white {
    background-color: transparent !important;
  }
  .navbar-expand-lg {
    margin-left: 1px;
  }
  .navbar-nav {
    margin-left: 3px;
    position: absolute;
    opacity: 9;
    color: #000;
    font-weight: 800;
  }
  .right-part {
    height: 330px;
    margin-top: -80px;
    /*  margin-right: -12px;  */
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
    font-weight: 400;
    padding-top: 18px;
  }
  .navbar-collapse {
    position: fixed;
    top: 74px;
    left: 0;
    margin-top: -75px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 78%;
    height: 100%;
    background-color: #904dd6;
    opacity: 1;
    z-index: 999999;
  }

  .navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
    /* z-index: 9; */
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 500ms ease-in-out;
  }
}
</style>
<script></script>
