<template>
  <!-- partner faq html part start from here -->
  <section class="partner-faq">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="faq-border"></div>

          <h2 class="text-center">FAQ</h2> 
          <p class="text-center">
            Have a burning question? See our FAQ section to find out if someone
            has already found their answer to a similar query.
          </p>
          <!-- </div> -->
          <!-- <div class="col-md-6 col-lg-6"> --> 
         <div class="accordion" id="accordionExample">
            <div class="accordion-item" v-for="faq in faqlist" :key="faq.accId">
              <h2 class="accordion-header" :id="faq.accId">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapseOne' + faq.accId"
                  aria-expanded="false"
                  :aria-controls="'collapseOne' + faq.accId"
                >
                  <strong>{{ faq.title }}</strong>
                </button>
              </h2>
              <div
                :id="'collapseOne' + faq.accId"
                class="accordion-collapse collapse"
                :aria-labelledby="faq.accId"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h6>{{ faq.description }}</h6>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- partner faq html part end here -->
</template>
<style scoped>
/* faq css start from here */

.partner-faq {
  padding: 50px 300px;
}
.partner-faq h2 {
  font-size: 32px;
  font-weight: 600;
  color: #000;
}
.partner-faq p {
  padding: 15px 0px;
  padding-right: 100px;

  line-height: 22px;
  color: #000;
  text-align: justify;
}
.faq-border {
  width: 5.5rem;
  height: 7px;
  background: #a95eea;
  border-radius: 3px;
  margin-bottom: 20px !important;
  margin: auto;
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
}
.accordion {
  overflow: auto;
  /* height: 400px; */
  margin-top: 25px;
  background-color: #fbf8fe;
}
.accordion-item {
  background-color: #fbf8fe;
}
.accordion-button {
  background-color: #fbf8fe;
}
strong {
  color: #6c3a97;
  font-weight: 600;
  font-size: 16px;
}
.accordion-body h6 {
  color: #6c3a97;
  opacity: initial !important;
  line-height: 28px;
  font-size: 14px;
}

/* faq css end here */
@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .partner-faq h2 {
    font-size: 28px;
  }
  .partner-faq p {
    padding-right: 2px;
  }
  .partner-faq {
    padding: 50px 0px;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .partner-faq {
    padding: 50px 0px 0 0;
  }
  .partner-faq h2 {
    font-size: 25px;
    font-weight: 600;
  }
  .partner-faq p {
    padding-right: 10px;
  }
}
</style>
<script>
export default {
  name: 'App',
  data() {
    return {
      faqlist: [
        {
          accId: 'headingOne',
          title: 'Do you offer rentals outside Dhaka?',
          description:
            'Yes, you can use Shuttle offers rentals in all major destinations in Bangladesh, including Dhaka, Chittagong, Sylhet, and more.'
        },
        {
          accId: 'headingTwo',
          title: 'Do you offer hourly or half-day rentals?',
          description:
            'Yes, Shuttle’s car rental services include hourly or half-day ( maximum 4 hours and up to 35 KM) options.'
        },
        {
          accId: 'headingThree',
          title: 'What is included in the daily rental package?',
          description:
            'You may keep your rental for up to 10 hours and travel up to 80 kilometers with the full-day rental package.'
        },
        {
          accId: 'headingFour',
          title:
            'What happens if I exceed the allotted hours or kilometers offered in my package?',
          description:
            'You may keep using your rental even after your hours or kilometers expire. After exceeding the package, you will be charged TK 9 per Kilometer for every extra kilometer traveled and TK 100 per hour for every extra hour you keep the rental with you.'
        },
        {
          accId: 'headingfive',
          title: 'What is the minimum time required for a booking?',
          description:
            'You may rent a car from Shuttle at least 24 hours before the date of departure.'
        },
        {
          accId: 'headingsix',
          title: 'Can I choose the type and/or model of my rental?',
          description:
            'Yes, you may choose any vehicle in our inventory. We currently offer Sedans, Microbuses, and Coasters in a wide range of models.'
        },
        {
          accId: 'headingseven',
          title: 'Do you offer premium car models?',
          description: 'Yes, ( Depending on the availability).'
        },
        {
          accId: 'headingeight',
          title: 'What costs are excluded from the package?',
          description:
            'We do not include tolls, parking fees, and overtime fees with our package.'
        },
        {
          accId: 'headingnine',
          title: 'Can I keep the car overnight?',
          description:
            'Yes, you may rent a car from Shuttle to keep overnight. An overnight fee of BDT 1000 will be applied every night to your charges.'
        }
      ]
    };
  },
  // methods: {
  //   toggleAccordion(accId) {
  //     this.faqlist = this.faqlist.map((faq) => {
  //       if (faq.accId === accId) {
  //         faq.open = !faq.open;
  //       } else {
  //         faq.open = false;
  //       }
  //       return faq;
  //     });
  //   }
  // }
};
</script>
