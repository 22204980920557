<template>
  <div class="rental-program">
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" :hidden="hideBackBtn">
              <i
                class="bi bi-chevron-left"
                aria-hidden="true"
                id="backbutton"
                @click="goToPreviousStep()"
              ></i>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="checkLastStep()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container" id="grad1">
              <div class="row justify-content-center mt-0">
                <div class="col-12 p-0 mt-3 mb-2">
                  <div class="card px-0 pb-0 mb-3">
                    <div class="row">
                      <div class="col-md-12 mx-0">
                        <form id="msform">
                          <!-- progressbarBookingModal -->
                          <ul id="progressbarBookingModal">
                            <li :class="active1" id="account">
                              <strong
                                >Select<br />
                                Location</strong
                              >
                            </li>
                            <li :class="active2" id="car">
                              <strong>Select Car</strong>
                            </li>
                            <li :class="active3" id="summary">
                              <strong>Summary</strong>
                            </li>
                            <li :class="active4" id="contact">
                              <strong>Contact <br />Details</strong>
                            </li>
                          </ul>
                          <!-- fieldsets -->
                          <fieldset v-if="step1 == 'show'">
                            <div class="d-flex flex-wrap font-weight-bold">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="radioFilter"
                                  id="filter-none"
                                  v-model="oneWay"
                                  @click="showReturnTime('oneWay')"
                                  :checked="oneWay"
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio1"
                                  ><b>One Way</b></label
                                >
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="radioFilter"
                                  id="filter-1"
                                  v-model="roundTrip"
                                  :checked="roundTrip"
                                  @click="showReturnTimeReturn('roundTrip')"
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio2"
                                  ><b>Round Trip</b></label
                                >
                              </div>
                            </div>
                            <div>
                              <h6 class="text"><b>Departure</b></h6>
                            </div>
                            <div class="input-group">
                              <div class="input-group-prepend"></div>
                              <!--  -->
                              <div class="col-6">
                                <DatePicker
                                  v-model="pickUpDate"
                                  :popover="popover"
                                  :min-date="new Date()"
                                >
                                  <template
                                    #default="{ inputValue, inputEvents }"
                                  >
                                    <input
                                      class="px-3 py-1 border rounded"
                                      :value="inputValue"
                                      v-on="inputEvents"
                                      placeholder="PickUp Date"
                                    />
                                  </template>
                                </DatePicker>
                                <p
                                  style="margin-block: 0"
                                  v-if="error1 && !pickUpDate"
                                  class="text-danger ps-1 pt-1 pb-0"
                                >
                                  <b>{{ error1 }}</b>
                                </p>
                              </div>
                              <div class="col-6">
                                <DatePicker
                                  mode="time"
                                  @change="getPickupTimeCalenderVisibility"
                                  v-model="pickUpTime"
                                  :popover="popover"
                                  :timezone="local"
                                  :minute-increment="15"
                                  class="ps-2"
                                >
                                  <template
                                    v-if="pickupTimePlaceholder"
                                    #default="{ inputEvents }"
                                  >
                                    <input
                                      class="px-3 py-1 border rounded"
                                      v-on="inputEvents"
                                      placeholder="PickUp Time"
                                    />
                                  </template>
                                  <template
                                    v-else
                                    #default="{ inputValue, inputEvents }"
                                  >
                                    <input
                                      class="px-3 py-1 border rounded"
                                      v-on="inputEvents"
                                      :value="inputValue"
                                      readonly
                                    />
                                  </template>
                                </DatePicker>
                                <p
                                  style="margin-block: 0"
                                  v-if="error2 && !selectedPickupTime"
                                  class="text-danger ps-1 pt-1 pb-0"
                                >
                                  <b>{{ error2 }}</b>
                                </p>
                              </div>
                            </div>

                            <div class="form-group">
                              <input
                                placeholder="Enter pickup location"
                                v-model="contactForm.location_infos[0].address"
                                type="text"
                                @input="searchLocations($event, 'pick')"
                                class="form-control enter-pickup"
                              />
                              <span
                                v-if="searchedPickupPlaces.length > 0"
                                style=""
                              >
                                <ul
                                  class="list"
                                  style="
                                    list-style: none;
                                    padding-left: 2.5%;
                                    background: rgb(249 249 249);
                                    border: 1px solid #ddd7d7;
                                    border-radius: 4px;
                                  "
                                >
                                  <span
                                    v-for="item in searchedPickupPlaces"
                                    :key="item"
                                  >
                                    <li
                                      class="list-items"
                                      @click="selectedPickupAddress(item)"
                                      style="cursor: pointer"
                                    >
                                      <p style="cursor: pointer">
                                        {{ item.name }}
                                      </p>
                                    </li>
                                  </span>
                                </ul>
                              </span>
                              <p
                                style="margin-block: 0"
                                v-if="error3 && !pickup_place_id"
                                class="text-danger ps-1 pt-1 pb-0"
                              >
                                <b>{{ error3 }}</b>
                              </p>
                            </div>

                            <div class="form-group">
                              <h6 class="text" v-if="roundTrip">
                                <b>Return</b>
                              </h6>
                              <div class="input-group" v-if="roundTrip">
                                <div class="input-group-prepend"></div>

                                <div class="col-6">
                                  <DatePicker
                                    v-model="returnDate"
                                    :popover="popover"
                                    :min-date="pickUpDate"
                                  >
                                    <template
                                      #default="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="px-3 py-1 border rounded"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        placeholder="Return Date"
                                      />
                                    </template>
                                  </DatePicker>
                                  <p
                                    style="margin-block: 0"
                                    v-if="error4 && !returnDate"
                                    class="text-danger ps-1 pt-1 pb-0"
                                  >
                                    <b>{{ error4 }}</b>
                                  </p>
                                </div>
                                <div class="col-6">
                                  <DatePicker
                                    mode="time"
                                    @change="getReturnTimeCalenderVisibility"
                                    v-model="returnTime"
                                    :popover="popover"
                                    :timezone="local"
                                    :minute-increment="15"
                                    class="ps-2"
                                  >
                                    <template
                                      v-if="returnTimePlaceholder"
                                      #default="{ inputEvents }"
                                    >
                                      <input
                                        class="px-3 py-1 border rounded"
                                        v-on="inputEvents"
                                        placeholder="Return Time"
                                      />
                                    </template>
                                    <template
                                      v-else
                                      #default="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="px-3 py-1 border rounded"
                                        v-on="inputEvents"
                                        :value="inputValue"
                                        readonly
                                      />
                                    </template>
                                  </DatePicker>
                                  <p
                                    style="margin-block: 0"
                                    v-if="error5 && !selectedReturnTime"
                                    class="text-danger ps-1 pt-1 pb-0"
                                  >
                                    <b>{{ error5 }}</b>
                                  </p>
                                </div>
                              </div>
                              <input
                                placeholder="Enter drop off location"
                                v-model="contactForm.location_infos[1].address"
                                type="text"
                                @input="searchLocations($event, 'drop')"
                                class="form-control enter-dropoff"
                              />
                              <span
                                v-if="searchedDropOffPlaces.length > 0"
                                style=""
                              >
                                <ul
                                  class="list"
                                  style="
                                    list-style: none;
                                    padding-left: 2.5%;
                                    background: rgb(249 249 249);
                                    border: 1px solid #ddd7d7;
                                    border-radius: 4px;
                                  "
                                >
                                  <span
                                    v-for="item in searchedDropOffPlaces"
                                    :key="item"
                                  >
                                    <li
                                      class="list-items"
                                      @click="selectedDropOffAddress(item)"
                                      style="cursor: pointer"
                                    >
                                      <p style="cursor: pointer">
                                        {{ item.name }}
                                      </p>
                                    </li>
                                  </span>
                                </ul>
                              </span>
                              <p
                                style="margin-block: 0"
                                v-if="error6 && !dropoff_pace_id"
                                class="text-danger ps-1 pt-1 pb-0"
                              >
                                <b>{{ error6 }}</b>
                              </p>
                            </div>

                            <div v-if="isLoading" class="button text-center">
                              <loader></loader>
                            </div>
                            <div class="button" v-else>
                              <input
                                type="button"
                                @click="stepOneCompleted"
                                name="next"
                                class="next action-button"
                                value="Proceed"
                              />
                            </div>
                          </fieldset>
                          <fieldset v-if="step2 == 'show'">
                            <span v-for="item in carWisePrices" :key="item.id">
                              <div
                                :class="packageClass(item.id)"
                                @click="
                                  packageSelection(
                                    item.id,
                                    item.approx_fare,
                                    item.car_type_name,
                                    item.sit_capacity,
                                    item.image
                                  )
                                "
                                style="
                                  border: 1px solid #a95eea;
                                  border-radius: 5px;
                                  margin-bottom: 10px;
                                  cursor: pointer;
                                "
                              >
                                <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                                  <div class="image-design">
                                    <img :src="item.image" />
                                  </div>
                                </div>
                                <div class="col-lg-5 col-md-6 col-sm-6 col-6">
                                  <div class="text_part">
                                    <h5>
                                      <b>{{ item.car_type_name }}</b>
                                    </h5>
                                    <i class="bi bi-people-fill"
                                      ><p>{{ item.sit_capacity }} Seats</p></i
                                    >
                                  </div>
                                </div>
                                <div
                                  class="col-lg-4 col-md-12 col-sm-12 col-12"
                                  v-if="oneWay"
                                >
                                  <div class="right_text">
                                    <h4>
                                      BDT
                                      {{ 
                                        item.approx_fare
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                          )
                                      }}*
                                    </h4>
                                    <h6
                                      style="
                                        text-align: center;
                                        font-size: 12px;
                                        font-weight: 600;
                                        margin-top: -5px;">
                                      * Estimated Price
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </span>
                            <div
                              v-if="error7 && !contactForm.car_type"
                              class="text-danger pt-1"
                            >
                              <p class="text-center">
                                <b>{{ error7 }}</b>
                              </p>
                            </div>
                            <div class="button">
                              <input
                                type="button"
                                @click="stepTwoCompleted"
                                name="next"
                                class="next action-button"
                                value="Proceed"
                              />
                            </div>
                          </fieldset>
                          <fieldset v-if="step3 == 'show'">
                            <div class="row upper_design">
                              <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="left_part">
                                  <h6><b>Pick-up Details:</b></h6>
                                  <p>
                                    <span>Location:</span>
                                    {{ contactForm.location_infos[0].address }}
                                  </p>

                                  <p>
                                    <span>Date:</span>
                                    {{ formatDate(selectedPickupDate) }}
                                  </p>
                                  <!-- <p><span>Time:</span> {{ selectedPickupTime }}</p> -->
                                  <p>
                                    <span>Time:</span>
                                    {{ convertTime(pickUpTime) }}
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="right_part">
                                  <h6><b>Drop-off Details:</b></h6>
                                  <p>
                                    <span>Location:</span>
                                    {{ contactForm.location_infos[1].address }}
                                  </p>
                                  <p v-if="roundTrip">
                                    <span>Date:</span>
                                    {{ formatDate(selectedReturnDate) }}
                                  </p>
                                  <!-- <p v-if="roundTrip"><span>Time:</span> {{ selectedReturnTime }} </p> -->
                                  <p v-if="roundTrip">
                                    <span>Time:</span>
                                    {{ convertTime(returnTime) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="row botton_design">
                              <div class="col-lg-3 col-md-12 col-sm-12">
                                <div class="first_part">
                                  <img :src="selectedCarImage" />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="middle_part">
                                  <i class="bi bi-info-circle-fill"
                                    ><p>
                                      <b>Car Type:</b>
                                      {{ selectedCarName }}
                                    </p></i
                                  >
                                  <i class="bi bi-person-fill"
                                    ><p>
                                      <b>Max Seats:</b>
                                      {{ selectedCarTypeSit }}
                                    </p></i
                                  >

                                  <i class="bi bi-geo-alt-fill"
                                    ><p>
                                      <b>Estimated Km:</b>
                                      {{ contactForm.total_distance / 2 }}
                                    </p></i
                                  >
                                </div>
                              </div>
                              <div class="col-lg-5 col-md-12 col-sm-12">
                                <div class="last_part">
                                  <i class="bi bi-check-square-fill"
                                    ><p>
                                      <b>Includes:</b> Fuel Cost, Driver
                                      Allowance
                                    </p></i
                                  >
                                  <i class="bi bi-x-circle-fill"
                                    ><p>
                                      <b>Excludes:</b> Parking Charge, Toll
                                      Charge & Extra Km Fare
                                    </p></i
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="last_part_text" v-if="oneWay">
                              <h2>
                                BDT
                                {{
                                  contactForm.price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }}*
                              </h2>
                            </div>
                            <h6
                              style="
                                text-align: center;
                                font-weight: 600;
                                margin-top: -5px;
                              "
                              v-if="oneWay"
                            >
                              * Estimated Price
                            </h6>

                            <div class="button">
                              <input
                                type="button"
                                @click="stepThreeCompleted"
                                name="next"
                                class="next action-button"
                                value="Proceed"
                              />
                            </div>
                          </fieldset>
                          <fieldset v-if="step4 == 'show'">
                            <div class="row">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control enter-name"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Your Name"
                                  v-model="contactForm.name"
                                />
                                <p
                                  style="margin-block: 0"
                                  v-if="error8 && !contactForm.name"
                                  class="text-danger ps-1 pt-1 pb-0"
                                >
                                  <b>{{ error8 }}</b>
                                </p>
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control enter-number"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Your Contact Number"
                                  v-model="contactForm.phone"
                                />
                                <p
                                  style="margin-block: 0"
                                  v-if="
                                    error9 && contactForm.phone.length != 11
                                  "
                                  class="text-danger ps-1 pt-1 pb-0"
                                >
                                  <b>{{ error9 }}</b>
                                </p>
                              </div>
                              <div class="form-group">
                                <input
                                  @keyup="invalidEmailCheck"
                                  type="email"
                                  class="form-control enter-email"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Your Email Address"
                                  v-model="contactForm.email"
                                />
                                <p
                                  style="margin-block: 0"
                                  v-if="error10 && !contactForm.email"
                                  class="text-danger ps-1 pt-1 pb-0"
                                >
                                  <b>{{ error10 }}</b>
                                </p>
                                <p
                                  style="margin-block: 0"
                                  v-if="error10 && contactForm.email"
                                  class="text-danger ps-1 pt-1 pb-0"
                                >
                                  <b>{{ error10 }}</b>
                                </p>
                              </div>
                            </div>

                            <div v-if="isLoading" class="button text-center">
                              <loader></loader>
                            </div>
                            <div v-else class="button">
                              <input
                                type="button"
                                @click="stepFourCompleted"
                                name="Submit" id="submit_rental_form"
                                class="next action-button"
                                value="Submit"
                              />
                            </div>
                          </fieldset>
                          <fieldset v-if="step5 == 'show'">
                            <div class="row thankyou_image" id="thankyou_id">
                              <img src="/assets/images/thanks.png" />

                              <h3 class="text-center p-2">
                                <span
                                  style="
                                    color: #a95eea;
                                    font-size: 20px;
                                    padding-bottom: 20px;
                                    line-height: 37px;
                                  "
                                  ><b>Thank you! </b></span
                                ><br />
                                Our team will get back to you shortly.<br />You
                                can also contact us at
                                <b>09638888868.</b>
                                <p class="text-center pt-2">
                                  <b>Contact Hours:</b>
                                  Saturday - Thursday (6:00AM - 9:00PM)
                                </p>
                              </h3>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Rental from '../../api/Rental';
import { DatePicker } from 'v-calendar';
import Loader from '../../components/lib/Loader.vue';

export default {
  name: 'Contact',
  props: ['selectedPackage'],
  data() {
    return {
      selectedTime2: null,
      step1: 'show',
      step2: 'hide',
      step3: 'hide',
      step4: 'hide',
      step5: 'hide',
      active1: 'active',
      active2: 'inactive',
      active3: 'inactive',
      active4: 'inactive',
      pickUpDate: '',
      pickUpTime: new Date(),

      pickupTimePlaceholder: true,
      returnTimePlaceholder: true,
      selectedPickupDate: null,
      selectedPickupTime: null,
      selectedReturnDate: null,
      selectedReturnTime: null,
      pickup_place_id: '',
      dropoff_pace_id: '',
      returnDate: '',
      returnTime: new Date(),
      searchedPickupPlaces: [],
      searchedDropOffPlaces: [],
      popover: {
        visibility: 'focus'
      },
      hideBackBtn: true,
      oneWay: true,
      roundTrip: false,
      carWisePrices: {},
      unSelectedPackageClass: 'row',
      selectedPackageClass: 'row border_design',
      selectedCarName: '',
      selectedCarTypeSit: '',
      selectedCarImage: '',
      isLoading: false,
      contactForm: {
        email: '',
        phone: '',
        name: '',
        way_type: 1,
        pickup_date_time: '',
        return_date_time: '',
        total_distance: '',
        package_id: '',
        car_type: '',
        price: '',

        location_infos: [
          {
            address: '',
            lat: '',
            lng: ''
          },
          {
            address: '',
            lat: '',
            lng: ''
          }
        ]
      },
      formValidation: {
        pLocation: ''
      },
      error1: '',
      error2: '',
      error3: '',
      error4: '',
      error5: '',
      error6: '',
      error7: '',
      error8: '',
      error9: '',
      error10: ''
    };
  },
  components: {
    // Loading,
    Loader,
    DatePicker
  },
  watch: {
    selectedPackage: function () {
      this.selectedPackagePickupAddress();
      this.selectedPackageDropOffAddress();
    },
    pickUpDate: function (r) {
      if (r) {
        this.selectedPickupDate = new Date(
          new Date(r).getTime() - new Date(r).getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[0];
      } else {
        this.selectedPickupDate = null;
      }
    },
    pickUpTime: function (r) {
      if (r) {
        this.selectedPickupTime = new Date(
          new Date(r).getTime() - new Date(r).getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[1]
          .slice(0, 5);
      } else {
        this.selectedPickupTime = null;
      }
    },
    returnDate: function (r) {
      if (r) {
        this.selectedReturnDate = new Date(
          new Date(r).getTime() - new Date(r).getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[0];
      } else {
        this.selectedReturnDate = null;
      }
    },
    returnTime: function (r) {
      if (this.returnTime) {
        this.selectedReturnTime = new Date(
          new Date(r).getTime() - new Date(r).getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[1]
          .slice(0, 5);
      } else {
        this.selectedReturnTime = null;
      }
    }
  },

  methods: {
    formatDate(date) {
      const formatableDate = new Date(date);
      return formatableDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'Asia/Dhaka'
      });
    },

    convertTime(isoTime) {
      const timeString = isoTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
      return timeString;
    },

    selectedPackagePickupAddress() {
      this.contactForm.location_infos[0].address =
        this.selectedPackage.from_destination;
      this.pickup_place_id = this.selectedPackage.from_dest_place_id;
      this.searchedPickupPlaces.length = 0;
    },
    selectedPackageDropOffAddress() {
      this.contactForm.location_infos[1].address =
        this.selectedPackage.to_destination;
      this.dropoff_pace_id = this.selectedPackage.to_dest_place_id;
      this.searchedDropOffPlaces.length = 0;
    },

    selectedPickupAddress(value) {
      this.contactForm.location_infos[0].address = value.name;
      this.pickup_place_id = value.place_id;
      this.searchedPickupPlaces.length = 0;
    },
    selectedDropOffAddress(value) {
      this.contactForm.location_infos[1].address = value.name;
      this.dropoff_pace_id = value.place_id;
      this.searchedDropOffPlaces.length = 0;
    },
    getPickupTimeCalenderVisibility() {
      this.pickupTimePlaceholder = false;
    },
    getReturnTimeCalenderVisibility() {
      this.returnTimePlaceholder = false;
    },
    goToPreviousStep() {
      if (this.step2 == 'show') {
        this.step2 = 'hide';
        this.step1 = 'show';
        this.active2 = 'inactive';
        this.contactForm.car_type = '';
        this.contactForm.price = '';
        this.selectedCarName = '';
        this.selectedCarTypeSit = '';
        this.selectedCarImage = '';
        this.hideBackBtn = true;
        // this.returnTime = new Date();
      }
      if (this.step3 == 'show') {
        this.step3 = 'hide';
        this.step2 = 'show';
        this.active3 = 'inactive';
      }
      if (this.step4 == 'show') {
        this.step3 = 'show';
        this.step4 = 'hide';
        this.active4 = 'inactive';
      }
    },
    stepOneCompleted() {
      this.isLoading = true;
      if (!this.pickUpDate) {
        this.isLoading = false;
        this.error1 = 'Please Select a Pickup Date';
        return;
      }
      this.isLoading = true;
      if (!this.selectedPickupTime) {
        this.isLoading = false;
        this.error2 = 'Please Select a Pickup Time';
        return;
      }

      this.isLoading = true;
      if (!this.pickup_place_id) {
        this.isLoading = false;
        this.error3 = 'Please Select a Pickup Location';
        return;
      }
      if (this.roundTrip) {
        if (!this.returnDate) {
          this.isLoading = false;
          this.error4 = 'Please Select a Return Date';
          return;
        }
      }
      if (this.roundTrip) {
        if (!this.selectedReturnTime) {
          this.isLoading = false;
          this.error5 = 'Please Select a Return Time';
          return;
        }
      }

      if (!this.dropoff_pace_id) {
        this.isLoading = false;
        this.error6 = 'Please Select a Drop-off Location';
        return;
      }

      if (
        this.pickup_place_id &&
        this.dropoff_pace_id &&
        this.pickUpDate &&
        this.selectedPickupTime
      ) {
        this.getLocationInfoAndDistanceInfo().then(async () => {
          await Rental.getDistance(
            this.contactForm.location_infos[0].lat,
            this.contactForm.location_infos[0].lng,
            this.contactForm.location_infos[1].lat,
            this.contactForm.location_infos[1].lng
          )
            .then(async (res) => {
              await Rental.kmWiseCarTypePrices(
                this.getBookingKm(res.data.distance)
              ).then((res) => {
                this.isLoading = false;
                this.step1 = 'hide';
                this.step2 = 'show';
                this.active2 = 'active';
                this.hideBackBtn = false;
                this.carWisePrices = res.data.data;
              });
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        });
      } else {
        this.isLoading = false;
      }
    },
    getBookingKm(distance) {
      let bookingDistance = distance.split(',').join('') * 2;
      this.contactForm.total_distance = bookingDistance;
      return bookingDistance;
    },

    async getLocationInfoAndDistanceInfo() {
      await Rental.getLocationInfo(this.pickup_place_id).then(
        async (address1) => {
          this.contactForm.location_infos[0].lat =
            address1.data.place_details.lat;
          this.contactForm.location_infos[0].lng =
            address1.data.place_details.lng;
          await Rental.getLocationInfo(this.dropoff_pace_id).then(
            (address2) => {
              this.contactForm.location_infos[1].lat =
                address2.data.place_details.lat;
              this.contactForm.location_infos[1].lng =
                address2.data.place_details.lng;
            }
          );
        }
      );
    },
    async packageSelection(id, price, carType, selectedCarTypeSit, image) {
      this.contactForm.car_type = id;
      this.contactForm.price = price;
      this.selectedCarName = carType;
      this.selectedCarTypeSit = selectedCarTypeSit;
      this.selectedCarImage = image;
    },
    packageClass(id) {
      if (id == this.contactForm.car_type) {
        return this.selectedPackageClass;
      } else {
        return this.unSelectedPackageClass;
      }
    },
    stepTwoCompleted() {
      if (this.contactForm.car_type) {
        this.step1 = 'hide';
        this.step2 = 'hide';
        this.step3 = 'show';
        this.active3 = 'active';
        if (!this.roundTrip) {
          this.returnTime = '';
          this.returnDate = '';
        }
      } else {
        this.error7 = 'Please Select a Car Type';
        // this.toaster.error("Please Select a Car Type");
      }
    },
    stepThreeCompleted() {
      this.step1 = 'hide';
      this.step2 = 'hide';
      this.step3 = 'hide';
      this.step4 = 'show';
      this.active4 = 'active';
      if (this.roundTrip) {
        this.contactForm.way_type = 2;
      } else {
        this.contactForm.way_type = 1;
      }
      this.contactForm.pickup_date_time =
        this.selectedPickupDate + ' ' + this.selectedPickupTime + ':00';
      if (this.returnDate && this.returnTime) {
        this.contactForm.return_date_time =
          this.selectedReturnDate + ' ' + this.selectedReturnTime + ':00';
      }
    },
    stepFourCompleted() {
      if (!this.contactForm.name) {
        this.isLoading = false;
        this.error8 = 'Please Enter Your Name';
        // this.toaster.error("Please Enter Your Name");
        return;
      }
      if (this.contactForm.phone.length != 11) {
        this.isLoading = false;
        this.error9 = 'Please Provide Valid Number';
        // this.toaster.error("Please Provide Valid Number");
        return;
      }
      if (!this.contactForm.email) {
        this.isLoading = false;
        this.error10 = 'Please Enter Email Address';
        return;
      }

      if (this.contactForm.phone && this.contactForm.phone.length < 12) {
        this.isLoading = true;
        Rental.saveBooking(this.contactForm)
          .then((res) => {
            this.isLoading = false;
            this.step1 = 'hide';
            this.step2 = 'hide';
            this.step3 = 'hide';
            this.step4 = 'hide';
            this.hideBackBtn = true;
            this.step5 = 'show';
            document.getElementById('progressbarBookingModal').classList.add('hidden');
            // document.querySelector('#progressbarBookingModal').style.display = "none";
            // document.getElementById('progressbarBookingModal').style.display = "none";
            console.log(res);
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
      }
    },
    invalidEmailCheck() {
      if (this.contactForm.email.indexOf('@') == -1) {
        this.isLoading = false;
        this.error10 = 'Invalid Email';
        return;
      } else {
        this.isLoading = false;
        this.error10 = '';
      }
    },

    showReturnTime() {
      this.roundTrip = false;
      this.oneWay = true;
      this.contactForm.way_type = 1;
    },
    showReturnTimeReturn() {
      this.roundTrip = true;
      this.oneWay = false;
      this.contactForm.way_type = 2;
    },
    searchLocations(event, type) {
      if (event.target.value.length > 1) {
        Rental.searchByInput(event.target.value).then((res) => {
          if (type == 'pick') {
            this.pickup_place_id = '';
            this.searchedPickupPlaces = res.data.search_results;
          } else {
            this.dropoff_pace_id = '';
            this.searchedDropOffPlaces = res.data.search_results;
          }
        });
      } else {
        if (type == 'pick') {
          this.searchedPickupPlaces.length = 0;
        } else {
          this.searchedDropOffPlaces.length = 0;
        }
      }
    },
    pickupLocationSet(event) {
      this.contactForm.location_infos[0].address = event.target.value;
    },
    dropoffLocationSet(event) {
      this.contactForm.location_infos[1].address = event.target.value;
    },
    checkLastStep() {
      if (this.step5 == 'show') {
        this.$router.go();
      }
    }
  }
};
</script>
  <style>
.vc-time-date {
  display: none !important;
}
.navbar-toggler {
  padding: 0.25rem 0.6rem !important;
}
button.btn-close {
  opacity: 10;
  font-weight: bolder;
  background: url('/assets/images/close.svg') right/1.5em no-repeat;
}
button.btn-close:focus {
  box-shadow: none;
}
</style>
  <style scoped>
.hidden { display: none; }
/* input.px-3.py-1.border.rounded,
  .enter-pickup,
  .enter-dropoff {
    text-transform: capitalize;
  } */
button.btn.btn-primary {
  box-shadow: none;
}
label {
  font-size: 16px !important;
}
::placeholder {
  color: #6c3a97;
  opacity: 1;
}
.form-check-input:checked {
  background-color: #6c3a97;
  border-color: #6c3a97;
}

:-ms-input-placeholder {
  color: #6c3a97;
}

::-ms-input-placeholder {
  color: #6c3a97;
}
input.px-3.py-1.border.rounded {
  width: 100%;
}
i.bi.bi-check-lg {
  font-size: 27px;
}

.list-items :hover {
  /* background-color: #eb7febdd !important; */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.text-center {
  font-size: 17px;
}
li#contact:after {
  background: white !important;
}
.text_part {
  padding-top: 15px;
}
.text_part i {
  display: flex;
  color: #6c3a97;
}
.text_part p {
  color: #6c3a97;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
}
.text_part h5 {
  text-align: initial;
  color: #6c3a97;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
}
.border_design {
  border: 2px solid #a95eea;
  border-radius: 10px;

  /* margin: 15px 2px 0 2px; */
  background: rgba(169, 94, 234, 0.15);
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.right_text h4 {
  color: #6c3a97;
  padding-top: 30px;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.image-design img {
  height: 100%;
  width: 100%;
  padding-top: 10px;
}
.form-control {
  margin-top: 20px;
}
.last_part_text h2 {
  text-align: center;
  padding-right: 0px !important;
  padding-top: 5px !important;
  font-size: 32px !important ;
}
.text {
  text-align: initial;
  padding: 15px 0 0 0;
}
.button {
  text-align: center;
}

.action-button {
  background-color: #6c3a97;
  color: #ffffff;
  border: 1px solid #6c3a97;
  padding: 10px 30px;
  margin-top: 30px;
  display: block;
  width: 100%;
  border-radius: 5px;
}
.modal-header {
  border-bottom: none;
}

.left_part span {
  font-weight: 900;
  color: #6c3a97;
}
.right_part span {
  font-weight: 700;
  color: #6c3a97;
}
.upper_design {
  background-color: rgba(169, 94, 234, 0.05);
  padding: 20px;
  border-radius: 10px;
}
.botton_design {
  padding-top: 20px;
}
.first_part img {
  height: 90px;
  width: 100px;
  padding-top: 15px;
}
.middle_part {
  padding-top: 30px;
}
.middle_part i {
  display: flex;
}
.middle_part p {
  padding-left: 10px;
}
.middle_part p b {
  color: #6c3a97;
}
.last_part {
  padding-top: 30px;
}
.last_part i {
  display: flex;
  font-style: normal !important;
}
.last_part p {
  padding-left: 10px;
}
.last_part p b {
  color: #6c3a97;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.icon i {
  float: right;
  color: #6c3a97;
  border-radius: 50%;
  border: 1px solid #6c3a97;
  padding: 2px 4px;
  margin-bottom: 20px;
}
.thankyou_image img {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*progressbarBookingModal*/
#progressbarBookingModal {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  -webkit-padding-start: 1 !important;
  /* padding-inline-start: 0 !important; */
}

#progressbarBookingModal .active {
  color: #000000;
}

#progressbarBookingModal li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

#progressbarBookingModal #account:before {
  text-align: center;
  content: '1';
}

#progressbarBookingModal #car:before {
  text-align: center;
  content: '2';
}

#progressbarBookingModal #summary:before {
  content: '3';
  text-align: center;
}
#progressbarBookingModal #contact:before {
  text-align: center;
  content: '4';
}

#progressbarBookingModal li:before {
  width: 45px;
  height: 45px;
  line-height: 38px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  /* margin: 0 auto 10px auto; */
  padding: 2px;
}

/*progressbarBookingModal connectors*/
#progressbarBookingModal li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbarBookingModal li.active:before,
#progressbarBookingModal li.active:after {
  background: #6c3a97;
}

/* section two css start from here */

.section-two {
  margin-top: -33%;
}
button {
  border: none;
}

.rental-program h2 {
  font-size: 40px;
  font-weight: 700;
  padding-top: 60px;
  padding-right: 150px;
  line-height: 49px;
}
.rental-program p {
  text-align: initial;
  font-size: 14px;
  line-height: 18px;
  font-size: 13px;
  font-style: normal;
}
.rental-image img {
  /* float: left; */
  margin-top: 19px;
}
/* .middle-part {
    margin-top: 30px;
  } */
.middle-part {
  padding-top: 15px;
}
.middle-part i {
  display: flex;
  color: #6c3a97;
  font-style: normal;
}
.middle-part p {
  color: #000;
  font-size: 16px;

  padding-left: 15px;
}
.btn {
  background-color: #6c3a97;
  border: none;
  color: white;
  padding: 12px 15px;

  font-size: 17px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 20px;
}
i.fa.fa-chevron-right {
  float: right;
  padding-top: 3px;
}
.btn i {
  padding-left: 50px;
}
/* section two css end here */
@media only screen and (min-width: 1824px) {
  .section-two {
    margin-top: -22%;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .section-two {
    margin-top: -31%;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .section-two {
    margin-top: -40%;
  }
}

/* 
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */

@media (min-width: 768px) and (max-width: 1024px) {
  .section-two {
    margin-top: -24%;
  }
  .section-two h2 {
    font-size: 30px;
    padding-right: 2px;
    padding-top: 0px;
    margin-top: -30px;
  }
  .right_text {
    float: right;
  }
  .text_part {
    float: right;
  }
  .right_text h4 {
    padding-top: 1px;
  }
}

/* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */

@media (min-width: 481px) and (max-width: 767px) {
  input.px-3.py-1.border.rounded,
  .enter-pickup,
  .enter-dropoff {
    font-size: 15px;
    /* text-transform: capitalize; */
  }
}

/* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */

@media (min-width: 320px) and (max-width: 480px) {
  input.px-3.py-1.border.rounded,
  .enter-pickup,
  .enter-dropoff,
  .enter-name,
  .enter-number,
  .enter-email {
    font-size: 15px;
    /* text-transform: capitalize; */
  }
  label {
    font-size: 16px !important;
  }
  .image-design img {
    /* float: right; */
    height: 100%;

    padding-top: 0px;
  }
  .text_part h5 {
    padding-bottom: 0px;
  }
  #progressbarBookingModal {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    /* -webkit-padding-start: 1 !important; */
    padding-inline-start: 0 !important;
  }

  .text_part {
    /* margin-top: -80px; */
    float: right;
  }
  .right_text {
    float: right;
  }
  .section-two h2 {
    font-size: 26px;
    padding: 0px;
    line-height: 32px;
    padding-bottom: 20px;
    font-weight: 700;
    margin-top: 174px;
  }
  a.btn {
    display: block;
  }
  .btn {
    display: block;
    width: 100%;
  }
  .middle-part {
    padding-top: 0px;
  }
  .first_part img {
    display: none;
  }
  .middle_part {
    padding-top: 0;
    padding: 0 0 0 20px;
  }
  .last_part {
    padding-top: 0;
    padding: 0 0 0 20px;
  }
  .last_part_text h2 {
    text-align: center;
    padding-right: 0px !important;
    margin-top: 5px !important;
    padding-bottom: 0px;
  }

  .right_text h4 {
    text-align: center;
    /* margin-top: -20px; */
    padding-top: 0;
  }
  .rental-program p {
    /* float: left; */
    padding-right: 6px;
  }
}
</style>

  