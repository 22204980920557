<template>
  <section class="why-shuttle-partner py-5">
    <div class="container px-5">
      <div class="row text-center pb-5">
        <div class="col-md-12 col-lg-12 title">
          <h3 class="m-0">কেন শাটেল পার্টনার?</h3>
        </div>
      </div>
      <div class="row text-center content">
        <div class="col-md-4 col-lg-4 px-5">
          <!-- <p class="commission">কমিশন ফ্রি</p> -->
          <img width="100%" src="../../../public/assets/images/partner/commission.webp" />
        </div>
        <div class="col-md-4 col-lg-4 px-5">
          <!-- <p>নির্দিষ্ট রুট</p> -->
          <img width="100%" src="../../../public/assets/images/partner/route.webp" />
        </div>
        <div class="col-md-4 col-lg-4 px-5">
          <!-- <p>ফিক্সড ইনকাম</p> -->
          <img width="100%" src="../../../public/assets/images/partner/income.webp" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
    section.why-shuttle-partner .title h3 {
      font-size: 28px;
    } 
    .content p{margin: 0; padding-top: 10px;}
    .content p.commission{padding-top: 0%;}
</style>