<template>
  <!--Rental Package Slider Section START-->
  <div class="container my-5">
    <div class="row d-flex flex-lg-column">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
        <div class="rental-package-line"></div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="border"></div>
        <h1 class="h3 rental-package-text">
            Rental Travel Packages
        </h1>
        <div class="row d-flex justify-content-lg-between">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row d-flex justify-content-start">
              <div class="col-md-12">
                <Carousel
                  v-if="Packages.length"
                  id="rental-carousel-daily"
                  :wrap-around="true"
                  :settings="settings"
                  :breakpoints="breakpoints"
                  :autoplay="5000"
                >
                  <Slide
                    v-for="slide in Packages"
                    :key="slide.id ?? ''"
                    @mouseover="SlideMouseOverHandler()"
                    :data-slide-index="slide.id ?? ''"
                    class="carousel-slide-view"
                  >
                    <div class="image-content2" @click="packageClicked(slide ?? '')">
                      <img :src="slide.image_url ?? ''" :class="slide.id ?? ''" />
                    </div>
                    <div class="description" id="travelPackage" @click="packageClicked(slide ?? '')">
                      <p class="visually-hidden">{{slide.id ?? ''}}</p>
                    <div class="image_box">
                        <div class="travel-package-card-content">
                          <h2 class="name">{{slide.from_destination ?? ''}}-{{slide.to_destination ?? ''}}</h2>
                        </div>
                        <img src="/assets/images/taka.png" />
                        <p>Starting From BDT {{slide.starting_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',') ?? ''}}</p>
                    </div>
                    <div class="text_box">
                      <img src="/assets/images/trip.png" />
                      <!-- <p>{{slide.trip_type ?? '' == 2 ? 'Round Trip' : 'One way'}}</p>
                       -->
                       <p>{{slide.trip_type == 2 ? 'Round Trip' : 'One way'}}</p>
                    </div>
                    </div>
                  </Slide>
                  <template #addons>
                    <navigation>
                      <template #next>
                        <i class="bi bi-chevron-right"></i>
                      </template>
                      <template #prev>
                        <i class="bi bi-chevron-left"></i>
                      </template>
                    </navigation>
                </template>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Rental Package Slider Section END-->

  <PackageDetails :selectedPackage="selectedPackage"></PackageDetails>
  <BandarbanPackage></BandarbanPackage>

</template>
  
<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import PackageDetails from './PackageDetails.vue';
import BandarbanPackage from './BandarbanPackage.vue';
import Rental from '../../api/Rental';
  export default{
    components:{ Carousel,Slide, Navigation,PackageDetails,BandarbanPackage},
    data() {
      return {
        current: 'PackageDetails',
       selectedPackage : {
        image_url: '',
        from_destination: '',
        to_destination: '',
        vehicle_type: '',
        starting_price: '',
       },
            Packages: [],
            packageData:[],
            
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
              // 700px and up
               320: {
                itemsToShow: 1,
                snapAlign: "center",
              },
              700: {
                itemsToShow: 2,
                snapAlign: "center",
              },
              // 1024 and up
              1024: {
                itemsToShow: 4,
                // snapAlign: "start",
              },
            },
        }
    },
    mounted() {
      this.getPackages();
    },
    methods: {
    oepenModal(){
      document.getElementById('modalCoxsBazar').click()
    },
    closeModal(){
      this.selectedPackage = {
        image_url: '',
        from_destination: '',
        to_destination: '',
        vehicle_type: '',
        starting_price: '',
       }
    },
    packageClicked(value){
      this.selectedPackage = value
      this.oepenModal()
    },
    SlideMouseOverHandler() {
      let lists = document.querySelectorAll(
        "#rental-carousel-daily.carousel .carousel__viewport ol.carousel__track>li"
      );
      let activeEl = "carousel__slide--active";
      lists.forEach(function (el) {
        el.classList.remove(activeEl);
      });
    },
      getPackages(){
         Rental.packages().then((res)=>{
          // console.log(res.data.data)
          this.Packages = res.data.data;
  
         });
      },
    },
  };
  </script>
  <style>
.carousel__prev,
.carousel__next {
	
	background-color: #6c3a97 !important;
  
}
@media (min-width: 320px) and (max-width: 480px) {
  .carousel__prev,
.carousel__next {
	
  transform: translate(-30%, -50%);
}

.carousel__next {
	
  transform: translate(13%, -50%);
}

}




</style>

  
<style scoped>



#rental-carousel-daily .carousel__slide--visible > .carousel__item {
 
  transform: none;
}

#rental-carousel-daily .carousel__slide--next > .carousel__item {
  transform: none;
}

#rental-carousel-daily .carousel__slide--prev > .carousel__item {
  transform: none;
}

#rental-carousel-daily .carousel__slide--active > .carousel__item {
  transform: none;
  
}

#rental-carousel-daily .carousel__prev {
  top: 115% !important;

}


#rental-carousel-daily .carousel__slide.carousel__slide--active.carousel__slide--visible {
  transform: none;
  
}
#rental-carousel-daily .carousel__slide.carousel__slide--visible:hover {
  transform: none;
  
}
#rental-carousel-daily .carousel__slide.carousel__slide--visible,
#rental-carousel-daily .carousel__slide.carousel__slide--next {
  transform: none;
  
}
.carousel__slide{
    display: block !important;
    margin-right: 20px !important;
}


/*  */

div#travelPackage {
    opacity: 0.9;
    background-color: #0a0909b3 !important;
}
.text_box img{
  height: 20px;
  width: 20px;
}
.package {
  padding: 200px 0 0 12px;
}

 .rental-package-text{
  padding:0 0px 20px 0;
  color: #111632;
  font-weight: 600;
  font-size: 32px;
}


.text_box {
  margin-top: -15px;
  padding-left: 25px;
  padding-bottom: 1px;
}
.image_box {
  padding-left: 25px;
}
.image_box img {
  width: 20px;
  height: 20px;
}
.description i {
  display: flex;
  color: #fff;
}
.description p {
  padding-left: 29px;
  margin-top: -23px;
  color: #fff;
  font-size: 14px;
}
.travel-package-card-content h2 {
  color: #fff;
  font-size: 15px;
  margin-top: -119px;
  padding: 10px 0 0 0;
}
.image-content2 img {
  width: 100%;
  height: 350px;
   border-radius: 10px;

}
.carousel-slide-view {
  /* background:#000; */
  height: 350px;
  border-radius: 10px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #a95eea;
}
.border {
  width: 5.5rem;
  height: 7px;
  background: #a95eea;
  border-radius: 3px;
  margin-bottom: 20px;
  margin-top: 120px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .package {
    padding: 70px 0 0 0;
  }
  .carousel__slide{
    margin-right: 5px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .package {
    padding: 50px 20px 0px 20px;
  }
  #rental-carousel-daily .carousel__prev{
       transform: translate(-32%, -50%) !important;
  }
  .carousel__next{
     transform: translate(-32%, -50%);
  }
  .carousel__slide{
    margin-right: 0px !important;
  }
  .text h2 {
    font-size: 25px;
    color: #111632;
    padding-bottom: 20px;
    font-weight: 600;
  }
  .border {
    margin-bottom: 13px;
    margin-top: 20px;
  }
  .rental-package-text{
    font-size: 25px;
    color: #111632;
    padding-bottom: 20px;
    font-weight: 600;
  }
  .image-content2 img {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
     border-radius: 10px;
  }
}
</style>
  