import Home from '../pages/Home.vue';
import Business from '../pages/Business.vue';
import Partner from '../pages/partner.vue'
import Rental from '../pages/Rental.vue';
import SFS from '../pages/SFSLayout.vue';
import Invoice from "../components/rental/Invoice.vue";

import FAQ from '../components/partner/FAQ.vue'
import PrivacyPolicy from '../components/partner/partial/PrivacyPolicy.vue'
import TermsCondition from '../components/partner/partial/TermsCondition.vue'

export default [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/business',
    name: 'business',
    component: Business
  },
  { path: '/partner', name: "Partner",component: Partner, },
  {
    path: '/rental',
    name: 'Rental',
    component: Rental
  },
  {
    path: '/shuttle-for-school',
    name: 'SFS',
    component: SFS
  },
  {
    path: "/Invoice/:id",
    component: Invoice,
    name: "Invoice",
  },
  { path: '/partner/faq', name: "FAQ", component: FAQ},
  { path: '/partner/privacy-policy', name: "PrivacyPolicy", component: PrivacyPolicy},
  { path: '/partner/terms-conditions', name: "TermsCondition", component: TermsCondition},
  { path: '/admin/login', component: { created() { window.location.href = 'https://api.shuttlebd.com/admin/login';}}},
];
