<template>
  <header>
    <the-header class="px-0"></the-header>
  </header>

  <!--welcome section start -->
  <section class="welcome-section py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6 align-self-center">
          <div class="welcome-text">
            <h4>
              Welcome to the future of
              <span class="text-shuttle">safe student transportation</span>
            </h4>
            <p>
              We are providing a tech-driven solution that is both reliable and
              affordable.
            </p>
            <a href="#hopOnboard" class="global-btn">Hop Onboard</a>
          </div>
          <div class="welcome-list">
            <div class="d-flex mb-2 mb-md-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Safe transportation</div>
            </div>
            <div class="d-flex mb-2 mb-md-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Live Tracking</div>
            </div>
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Reliable Service</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="welcome-img mt-4 mt-md-0">
            <img src="@/assets/sfs/images/welcome2.webp" alt="welcome" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- welcome section end -->

  <!--transportation section start -->
  <section class="welcome-section py-4">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main-trans">
            <div class="transportation-box">
              <div class="row">
                <div class="col-md-8">
                  <div class="transportation-text">
                    <h4>Revolutionising Student Transportation</h4>
                    <p>
                      Shuttle for School offers end-to-end solutions for students, parents
                      and schools.
                    </p>
                    <a href="#hopOnboard" class="global-btn">Hop Onboard</a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="transportation-woman">
                    <div class="tw-one">
                      <img src="@/assets/sfs/images/woman-bg.png" alt="" />
                    </div>
                    <div class="tw-two">
                      <img src="@/assets/sfs/images/woman.webp" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="trans-bg">
              <img src="@/assets/sfs/images/trans.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- transportation section end -->

  <!--student parent section start -->
  <section class="student-section welcome-section py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="welcome-text">
            <span>What we offer</span>
            <h4>For Students & Parents</h4>
          </div>
          <div class="welcome-list">
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Comfortable Rides at affordable prices</div>
            </div>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Peace of mind with Live Ride Tracking</div>
            </div>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                Verified & Trained Drivers and Bus Mothers
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Convenient Routes close to home</div>
            </div>
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Live Customer Service during service hours</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="welcome-img ms-auto mt-5 mt-md-0">
            <img class="wm-two" src="@/assets/sfs/images/student.webp" alt="student" />
            <img class="wm-one" src="@/assets/sfs/images/student-vector.png" alt="vector" />
          </div>
        </div>
      </div>
      <div class="space-control"></div>
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-6">
          <div class="welcome-img mt-5 mt-md-0">
            <img class="wm-two" src="@/assets/sfs/images/parent.webp" alt="student" />
            <img class="wm-one" src="@/assets/sfs/images/student-vector.png" alt="vector" />
          </div>
        </div>
        <div class="col-md-6 ps-4 ps-md-5">
          <div class="welcome-text mt-5 mt-md-0">
            <span>What we offer</span>
            <h4>Educators</h4>
          </div>
          <div class="welcome-list">
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                Optimised routes and flexible plans for your students
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                Integrated dashboard to track rides & monitor expenses
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                Verified & Trained Drivers and Bus Mothers
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">Vetted vehicles for your fleet</div>
            </div>
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="@/assets/sfs/icon/check.png" alt="..." />
              </div>
              <div class="flex-grow-1 ms-3">
                Live Customer Service during service hours
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- student parent section end -->

  <!-- better city start -->
  <section class="better-city py-4 pt-5 mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-6 ms-auto ps-xl-0">
          <div class="welcome-text">
            <span class="line"></span>
            <h4>A <span class="text-shuttle">better city</span> for all</h4>
            <p>
              We envision Dhaka as a smart city for all, without disruptive traffic, safer
              roads and a smaller carbon footprint.
            </p>
          </div>
        </div>
        <div class="col-xl-6 better-city-bg">
          <div class="better-city-item">
            <div class="row">
              <div class="col-6">
                <div class="bc-first">
                  <div class="bc-item mb-4 ms-auto">
                    <div class="bci-img" style="background-color: #fff6e0">
                      <img src="@/assets/sfs/icon/trafic.png" alt="" />
                    </div>
                    <p>Lower traffic congestion</p>
                  </div>
                  <div class="bc-item ms-auto">
                    <div class="bci-img" style="background-color: #efffe0">
                      <img src="@/assets/sfs/icon/leg.png" alt="" />
                    </div>
                    <p>Smaller carbon footprint</p>
                  </div>
                </div>
              </div>
              <div class="col-6 align-self-center">
                <div class="bc-last">
                  <div class="bc-item">
                    <div class="bci-img" style="background-color: #e0f1ff">
                      <img src="@/assets/sfs/icon/route.png" alt="" />
                    </div>
                    <p>Organised pick & drop-off points</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- better city end -->

  <!-- regulatory partner start -->
  <section class="regulatory-partner py-4 pt-5 mt-5">
    <div class="container-fluid">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-4 better-city-bg">
          <div class="better-city-item">
            <div class="row">
              <div class="col-12 align-self-center">
                <div class="bc-last">
                  <div class="bc-item">
                    <img src="@/assets/sfs/images/city-corporation.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="welcome-text">
            <span class="line"></span>
            <h4>Official Regulatory<span class="text-shuttle"> Partner</span></h4>
            <p>
              Collaboration of Dhaka North City Corporation and Shuttle transportation in
              Dhaka benefits commuters with safe, reliable, and affordable options while
              reducing traffic congestion.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- regulatory partner end -->

  <!-- impact video start -->
  <!-- <section class="impact-video">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="impact-video-box">Impact Video</div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- impact video end -->

  <!-- impact app download start -->
  <section class="app-download">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="row flex-column-reverse flex-md-row">
            <div class="col-md-6">
              <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-current="true"
                    aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="10000">
                    <!-- slider item -->
                    <div class="slider-item">
                      <div class="app-image mt-5 mt-md-0">
                        <img src="@/assets/sfs/images/app-image2.svg" class="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <!-- slider item -->
                    <div class="slider-item">
                      <div class="app-image mt-5 mt-md-0">
                        <img src="@/assets/sfs/images/app-image2.webp" class="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="app-download-text">
                <div class="welcome-text">
                  <span class="line"></span>
                  <h4>
                    <span class="text-shuttle">Convenience</span> and
                    <span class="text-shuttle">connections</span> in just a few clicks
                  </h4>
                  <p>See what the Shuttle for school app can do for you</p>
                </div>
                <ul class="app-download-list">
                  <li>Know when your ride is arriving</li>
                  <li>Track your child’s ride</li>
                  <li>Assign guardians to pick up your child</li>
                </ul>
                <div class="app-download-link">
                  <p>Download the Shuttle app today</p>
                  <div class="app-download-link-box">
                    <div class="app-download-platform">
                      <a href="https://apps.apple.com/us/app/shuttle-pick-drop-service/id1459741215" target="_blank">
                        <img src="@/assets/sfs/icon/apple-logo.png" alt="" />
                        <span>App store</span>
                      </a>
                    </div>
                    <div class="app-download-platform">
                      <a href="https://play.google.com/store/apps/details?id=com.easytransport.shuttle" target="_blank">
                        <img src="@/assets/sfs/icon/playstore.png" alt="" />
                        <span>Play store</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- impact app download end -->

  <!-- contact section start -->
  <section class="contact-section py-5 my-5" id="hopOnboard">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 ps-0">
          <div class="contact-image">
            <img src="@/assets/sfs/images/contact.svg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-form-box">
            <div class="contact-form-text">
              <h4>Contact Us</h4>
              <p>Hop onboard with Shuttle</p>
            </div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button @click="changeContactType('parent')" class="nav-link"
                  :class="this.contactFormData.onboard_as == 'parent' ? 'active' : ''" id="pills-home-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                  aria-selected="true">For Parents</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="changeContactType('educator')" class="nav-link"
                  :class="this.contactFormData.onboard_as == 'educator' ? 'active' : ''" id="pills-profile-tab"
                  data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                  aria-controls="pills-profile" aria-selected="false">For Educators</button>
              </li>
            </ul>
            <div id="successMessage"></div>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="contact-form-input">
                  <form @submit.prevent="contactFormSubmit()">
                    <div class="form-group">
                      <label for="name">Name</label>
                      <input type="text" v-model="contactFormData.contact_name" placeholder="Enter your name" id="name"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="text" v-model="contactFormData.email" placeholder="Enter your email" id="email"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="mobile">Phone Number</label>
                      <input type="number" v-model="contactFormData.phone" placeholder="Enter your number" id="mobile"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="institution">Institution</label>
                      <input type="text" v-model="contactFormData.institution" placeholder="Enter" id="institution"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="children">Number of Children</label>
                      <input type="number" v-model="contactFormData.no_of_employees" placeholder="Enter" id="children"
                        required />
                    </div>
                    <div class="form-group">
                      <button class="if-sub-btn" type="submit">Hop Onboard</button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="contact-form-input">
                  <form @submit.prevent="contactFormSubmit()">
                    <div class="form-group">
                      <label for="name">Name</label>
                      <input type="text" v-model="contactFormData.contact_name" placeholder="Enter your name" id="name"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="text" v-model="contactFormData.email" placeholder="Enter your email" id="email"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="mobile">Phone Number</label>
                      <input type="number" v-model="contactFormData.phone" placeholder="Enter your number" id="mobile"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="institution">Institution</label>
                      <input type="text" v-model="contactFormData.institution" placeholder="Enter" id="institution"
                        required />
                    </div>
                    <div class="form-group">
                      <label for="children">Number of Children</label>
                      <input type="number" v-model="contactFormData.no_of_employees" placeholder="Enter" id="children"
                        required />
                    </div>
                    <div class="form-group">
                      <button class="if-sub-btn" type="submit">Hop Onboard</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- contact section end -->

  <!-- Contact submited modal -->
  <div class="modal fade" id="contactSubmitedModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <h6 class="text-success">Thank you!</h6>
          <p style="font-size:17px"> We’ve received your request. Someone from our team will contact you soon.</p>
          <div class="text-center">
            <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- Contact form error modal -->
    <div class="modal fade" id="contactErrorModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <h6 class="text-danger">Something went wrong!</h6>
          <p style="font-size:17px"> Please try again...</p>
          <div class="text-center">
            <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeaderVue from "../TheHeader.vue";
import axios from "axios";
// import $ from "jquery";
import { Modal } from 'bootstrap'

export default {
  name: "SfsIndex",
  components: {
    TheHeader: TheHeaderVue,
  },
  data() {
    return {
      contactFormData: {
        onboard_as: 'parent',
        contact_name: null,
        email: null,
        phone: null,
        institution: null,
        no_of_employees: null,
      }
    }
  },
  methods: {
    changeContactType(contactType) {
      this.contactFormData.onboard_as = contactType
    },
    contactFormSubmit: function () {

      axios.post("https://api.shuttlebd.com/sfs/store", this.contactFormData)
        .then((response) => {
          console.log(response);
          let myModal = new Modal(document.getElementById('contactSubmitedModal'));
          myModal.show();
          this.contactFormData.onboard_as = 'parent';
          this.contactFormData.contact_name = null;
          this.contactFormData.email = null;
          this.contactFormData.phone = null;
          this.contactFormData.institution = null;
          this.contactFormData.no_of_employees = null;
        })
        .catch((error) => {
          console.log("error =======", error);
          let myModal = new Modal(document.getElementById('contactErrorModal'));
          myModal.show();


        });
    },
  }
};


</script>

<style scoped>
@import "../../../src/assets/sfs/css/style.css";
@import "../../../src/assets/sfs/css/responsive.css";
</style>
