<template>
  <!-- partner description html part start from here -->
  <section class="rental-description">
    <div class="container box_shadow">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="describe-border"></div>
          <div class="left-part">
            <h2>Choose Your Shuttle Rental</h2>
            <p>
              Choose from different classes, offering a wide range of flexibility when it
              comes to budget, carrying capacity, and models.
            </p>
            <a href="#rentalContact" data-bs-toggle="modal" id="slider_cta" data-bs-target="#exampleModal"
              >Rent A Car Today</a
            >
          </div>
          <div class="left_box">
            <img src="/assets/images/shape1.png" />
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div
            id="carouselExampleControls"
            class="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="card-wrapper container-sm d-flex justify-content-around">
                  <div class="card" style="width: 18rem">
                    <img src="/assets/images/car.webp" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title text-center">কার</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="card-wrapper container-sm d-flex justify-content-around">
                  <div class="card" style="width: 18rem">
                    <img src="/assets/images/noah.webp" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title text-center">মাইক্রো বাস</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="card-wrapper container-sm d-flex justify-content-around">
                  <div class="card" style="width: 18rem">
                    <img
                      src="/assets/images/coaster.webp"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h5 class="card-title text-center">কোস্টার</h5>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="shape_design">
            <img src="/assets/images/shape1.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- partner description html part end here -->
</template>
<style scoped>
.carousel-control-prev-icon{
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c3a97'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important
    }
  
.carousel-control-next-icon {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c3a97'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}


/* partner description  css start from here  */
.rental-description {
  /* background: #6C3A97; */
  /* padding: 50px 0px; */
  margin-top: 60px;
}
.box_shadow {
  box-shadow: #6c3a9740 0px 8px 24px;
  border-radius: 15px;
  padding: 60px 30px 0 30px;
}
.describe-border {
  width: 5.5rem;
  height: 7px;
  background: #a95eea;
  border-radius: 3px;
}
.left-part h2 {
  font-size: 32px;
  font-weight: 600;
  padding-top: 15px;
  color: #000;
}
.left-part p {
  padding: 15px 0px;
  /*  padding-right: 100px; */
  color: #000;
  text-align: justify;
}
.left-part a {
  background-color: #6c3a97;
  color: #fff;
  padding: 12px 75px;
  text-decoration: none;
  border-radius: 5px;

  font-weight: 700;
}
.left_box .img {
  max-width: 100%;
}
.middle-part {
  margin-top: 30px;
}
.middle-part i {
  display: flex;
  color: #6c3a97;
}
.middle-part p {
  color: #000;
  padding-left: 15px;
}
.rental-description .card {
  background-color: transparent;
  border: none;
}
h5.card-title.text-center {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-top: -30px;
}
.carousel-dark .carousel-control-prev-icon {
  filter: none !important;
  opacity: 1.5 !important;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 2 !important;
}
.carousel-dark .carousel-control-next-icon {
  filter: none !important;
  opacity: 1.5 !important;
}
.shape_design img {
  max-width: 100%;
}

/* partner description  css end here  */
@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .left-part h2 {
    font-size: 28px;
  }
  .navbar-expand-lg {
    margin-left: 2px;
  }
  .right-part {
    height: 270px;
  }
  .section-two h2 {
    font-size: 43px;
  }
  .information-image img {
    width: 95%;
  }
  .partner-image img {
    width: 95%;
  }
  .partner-contact h2 {
    padding-right: 10px;
  }
  .partner-form {
    padding: 10px;
    margin: 2px;
  }
  .partner-program p {
    padding-right: 10px;
  }
  .left-part p {
    padding-right: 20px;
  }
  .information p {
    padding-right: 10px;
  }
  .left_box img {
    width: 100%;
  }
  .shape_design img {
    margin-top: 40px;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  /* rental description css start */
  .rental-description {
    margin-top: 10px;
  }
  .rental-description .card {
    margin-top: 50px;
  }
  .left-part h2 {
    font-size: 25px;
    font-weight: 600;
  }
  .left-part p {
    font-size: 14px;
    padding: 10px 5px 10px 0px;
  }
  .left-part a {
    text-align: center;
    display: block;
    padding: 12px 60px;
    /* margin-right:13px; */
  }
  .left_box img {
    display: none;
  }
  /* rental description css end */
}
</style>
