<template>
  <div class="card w-100 m-2 border">
    <div class="card-body">
      <div class="box">
        <slot name="icon"></slot>
        <slot name="title"></slot>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCard'
};
</script>

<style scoped>
.card {
  height: 16rem;
}
.box {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}
</style>
