<template>
  <section class="invoice" v-if="invoiceInfo">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 order-md-1">
          <!-- <div class="d-flex">
                  <div class="flex-shrink-0">
                    <img src="/assets/images/logo.png" alt="...">
                  </div>
                  <div class="flex-grow-1 ms-3">
                    Your Trip details
                  </div>
                </div> -->
          <img
            style="padding-left: 17px; padding-top: 5px"
            src="/assets/images/logo.png"
          />
          <h3 class="pt-3 pb-3" style="padding-left: 20px">Your Trip details:</h3>
          <div class="row invoice_content">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <h6>Pick-up Details:</h6>
              <p>
                <span>Location:</span>
                {{ invoiceInfo.booking.booking_location[0].address }}
              </p>
              <p>
                <span>Date:</span>
                {{ new Date(invoiceInfo.booking.pickup_date_time).toLocaleDateString('en-GB') }}
              </p>
              <p>
                <span>Time:</span>
                {{ new Date(invoiceInfo.booking.pickup_date_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 text-right">
              <h6>Drop-off Details:</h6>
              <p>
                <span>Location:</span>
                {{ invoiceInfo.booking.booking_location[1].address }}
              </p>
              <p v-if="invoiceInfo.booking.way_type == 2">
                <span>Date:</span>
                {{ new Date(invoiceInfo.booking.return_date_time).toLocaleDateString('en-GB') }}
              </p>
              <p v-if="invoiceInfo.booking.way_type == 2">
                <span>Time:</span>
                {{ new Date(invoiceInfo.booking.return_date_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}
              </p>
            </div>
          </div>
          <div class="row Invoice_bottom">
            <h6>Car & Other Details:</h6>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <i class="bi bi-info-circle-fill"
                ><p>
                  <b>Car Type:</b>
                  {{ invoiceInfo.booking.selected_car_type.car_type_name }}
                </p></i
              >
              <i class="bi bi-person-fill"
                ><p>
                  <b>Max Seats:</b>
                  {{ invoiceInfo.booking.selected_car_type.sit_capacity }}
                </p></i
              >
              <i class="bi bi-geo-alt-fill"
                ><p><b>Est Km:</b> {{ invoiceInfo.booking.total_distance }}</p></i
              >
            </div>
            <!-- <div class="col-lg-6 col-md-6 col-sm-6 text-right">
              <i class="bi bi-check-square-fill"
                ><p><b>Includes:</b>Fuel Cost, Driver Allowance</p></i
              >
              <i class="bi bi-x-circle-fill"
                ><p><b>Excludes:</b>Parking Charge, Toll Charge & Extra Km Fare</p></i
              >
            </div> -->
          </div>
          <div class="row last_part">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <h6>Payment Details:</h6>
              <p><span>Total Amount:</span> {{ invoiceInfo.booking.price }} TK</p>
              <p><span>Total Paid:</span> {{ invoiceInfo.booking.paid_amount }} TK</p>
              <p>
                <span>Total Due:</span>
                {{
                  parseInt(invoiceInfo.booking.price) -
                  parseInt(invoiceInfo.booking.paid_amount)
                }}
                TK
              </p>
              <p v-if="invoiceInfo.booking.paid_amount<0"><span>Booking Amount:</span> {{ parseInt(invoiceInfo.amount) }} TK</p>
              <!-- <p>
                <span>Due after this payment:</span>
                {{
                  parseInt(invoiceInfo.booking.price) -
                  (parseInt(invoiceInfo.booking.paid_amount) +
                    parseInt(invoiceInfo.amount))
                }}
                TK
              </p> -->
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 text-right">
              <h6>Additional Details:</h6>
              <p>
                {{ invoiceInfo.payment_details }}
              </p>
            </div>
          </div>
          <div class="button">
            <button class="btn" @click="redirectToSslCommerz()" href="">
              Make Payment
            </button>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 order-md-2">
          <div class="invoice_image">
            <img src="/assets/images/rental.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import RentalApis from "../../api/Rental";
export default {
  data() {
    return {
      trxId: "",
      invoiceInfo: "",
      rentalBackendUrl: "",
    };
  },
  components: {},
  created() {
    this.trxId = this.$route.params.id;
    // this.rentalBackendUrl = import.meta.env.VITE_APP_API_BASE_URL
    this.rentalBackendUrl = process.env.VUE_APP_API_BASE_URL;
    this.fetchInvoiceInfo();
  },
  mounted() {},
  methods: {
    fetchInvoiceInfo() {
      RentalApis.rentalInvoiceInfo(this.trxId).then((res) => {
        this.invoiceInfo = res.data.invoice;
      });
    },
    redirectToSslCommerz() {
      window.location.href =
        "https://rental.shuttlengine.com/api/v1/makePayment?transaction_id=" + this.trxId;
    },
  },
};
</script>

<style scoped>
.invoice_content {
  background-color: rgba(169, 94, 234, 0.05);
  padding: 20px;
  border-radius: 10px;
  /* margin-top: 50px; */
}
.invoice_image img {
  width: 100%;
}
.Invoice_bottom i {
  display: flex;
}
.Invoice_bottom p {
  padding-left: 10px;
  font-style: normal;
}
.Invoice_bottom {
  padding: 20px;
}
.Invoice_bottom h6 {
  padding-bottom: 10px;
}
.last_part {
  background-color: rgba(169, 94, 234, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.Invoice_bottom i::before {
  margin-top: 5px;
}
.text-right i::before {
  margin-top: 5px;
}

h6 {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}
span {
  color: #6c3a97;
  font-weight: 600;
}
.button button {
  background-color: #6c3a97;
  color: #fff;
  padding: 12px 10px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  display: block;
  text-align: center;
  width: 203px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .invoice_image img {
    display: none !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .invoice_image img {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .Invoice_bottom {
    padding-left: 20px;
  }
  .invoice_image img {
    display: none;
  }
  .button a {
    width: 320px;
  }
}
</style>
