<template>
   <section class="partner-safety">
        <div class="container">
            <div class="row text-center pb-5">
                <div class="col-md-12 title">
                    <h3 class="m-0">শাটেল পার্টনারদের সুরক্ষা</h3>
                </div>
            </div>
            <div class="row px-5">
                <div id="content">
                    <img src="../../../public/assets/images/partner/banner4.webp">
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    section.partner-safety .title h3 {
        font-size: 28px;
    }
    img{

        width: 100%;
        height: 250px;
    }
</style>